import { FC, Dispatch, SetStateAction, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import {
  Accordion,
  Autocomplete,
  Box,
  Button,
  FileDragDrop,
  Grid,
  Icon,
  Input,
  PersistentDrawer,
  Select,
  Textarea,
} from "@ntpkunity/controls";
import {
  QueryKeys,
  ValidationMessages,
  ActionOptions,
  ActionTypes,
} from "Enums";
import {
  AccordionWrapSm,
  AutocompleteWrap,
  FileDragDropWrap,
  TextAreaWrap,
} from "@sharedComponents";
import { ICreditRating, IDealerMarkups, ILender, ILenderMarkupSlab } from "Interface";
import { Controller } from "react-hook-form";
import { MarkupSlabs } from "./markupsalbs";
import { useQueryClient } from "react-query";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import {
  useSaveDealerMarkups,
  useUpdateDealerMarkupsById,
  UseQuery_getAllDealerMarkupSlabs
} from "services/financial-insurance.service";

export declare type MarkupDrawerPopUp = {
  openPopUp: boolean;
  setPopUpState: Dispatch<SetStateAction<boolean>>;
  title: string;
  form: any;
  dealer_code?: string;
  markupsData: IDealerMarkups;
  actionType: string;
  isDisabled: boolean;
  s3Response: any;
};

  const MarkupDrawer: FC<MarkupDrawerPopUp> = ({
    openPopUp,
    setPopUpState,
    title,
    form,
    dealer_code,
    markupsData,
    actionType,
  }) => {
    const theme = useTheme();
    const {
      control,
      handleSubmit,
      setValue,
      getValues,
      reset,
      watch,
      formState: { errors },
      setError,
      clearErrors,
    } = form;
    const queryClient = useQueryClient();
    const { actions } = useStoreContext();
    const creditRatings: Array<ICreditRating> =
      queryClient.getQueryData([
        QueryKeys.GET_ALL_CREDIT_RATINGS,
    ]);

    const Lenders: Array<ILender> =
      queryClient.getQueryData([
        QueryKeys.GET_ALL_LENDER,
    ]);

    const { data } =
    UseQuery_getAllDealerMarkupSlabs(dealer_code);

    const { mutate: SaveDealerMarkups } = useSaveDealerMarkups();
    const { mutate: UpdateDealerMarkups } = useUpdateDealerMarkupsById();
    const [creditRatingItems, setCreditRatingItems] = useState([]);
    const [lendername, setLenderName] = useState([]);
    const [deletedSlabs, setDeletedSlabs] = useState<number[]>([]);
    
    let displayIndex = 0;
    const markupSlabs = watch("markup_slabs");
    
    const handleDeleteItem = (index: number) => {
      const ctm = getValues("markup_slabs");
      if (ctm && ctm?.length > 0) {
        const itemToUpdate = ctm[index];

        const itemToInsert = {
          ...(itemToUpdate || {}),
          is_active: false,
          is_deleted: true,
        };

        ctm[index] = itemToInsert;
        setValue("markup_slabs", ctm);
        if (itemToUpdate && itemToUpdate.markup_slab_id) {
          setDeletedSlabs((prevState) => [
            ...prevState,
            itemToUpdate.markup_slab_id,
          ]);
        }
      }
    };
    const handleAddItem = () => {
      const ctm = getValues("markup_slabs") || [];
      ctm?.push({
        interest_rate_from: undefined,
        interest_rate_to: undefined,
        lender_fee: undefined,
        markup: undefined
      });
      setValue("markup_slabs", ctm);
    };

    const onClose = () => {
      let children = document.querySelectorAll(".drawer-header .pinned");
      if (children.length == 0) {
        setPopUpState(false);
      }
    };
    useEffect(() => {
      if (creditRatings) {
        const items = creditRatings
          .filter((x) => x.is_active)
          .map((score) => ({
            value: score.id,
            text: `${score.score_from} - ${score.score_to}`,
          }));
        setCreditRatingItems(items);
      }
    }, [creditRatings]);

    useEffect(() => {
      if (Lenders) {
        const items = Lenders.filter((x) => x.is_active)
          .map((item) => ({
            label: item.name,
            id: item.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setLenderName(items);
      }
    }, [Lenders]);

    const transformDealerMarkupData = (data: any): IDealerMarkups => {
      return {
        id: data.id || 0,
        score_range_id: data.score_range_id,
        dealer_code: dealer_code,
        lender_markup_slabs: [
          {
            id: data.lender_markup_slabs?.[0]?.id || 0,
            lender_id: data.lender_id,
            is_active: true,
            markup_slabs: data.markup_slabs
              ?.filter((slab: any) => !slab.is_deleted)
              .map((slab: any) => ({
                id: slab.markup_slab_id || 0,
                is_active: true,
                interest_rate_from: slab.interest_rate_from || 0,
                interest_rate_to: slab.interest_rate_to || 0,
                lender_fee: slab.lender_fee || 0,
                markup: slab.markup || 0,
              })),
          },
        ],
      };
    };

    const onSubmit = (data: IDealerMarkups) => {
      const transformedData = transformDealerMarkupData(data);
      if (actionType === ActionOptions.ADD) {
        SaveDealerMarkups(transformedData, {
          onSuccess() {
            reset();
            onClose();
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.error?.detail.message,
              toastState: true,
              variant: "error",
            });
          },
        });
      } else if (actionType === ActionOptions.EDIT) {
        UpdateDealerMarkups(transformedData, {
          onSuccess() {
            reset();
            onClose();
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.error?.detail.message,
              toastState: true,
              variant: "error",
            });
          },
        });
      }
    };

    return (
      <>
        <PersistentDrawer
          title={title}
          openPopUp={openPopUp}
          setPopUpState={setPopUpState}
          theme={theme}
          enabledPin={true}
          children={
            <>
              <Controller
                name="score_range_id"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id="rating_range_id"
                    name="score_range_id"
                    theme={theme}
                    label={"Credit Score Range"}
                    items={creditRatingItems}
                    value={value ?? []}
                    onChange={(e) => {
                      const selectedRange = creditRatingItems.find(
                        (item) => item.value === e.target.value
                      );
                      if (selectedRange) {
                        onChange(selectedRange.value);
                      }
                    }}
                    selectError={errors?.score_range_id?.message as string}
                    disabled={actionType === ActionOptions.EDIT ? true : false}
                  />
                )}
              />
              <Controller
                name="lender_id"
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <AutocompleteWrap className="auto-complete-wrap">
                    <Autocomplete
                      disablePortal={true}
                      id={"lender_id"}
                      theme={theme}
                      label={"Lender"}
                      placeholder="Search lender..."
                      items={lendername}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(event, newValue) => {
                        onChange(newValue?.id || null);
                      }}
                      value={lendername.find((item) => item.id === value) || null}
                      error={errors?.lender_id?.message as string}
                      disableCloseOnSelect={false}
                      multiple={false as never}
                      disabled={actionType === ActionOptions.EDIT ? true : false}
                    />
                  </AutocompleteWrap>
                )}
              />
              <Controller
                name="is_active"
                control={control}
                defaultValue={true}
                render={({ field: { value, onChange } }) => (
                  <Select
                    theme={theme}
                    label={"Status"}
                    placeholder={"Select"}
                    items={[
                      { text: "Enabled", value: true },
                      { text: "Disabled", value: false },
                    ]}
                    value={value ?? []}
                    onChange={onChange}
                  />
                )}
              />
              {markupSlabs?.map((item: ILenderMarkupSlab, index: number) => {
                item.is_active !== false && displayIndex++;
                return (
                  item.is_active !== false && (
                    <AccordionWrapSm theme={theme} key={index}>
                      <Accordion
                        theme={theme}
                        varient="filters"
                        items={[
                          {
                            content: (
                              <MarkupSlabs
                                form={form}
                                popUpOpenState={""}
                                index={index}
                              />
                            ),
                            isExpanded: true,
                            title: `MARKUP SLAB ${displayIndex}`,
                            key: `markup-slab-${index}`,
                            actionBtn: (
                              <>
                                <Button
                                  theme={theme}
                                  defaultBtn
                                  onClick={() => handleDeleteItem(index)}
                                  iconText={<Icon name="DeleteIcon" />}
                                />
                              </>
                            ),
                          },
                        ]}
                      />
                    </AccordionWrapSm>
                  )
                );
              })}
              <Box theme={theme} sx={{ mt: markupSlabs?.length > 0 ? 0 : 3 }}>
                <Button
                  theme={theme}
                  secondary
                  text="Add New Markup Slab"
                  fullWidth
                  onClick={handleAddItem}
                />
              </Box>
            </>
          }
          customFooter={
          <Button 
            theme={theme} 
            primary
            onClick={handleSubmit(onSubmit)}
            text="Save"
            fullWidth 
          />
        }
        />
      </>
    );
  };

  export default MarkupDrawer;