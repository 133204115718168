import { useTheme } from "@mui/material";
import { Grid, Input, Select } from "@ntpkunity/controls";
import { FC, useState } from "react";
import { validation } from "shared/helper/methods";
import { Controller } from "react-hook-form";
import { UseQuery_GetAllFinanceTypes } from "services";
import { useQueryClient } from "react-query";
import { QueryKeys } from "Enums";
import { IFinanceTypeModel } from "Interface";

export const MarkupSlabs: FC<{
  form: any;
  index: number;
  popUpOpenState: string;
}> = ({ form, index }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const theme = useTheme();
  const queryClient = useQueryClient();

  return (
    <>
      <Grid theme={theme} container columnSpacing={2} rowSpacing={3} mb={2}>
        <Grid theme={theme} item xs={6}>
          <Controller
            name={`markup_slabs[${index}].interest_rate_from`}
            control={control}
            rules={{
              min: {
                value: 0,
                message: "Interest Rate From cannot be less than 0%",
              },
              max: {
                value: 100,
                message: "Interest Rate From cannot be greater than 100%",
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(val) => {
                  const formattedValue = val
                    ?.replace(/[^0-9.]/g, "")
                    ?.replace(/(\..*)\./g, "$1")
                    ?.replace(/^(\d*\.{0,1}\d{0,6}).*$/, "$1");
                  setValue(
                    `markup_slabs[${index}].interest_rate_from`,
                    formattedValue ? parseFloat(formattedValue) : undefined
                  );
                }}
                theme={theme}
                fullWidth
                label={"Interest Rate From"}
                type="text"
                endAdornment="%"
                error={errors?.markup_slabs?.[index]?.interest_rate_from?.message}
                masking
                maskDecimalScale={2}
                maskNumeric
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name={`markup_slabs[${index}].interest_rate_to`}
            control={control}
            rules={{
              min: {
                value: 0,
                message: "Interest Rate To cannot be less than 0%",
              },
              max: {
                value: 100,
                message: "Interest Rate To cannot be greater than 100%",
              },
              validate: (value) => {
                const interestRateFrom = getValues(`markup_slabs[${index}].interest_rate_from`);
                if (value < interestRateFrom) {
                  return "Interest Rate To cannot be less than Interest Rate From";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(val) => {
                  const formattedValue = val
                    ?.replace(/[^0-9.]/g, "")
                    ?.replace(/(\..*)\./g, "$1")
                    ?.replace(/^(\d*\.{0,1}\d{0,6}).*$/, "$1");

                  setValue(
                    `markup_slabs[${index}].interest_rate_to`,
                    formattedValue ? parseFloat(formattedValue) : undefined
                  );
                }}
                theme={theme}
                fullWidth
                label={"Interest Rate To"}
                type="text"
                endAdornment="%"
                error={errors?.markup_slabs?.[index]?.interest_rate_to?.message}
                masking
                maskDecimalScale={2}
                maskNumeric
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name={`markup_slabs[${index}].markup`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(val) => {
                  const formattedValue = val
                    ?.replace(/[^0-9.]/g, "")
                    ?.replace(/(\..*)\./g, "$1")
                    ?.replace(/^(\d*\.{0,1}\d{0,6}).*$/, "$1");

                  setValue(
                    `markup_slabs[${index}].markup`,
                    formattedValue ? parseFloat(formattedValue) : undefined
                  );
                }}
                theme={theme}
                fullWidth
                label={"Markup"}
                type="text"
                endAdornment="%"
                error={errors?.markup_slabs?.[index]?.markup?.message}
                masking
                // disabled={disabled}
                maskDecimalScale={2}
                maskNumeric
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <Controller
            name={`markup_slabs[${index}].lender_fee`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(val) => {
                  const formattedValue = val
                    ?.replace(/[^0-9.]/g, "")
                    ?.replace(/(\..*)\./g, "$1")
                    ?.replace(/^(\d*\.{0,1}\d{0,6}).*$/, "$1");

                  setValue(
                    `markup_slabs[${index}].lender_fee`,
                    formattedValue ? parseFloat(formattedValue) : undefined
                  );
                }}
                theme={theme}
                fullWidth
                label={"Lender Fee"}
                type="text"
                endAdornment="$"
                error={errors?.markup_slabs?.[index]?.lender_fee?.message}
                masking
                maskDecimalScale={2}
                maskNumeric
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};