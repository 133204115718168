import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import {
  EP_GET_COUNTRIES,
  EP_GET_READY_CHECKLIST,
  EP_GET_STATES,
  EP_READY_CHECKLIST_EVALUATE,
  EP_GET_INSURANCE_INFO,
  EP_GET_CUSTOMER_LICENSE_INFO,
  EP_GET_CUSTOMER_DETAILS,
  EP_GET_ODOMETER,
  EP_GET_REASONS_BY_LENDER_ID,
  EP_GET_CUSTOMER_NAMES,
  EP_DOWNLOAD_DOCUMENT,
  EP_SAVE_CHECKLIST_PROGRESS,
  EP_GET_SAVED_CHECKLIST_OPTIONS,
  EP_GET_AD_USER,
  EP_UPDATE_SUBSCRIPTION,
  UPDATE_ORDER_STATUS,
  EP_DOCUMENT_PACKAGE_BY_REFERENCE_ID,
  EP_UPDATE_RETURN,
  EP_GET_RETURNS,
  EP_CUSTOMER_SEARCH,
  EP_GET_CONTRACTS,
  EP_GET_CONTRACTS_SEARCH,
  EP_AN_DEALERS,
  EP_GET_CONTRACT,
  EP_DYNAMIC_ORDERS_DATA,
  UPDATE_ORDER_STATUS_AND_LENDER
} from '@helpers/endpoints'
import { getMarketPlaceToken } from '@helpers/methods'
import { SortOrder, SortOrderFields, vehcileStatuses } from '@helpers/constants'
import { IUpdateOrderStatusPayload, IUpdateOrderStatusAndLenderPayload } from '@models'

export const useGetVehiclesAvailableConfirmation = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const requestBody = [
        {
          field: 'order_status',
          operator: 'Equals',
          value: vehcileStatuses?.VEHICLE_AVAILABLE_CONFIRMTAION,
          and_or: 'And'
        }
      ]
      if (body?.additionalParams) {
        // Push the additional variables into the request body array
        requestBody.push(...body.additionalParams)
      }
      let params = `page_size=${100000}&page_number=${0}&sort=${SortOrder.desc}&sort_by=${
        SortOrderFields.order_updated_at
      }`
      return apiService.post<any>(`${EP_DYNAMIC_ORDERS_DATA}?${params}`, requestBody)
    },
    {
      onSuccess(res: any) {
        actions.setDealerVehicleAvailableConfirmationOrders(res)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useOrderUpdateRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.AUTO_NATION_BASE_URL
      })
      const { order_id, reqBody } = body
      return apiService.patch<any>(`v1/order/admin/${order_id}`, reqBody)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateOrderStatus = () => {
  const { data, isLoading, mutate } = useMutation<any, IUpdateOrderStatusPayload, any>(
    (body: IUpdateOrderStatusPayload) => {
      const apiService = Http.createConnection()
      const { order_id, reqBody } = body
      return apiService.patch<any>(`${UPDATE_ORDER_STATUS}/${order_id}`, reqBody)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateOrderStatusAndLender = () => {
  const { data, isLoading, mutate } = useMutation<any, IUpdateOrderStatusAndLenderPayload, any>(
    (body: IUpdateOrderStatusAndLenderPayload) => {
      const apiService = Http.createConnection()
      const { order_id, lender_id, order_status } = body
      const baseUrl = `${UPDATE_ORDER_STATUS_AND_LENDER}/${order_id}/${lender_id}`
      const url = order_status ? `${baseUrl}?order_status=${order_status}` : baseUrl
      return apiService.patch<any>(url, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetReadyCheckList = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const requestBody = [
        {
          field: 'order_status',
          operator: 'Equals',
          value: vehcileStatuses?.GET_READY_CHECKLIST,
          and_or: 'And'
        }
      ]
      if (body?.additionalParams) {
        // Push the additional variables into the request body array
        requestBody.push(...body.additionalParams)
      }
      let params = `page_size=${100000}&page_number=${0}&sort=${SortOrder.desc}&sort_by=${
        SortOrderFields.order_updated_at
      }`

      return apiService.post<any>(`${EP_DYNAMIC_ORDERS_DATA}?${params}`, requestBody)
    },
    {
      onSuccess(res: any) {
        actions.setDealerGetReadyCheckList(res)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetSchedulePickUpRequest = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const requestBody = [
        {
          field: 'order_status',
          operator: 'Equals',
          value: vehcileStatuses?.SCHEDULE_PICKUP,
          and_or: 'And'
        }
      ]
      if (body?.additionalParams) {
        // Push the additional variables into the request body array
        requestBody.push(...body.additionalParams)
      }
      let params = `page_size=${100000}&page_number=${0}&sort=${SortOrder.desc}&sort_by=${
        SortOrderFields.order_updated_at
      }`
      return apiService.post<any>(`${EP_DYNAMIC_ORDERS_DATA}?${params}`, requestBody)
    },
    {
      onSuccess(res: any) {
        actions.setDealerSchedulePickups(res)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetCompletedOrdersRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })

    return apiService.post<any>(`${EP_DYNAMIC_ORDERS_DATA}?${body?.params}`, body.requestBody)
  })
  return { data, isLoading, mutate }
}

export const useGetReadyCheckListRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_GET_READY_CHECKLIST}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useReadyCheckListEvaluateRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    const { company_id, ...restPayload } = body
    const headers: any = {
      company_id: company_id
    }

    return apiService.post<any>(`${EP_READY_CHECKLIST_EVALUATE}`, restPayload, { headers })
  })
  return { data, isLoading, mutate }
}

export const useGetSingleOrderRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.get<any>(`external-orders/all?orderId=${body?.order_id}`, {})
  })
  return { data, isLoading, mutate }
}

export const useGetStates = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_STATES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCountries = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_COUNTRIES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetInsuranceDetails = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(
        EP_GET_INSURANCE_INFO + `?reference_id=${encodeURIComponent(body?.customer_ref_id)}`,
        {}
      )
    },
    {
      onSuccess(_response: any) {
        actions.setInsuranceInformation(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCustomerLicenseInfo = () => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(
        EP_GET_CUSTOMER_LICENSE_INFO +
          `?reference_id=${encodeURIComponent(body?.customer_ref_id)}&include_images=true`,
        {}
      )
    },
    {
      onSuccess(_response: any) {
        actions.setCustomerLicenseInformation(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetImageByArcifect = () => {
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.S3_API_GATEWAY
      })
      return apiService.get<any>(
        EP_GET_CUSTOMER_LICENSE_INFO +
          `?reference_id=${encodeURIComponent(body?.customer_ref_id)}&include_images=true`,
        {}
      )
    },
    {
      onSuccess(_response: any) {
        ///  actions.setCustomerLicenseInformation(_response);
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useCreateCustomerLicenseInfo = () => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection()

      return apiService.post<any>(EP_GET_CUSTOMER_LICENSE_INFO, body)
    },
    {
      onSuccess(_response: any) {
        return actions.setCustomerLicenseInformation(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useUpdateCustomerLicenseInfo = () => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      const apiService = Http.createConnection()
      return apiService.patch<any>(
        `${EP_GET_CUSTOMER_LICENSE_INFO}?reference_id=${reference_id}&is_presigned_url_required=true`,
        restPayload
      )
    },
    {
      onSuccess(_response: any) {
        return actions.setCustomerLicenseInformation(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCustomerDetails = (): any => {
  const { data, isLoading, error, mutate } = useMutation((body: any) => {
    const apiService = Http.createConnection()
    return apiService.get<any>(
      EP_GET_CUSTOMER_DETAILS + `?reference_id=${encodeURIComponent(body?.refId)}`,
      {}
    )
  })
  return { data, isLoading, error, mutate }
}

export const useGetOdometerRequest = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      const headers: any = {
        company_id: body?.company_id
      }

      return apiService.get<any>(EP_GET_ODOMETER + `/${body?.company_id}`, {}, { headers })
    },
    {
      onSuccess(_response: any) {
        actions.setOdometerData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetReasonsByLender = (): any => {
  const { data, isLoading, error, mutate } = useMutation((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    const token = getMarketPlaceToken()
    const headers: any = {
      Authorization: `Bearer ${token}`
    }
    if (body?.company_id) {
      headers.company_id = body?.company_id
    }
    return apiService.get<any>(EP_GET_REASONS_BY_LENDER_ID, {}, { headers })
  })
  return { data, isLoading, error, mutate }
}

export const useCreateEventRequest = (): any => {
  const { data, isLoading, error, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.AUTO_NATION_BASE_URL
      })
      const { order_id, reqBody } = body
      return apiService.post<any>(`v1/order/admin/${order_id}/event`, reqBody)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useDonwnloadDocument = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_DOWNLOAD_DOCUMENT + `?artifect_id=${body?.artifact_id}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetCustomerNames = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      var params = new URLSearchParams()
      body?.map((val: any) => {
        if (!val.includes('%')) {
          params.append('reference_ids', val)
        } else if (val.includes('%')) {
          params.append('reference_ids', encodeURIComponent(val))
        }
      })
      return apiService.get<any>(`${EP_GET_CUSTOMER_NAMES}?${params}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetChecklistSavedProgress = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(
        EP_GET_SAVED_CHECKLIST_OPTIONS +
          `?customer_reference_id=${encodeURIComponent(
            body?.customer_reference_id
          )}&checklist_identifier=${body.checklist_identifier}&order_reference_id=${
            body.order_reference_id
          }`,
        {}
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveChecklistProgress = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const { data } = body
      return apiService.post<any>(EP_SAVE_CHECKLIST_PROGRESS, data)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateChecklistProgress = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const { data } = body
      return apiService.patch<any>(
        EP_SAVE_CHECKLIST_PROGRESS +
          `?order_reference_id=${encodeURIComponent(data[0]?.external_order_ref_number)}`,
        data
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetADUser = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.UMS_BASE_URL
    })
    const { data } = body
    return apiService.post<any>(EP_GET_AD_USER, data)
  })
  return { data, isLoading, mutate }
}

export const useDocumentPackage = () => {
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({})
      var query_string = `reference_id=${encodeURIComponent(body.reference_id)}`
      return apiService.get<any>(`${EP_DOCUMENT_PACKAGE_BY_REFERENCE_ID}?${query_string}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetVehicleReturnsRequest = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.EOT_API_GATEWAY
      })
      const searchParams = new URLSearchParams()
      Object.keys(body?.params).forEach(function (key) {
        searchParams.append(key, body?.params[key])
      })
      return apiService.get<any>(`${EP_GET_RETURNS}?${searchParams}`)
    },
    {
      onSuccess(res: any) {
        actions.setDealerReturnVehicle(res)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetMultipleOrdersRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.get<any>(`/external-orders/all?orderId=${body?.join(',')}`)
  })
  return { data, isLoading, mutate }
}

export const useUpdateReturnDataRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.EOT_API_GATEWAY
    })
    return apiService.patch<any>(`${EP_UPDATE_RETURN}/${body?.return_identifier}`, {
      ...body?.data
    })
  })
  return { data, isLoading, mutate }
}

export const useUpdateSubscriptionStatus = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.EOT_API_GATEWAY
    })
    return apiService.post<any>(`${EP_UPDATE_SUBSCRIPTION}/${body.identifier}`, body)
  })
  return { data, isLoading, mutate }
}

export const useSearchCustomerRequest = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    return apiService.post<any>(`${EP_CUSTOMER_SEARCH}?${body.params}`, body?.requestBody)
  })
  return { data, isLoading, mutate }
}

export const useGetContractsByOrderIds = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    var params = new URLSearchParams()
    body?.map((val: any) => {
      if (!val.includes('%')) {
        params.append('external_order_ids', val)
      } else if (val.includes('%')) {
        params.append('external_order_ids', encodeURIComponent(val))
      }
    })
    return apiService.get<any>(`${EP_GET_CONTRACTS}?${params}`)
  })
  return { data, isLoading, mutate }
}

export const useGetContractsByDate = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    return apiService.get<any>(`${EP_GET_CONTRACTS_SEARCH}?${body?.contract_params}`)
  })
  return { data, isLoading, mutate }
}

export const useGetANDealers = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(() => {
    const apiService = Http.createConnection()
    return apiService.get<any>(`${EP_AN_DEALERS}`)
  })
  return { data, isLoading, mutate }
}

export const useGetCustomerNamesForAN = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const requestBody = {
        reference_ids: body.map((val: any) => {
          return !val.includes('%') ? val : encodeURIComponent(val)
        })
      }
      return apiService.post<any>(`${EP_GET_CUSTOMER_NAMES}`, requestBody)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDealerWorkQueueSearch = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.AUTO_NATION_BASE_URL
    })
    return apiService.get<any>(`v1/orders?${body?.params}`, {})
  })
  return { data, isLoading, mutate }
}

export const useGetContracts = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    // Initialize params as an empty object with an append method

    return apiService.get<any>(`${EP_GET_CONTRACTS}?external_order_ids=${body}`)
  })
  return { data, isLoading, mutate }
}

export const useGetContractData = (): any => {
  const { data, isLoading, mutate } = useMutation<any>((body: any) => {
    const apiService = Http.createConnection()
    // Initialize params as an empty object with an append method

    return apiService.get<any>(
      `${EP_GET_CONTRACT}?external_order_ids=${
        body?.external_order_id
      }&customer_reference_id=${encodeURIComponent(body?.customer_ref_id)}`
    )
  })
  return { data, isLoading, mutate }
}
