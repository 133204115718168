import { FC } from "react";
import { styled, useTheme } from "@mui/material";
import {
    Box,
    Button,
    DatePicker,
    Grid,
    IBoxProps,
    Icon,
    Input,
    Select,
    Switch,
    Typography,
} from "@ntpkunity/controls";
import { Controller, UseFieldArrayRemove } from "react-hook-form";
import {
    ValidationMessages,
    DialogMessages,
} from "Enums";

const HolidayRow: FC<{
    form: any;
    dealer_code: string;
    index:number;
    remove: UseFieldArrayRemove
}> = ({
    form,
    dealer_code,
    index,
    remove
}) => {
        const theme = useTheme();
        const {
            control,
            setValue,
            getValues,
            watch,
            formState: { errors },
        } = form;
        const test = "2024-12-24T14:45:32+00:00"
        return (
            <Box theme={theme} className="hd-row">
                <Box theme={theme} className="hd-col">
                    <Controller
                        name={`holidayData.${index}.holiday_purpose`}
                        control={control}
                        defaultValue={""}
                        rules={{
                            required: ValidationMessages.HOLIDAY_PURPOSE_REQUIRED,
                            validate: (value) => value.trim() !== "" || ValidationMessages.HOLIDAY_PURPOSE_REQUIRED,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                theme={theme}
                                fullWidth
                                placeholder={"Type here.."}
                                label={"Purpose"}
                                type="text"
                                value={value?.trimStart()}
                                onChange={onChange}
                                error={errors?.holiday_purpose?.message}
                            />
                        )}
                    />
                </Box>
                <Box theme={theme} className="hd-col">
                     <Controller
                        name={`holidayData.${index}.holiday_occurance`}
                        control={control}
                        defaultValue={""}
                        rules={{
                            required: ValidationMessages.HOLIDAY_OCCURANCE_REQUIRED,
                          }}
                        render={({ field: { value, onChange } }) => (
                        <Select
                            theme={theme}
                            label={"Occurance"}
                            placeholder={"Select"}
                            items={[
                            { text: "Yearly ", value: "Yearly" },
                            { text: "One-Time", value: "One-Time" },
                            ]}
                            value={value ?? []}
                            onChange={onChange}
                            selectError={errors?.holiday_occurance?.message}
                        />
                        )}
                    />
                </Box>
                <Box theme={theme} className="hd-col">
                    {/* <DatePicker theme={theme} /> */}
                    <Controller
                            name={`holidayData.${index}.holiday_date`}
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: ValidationMessages.HOLIDAY_DATE_REQUIRED,
                              }}
                            render={({ field: { value, onChange } }) => (
                                <DatePicker
                                    theme={theme}
                                    label={"Planned Return Date"}
                                    value={value ?? ""}
                                    disablePast
                                    // maxDate={dayjs(Date.now())?.add(plannedConfiguredDays,"day")}  
                                    // minDate={(test)}
                                    onChange={(e: Date) => {
                                        if (
                                            e.getFullYear() !== 1969 &&
                                            e.getFullYear() !== 1970 
                                        ) { onChange(e)}
                                    }}
                                    onKeyDown={(e: any) => e.preventDefault()}
                                    error={errors?.returns?.holiday_date?.message}
                                />
                            )}
                        />
                </Box>
                <Box theme={theme} className="hd-col">
                    <Button
                        theme={theme}
                        secondary
                        fullWidth
                        onClick={(e) => {
                            remove(index);
                        }}
                        iconText={<Icon name="DeleteIcon" />}
                    />
                </Box>
            </Box>
        );
    };

export default HolidayRow;
