import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneInputProps } from "./phone-input.props";
import { PhoneInputWrap } from "./phone-input.style";
import clsx from "clsx";
import { useState } from "react";

export const Phone = ({
  country,
  value: initialValue,
  onChange,
  placeholder,
  countryCodeEditable = false,
  disabled,
  enableSearch,
  disableSearchIcon,
  inputProps,
  label,
  showInfoIcon,
  helpText,
  status,
  errorText,
}: PhoneInputProps) => {
  const [phoneValue, setPhoneValue] = useState(initialValue || "");

  // Handler for value change
  const handlePhoneChange = (newValue) => {
    setPhoneValue(newValue); // Update local state
    if (onChange) {
      onChange(newValue); // Trigger external onChange handler
    }
  };
  return (
    <PhoneInputWrap
      className={clsx({
        "phone-input-wrap": true,
        "phone-input-disabled": disabled,
        "phone-input-invalid": status === "error",
      })}
    >
      {label && (
        <label
          className={clsx({
            "phone-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="phone-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <PhoneInput
        country={country}
        value={phoneValue}
        onChange={handlePhoneChange}
        placeholder={placeholder}
        disabled={disabled}
        countryCodeEditable={countryCodeEditable}
        enableSearch={enableSearch}
        disableSearchIcon={disableSearchIcon}
        inputProps={inputProps}
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {status === "error" && errorText && (
        <label className="error-text text-error text--disclaimer">
          {errorText}
        </label>
      )}
    </PhoneInputWrap>
  );
};
