import { Theme, styled } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Grid,
  Icon,
  MultiSelect,
  Accordion,
  Box,
  Button,
  DualSlider,
  Input,
  Checkbox,
  Tooltip
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { MultiSelectWrap } from '.'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'
import { mapPayload } from '@helpers/lead'
import { useUpdateLead } from '@hooks/lead-management'
import { useParams } from 'react-router-dom'
import { LeadKey } from '@helpers/enums/lead'
import { useGetInventoryModels, useGetInventoryTrims } from '@hooks/queries/inventoryManagement'

type ICustomFilterControlProps = {
  theme: Theme
  referenceData: any
  onSave: (data: any) => unknown
}

const CustomFilters = styled('div')(({ theme }) => ({
  '&.custom-filters': {
    // paddingRight: 24,
    width: 'calc(100% - 0px)',
    '.scroll': {
      height: 'calc(100vh - 125px)',
      flexGrow: 1,
      overflowY: 'hidden',
      overflowX: 'hidden',
      position: 'relative',
      scrollbarGutter: 'stable',
      '@supports (-moz-appearance:none)': {
        scrollbarWidth: 'thin'
      },
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent',
        marginBottom: 70
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)'
      },
      '&:hover': {
        overflowY: 'auto',
        '@-moz-document url-prefix()': {
          overflowY: 'auto'
        }
      },
      '.switch-label': {
        display: 'flex',
        width: '100%',
        '.span-col': {
          flex: 1
        },
        '.switch-control-span': {
          float: 'right',
          textAlign: 'right',
          '.span-col': {
            float: 'right',
            flex: 1
          }
        }
      },
      '.filter-item': {
        marginBottom: 8,
        '.u-accordian-wrap': {
          backgroundColor: '#F8F8F8',
          marginBottom: '8px !important',
          borderRadius: 8,
          '.MuiAccordionSummary-root': {
            padding: '16px 24px',
            '.MuiAccordionSummary-content': {
              margin: 0
            },
            '.sub-title .ind': {
              display: 'inline-block',
              width: 12,
              height: 12,
              borderRadius: 12,
              backgroundColor: theme.palette.primary.main
            },
            '&.Mui-expanded': {
              '.sub-title .ind': {
                display: 'none'
              }
            }
          },
          '.u-accordian-body': {
            border: 'none',
            padding: 24,
            paddingTop: 0,
            marginTop: 8,
            '.checks-wrap': {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: '-8px',
              '.u-form-group': {
                width: '100%',
                '.MuiInputLabel-root': {
                  backgroundColor: '#F8F8F8'
                }
              },
              '&.multi-select': {
                '.u-form-group': {
                  marginBottom: 16,
                  '&:last-child': {
                    marginBottom: 0
                  }
                }
              },
              '.custom-checkbox-wrap': {
                flex: '0 0 100%',
                maxWidth: '100%',
                '&:last-child': {
                  '.u-custom-control-label': {
                    marginBottom: 0
                  }
                }
              },
              '.slider-wrap': {
                width: '100%'
              }
            }
          }
        },

        '.slider-wrap': {
          '.slider-values': {
            color: theme.palette.grey[600]
          }
        },
        '&.filters-cta': {
          '.btn:not(:first-of-type)': {
            marginLeft: 8,
            flexGrow: 1
          }
        },
        '&.btn-sticky': {
          backgroundColor: theme.palette.common.white,
          borderTop: '1px solid' + theme.palette.grey[100],
          position: 'sticky',
          paddingTop: 25,
          margin: 0,
          zIndex: 1,
          bottom: 0
        }
      }
    }
  }
}))

interface IFormInputs {
  minSellingPrice: number
  maxSellingPrice: number
  year_from: number
  year_to: number
  years: number[]
  makes: string[]
  models: string[]
  asset_conditions: [x: boolean][]
  trims: string[]
  vin: string
  stock: string
}

const CustomFilterControl: FC<ICustomFilterControlProps> = ({ theme, referenceData, onSave }) => {
  const assetConditionsArray = Array.from(new Set(referenceData?.asset_condition))
  const yearsArray = referenceData?.years || []
  const { customer_reference_id } = useParams()
  const validYears = yearsArray.filter((year: number) => typeof year === 'number' && !isNaN(year))
  const { mutate: updateLead } = useUpdateLead()
  const defaultValue = {
    minSellingPrice: referenceData?.selling_price?.[0] || 0,
    maxSellingPrice: referenceData?.selling_price?.[1] || 0,
    asset_conditions: [],
    year_from: validYears.length > 0 ? Math.min(...validYears) : 0,
    year_to: validYears.length > 0 ? Math.max(...validYears) : 0,
    years: validYears,
    make: [],
    makes: [],
    models: [],
    trims: [],
    vin: '',
    stock: ''
  }

  const [leadFilter, setLeadFilter] = useState<boolean>(false)
  const { handleSubmit, control, getValues, reset, setValue, watch } = useForm<IFormInputs>({
    defaultValues: defaultValue
  })
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const {
    dealerInfo: { dealer_code, company_id }
  } = states

  const [isAssetConditionExpanded, setIsAssetConditionExpanded] = useState(true)
  const [isSellingPriceExpanded, setIsSellingPriceExpanded] = useState(true)
  const [isYearExpanded, setIsYearExpanded] = useState(true)
  const [isMakeModelTrimExpanded, setIsMakeModelTrimExpanded] = useState(true)
  const [isVinExpanded, setIsVinExpanded] = useState(true)
  const [isStockNumberExpanded, setIsStockNumberExpanded] = useState(true)
  const handleAssetConditionClose = (_event: any, _key: any) => {
    setIsAssetConditionExpanded(false)
  }
  const handleSellingPriceClose = (_event: any, _key: any) => {
    setIsSellingPriceExpanded(false)
  }
  const handleYearClose = (_event: any, _key: any) => {
    setIsYearExpanded(false)
  }
  const handleMakeModelClose = (_event: any, _key: any) => {
    setIsMakeModelTrimExpanded(false)
  }
  const handleVinClose = (_event: any, _key: any) => {
    setIsVinExpanded(false)
  }
  const handleStockNumClose = (_event: any, _key: any) => {
    setIsStockNumberExpanded(false)
  }

  const handleAssetConditionOpen = (_event: any, _key: any) => {
    setIsAssetConditionExpanded(true)
  }
  const handleSellingPriceOpen = (_event: any, _key: any) => {
    setIsSellingPriceExpanded(true)
  }
  const handleMakeModelTrimOpen = (_event: any, _key: any) => {
    setIsMakeModelTrimExpanded(true)
  }
  const handleYearOpen = (_event: any, _key: any) => {
    setIsYearExpanded(true)
  }
  const handleVinOpen = (_event: any, _key: any) => {
    setIsVinExpanded(true)
  }
  const handleStockNoOpen = (_event: any, _key: any) => {
    setIsStockNumberExpanded(true)
  }

  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const selectedMake = watch('makes')
  const selectedModel = watch('models')

  const inventoryMakeItems = referenceData?.asset_makes?.map((item: string) => {
    return { text: item, value: item }
  })
  const { data: inventoryModels } = useGetInventoryModels(dealer_code, company_id, selectedMake)
  const { data: inventoryTrims } = useGetInventoryTrims(dealer_code, company_id, selectedModel)
  const inventoryModelItems = inventoryModels?.result?.flatMap((result) =>
    result?.asset_models?.map((item) => ({
      text: item?.model_name,
      value: item?.model_code
    }))
  )
  const inventoryTrimItems = inventoryTrims?.result?.flatMap((result) =>
    result?.asset_trims?.map((item) => ({
      text: item?.trim_name,
      value: item?.trim_code
    }))
  )

  const onSubmit = () => {
    // Initialize an object to hold the parameters
    const params: any = {
      vehicle_status: 'Available',
      listing_status: 'listed',
      internet_price_from: getValues('minSellingPrice'),
      internet_price_to: getValues('maxSellingPrice'),
      year_from: getValues('year_from'),
      year_to: getValues('year_to')
    }

    // Add conditions for 'asset_conditions'
    const assetConditions = getValues('asset_conditions') || {}
    if (!!assetConditions) {
      params[`multiple_asset_condition`] = Object.keys(assetConditions).filter(
        (key: any) => assetConditions[key]
      )
    }

    // Add makes
    const makes = getValues('makes')
    if (makes?.length > 0) {
      params[`multiple_asset_make`] = makes
    }

    // Add models
    const models = getValues('models')
    if (models?.length > 0) {
      const modelName = models.map((modelCode: any) => {
        return inventoryModelItems?.find((item) => item.value === modelCode)?.text
      })
      params[`multiple_asset_model`] = modelName
    }

    // Add trims
    const trims = getValues('trims')
    if (trims?.length > 0) {
      const trimsName = trims.map((trimCode: any) => {
        return inventoryTrimItems?.find((item) => item.value === trimCode)?.text
      })
      params[`multiple_asset_trim`] = [...trimsName]
      params[`asset_trim_codes`] = [...trims]
    }

    // Add VIN
    const vin = getValues('vin')
    if (vin) {
      params[`vin`] = vin.replace(/\s/g, '') // Remove spaces from VIN
    }

    // Add stock number
    const stock = getValues('stock')
    if (stock) {
      params[`stock_number`] = stock.replace(/\s/g, '') // Remove spaces from stock number
    }

    onSave(params)

    // If lead filter is true, process lead information
    if (leadFilter) {
      let form_data = getValues()
      let { years, ...form_data_without_years } = form_data
      let lead = mapPayload(
        states?.dealerAndLenderInfo?.id,
        customer_reference_id ?? states?.customerData?.reference_id,
        LeadKey.Vehicle_Search,
        form_data_without_years
      )
      updateLead(lead)
      setLeadFilter(false)
    }
  }

  useEffect(() => {
    const availableMakes = referenceData?.asset_makes || []
    const defaultMakeValue = availableMakes.length === 1 ? [availableMakes[0]] : []
    if (defaultMakeValue) setValue('makes', defaultMakeValue)
  }, [])

  useEffect(() => {
    onSubmit()
  }, [])

  const formatCurrency = (number: any): any => {
    const options = {
      style: 'currency',
      currency: { defaultCurrency }
    }
    let numberConvertor = number?.toLocaleString(options)
    numberConvertor = numberConvertor !== undefined ? numberConvertor : `${defaultCurrency}0`
    const currencyAdded = `${defaultCurrency}${numberConvertor}`
    return currencyAdded
  }
  const [sliderValue, setSliderValue] = useState<any[]>([
    referenceData?.selling_price?.[0],
    referenceData?.selling_price?.[1]
  ])
  const [yearSliderValue, setYearSliderValue] = useState<number[]>([
    validYears.length > 0 ? Math.min(...validYears) : 0,
    validYears.length > 0 ? Math.max(...validYears) : 0
  ])
  function isAnyCheckboxChecked(conditions: Record<string, boolean> | undefined): boolean {
    if (!conditions) return false

    return Object.values(conditions).some((value) => value === true)
  }

  return (
    <CustomFilters className="custom-filters" theme={theme}>
      <Box theme={theme} className="scroll">
        <form onSubmit={handleSubmit(onSubmit)} id="vehicleForm">
          <>
            {/* Search Key word */}
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isAssetConditionExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap">
                        {assetConditionsArray?.length > 0 &&
                          assetConditionsArray?.map((condition: any, index: number) => (
                            <Controller
                              key={index}
                              name={`asset_conditions.${condition}`}
                              control={control}
                              render={({ field }) => (
                                <Checkbox
                                  label={condition}
                                  theme={theme}
                                  checkBoxChecked={field.value ? true : false}
                                  {...field}
                                  value={field.value ? true : false}
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setIsAssetConditionExpanded(true)
                                  }}
                                ></Checkbox>
                              )}
                            />
                          ))}
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleAssetConditionClose(event, 'asset_conditions')
                    },
                    onOpenTab(event) {
                      handleAssetConditionOpen(event, 'asset_conditions')
                    },
                    title: 'Asset Condition',
                    key: 'string',
                    subtitle: isAnyCheckboxChecked(
                      watch('asset_conditions') as unknown as Record<string, boolean>
                    ) ? (
                      <>
                        <span className="ind" />
                      </>
                    ) : (
                      <></>
                    )
                  }
                ]}
              />
            </Box>
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isSellingPriceExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap">
                        <Grid theme={theme} container item spacing={2}>
                          <Grid theme={theme} item xs={6} md={6} lg={6}>
                            <Controller
                              name="minSellingPrice"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  theme={theme}
                                  fullWidth
                                  value={field.value ? field?.value.toLocaleString() : ''}
                                  label={'Min'}
                                  type="text"
                                  onChange={(e) => {
                                    const numericValue = parseFloat(e.replace(/[^0-9]/g, ''))
                                    if (!isNaN(numericValue)) {
                                      field.onChange(numericValue)
                                      setSliderValue([numericValue, getValues('maxSellingPrice')])
                                      setIsSellingPriceExpanded(true)
                                    } else {
                                      field.onChange(null)
                                      setSliderValue([0, getValues('maxSellingPrice')])
                                    }
                                  }}
                                  onBlur={() => {
                                    if (field.value === null) {
                                      field.onChange(null)
                                    }
                                  }}
                                  startAdornment={defaultCurrency}
                                />
                              )}
                            />
                          </Grid>
                          <Grid theme={theme} item xs={6} md={6} lg={6}>
                            <Controller
                              name="maxSellingPrice"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  theme={theme}
                                  fullWidth
                                  value={field.value ? field?.value.toLocaleString() : ''}
                                  label={'Max'}
                                  type="text"
                                  onChange={(e) => {
                                    const numericValue = parseFloat(e.replace(/[^0-9]/g, ''))
                                    if (!isNaN(numericValue)) {
                                      field.onChange(numericValue)
                                      setSliderValue([getValues('minSellingPrice'), numericValue])
                                      setIsSellingPriceExpanded(true)
                                    } else {
                                      field.onChange(null)
                                      setSliderValue([getValues('minSellingPrice'), 0])
                                    }
                                  }}
                                  onBlur={() => {
                                    if (field.value === null) {
                                      field.onChange(null)
                                    }
                                  }}
                                  startAdornment={defaultCurrency}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Box theme={theme} sx={{ mt: 2, width: '100%' }}>
                          <DualSlider
                            theme={theme}
                            label=""
                            value={sliderValue}
                            minValue={referenceData?.selling_price?.[0]}
                            maxValue={referenceData?.selling_price?.[1]}
                            defaultValue={sliderValue}
                            valuetext={() => ''}
                            template={(value) => `${formatCurrency(value)}`}
                            onChange={(e) => {
                              setSliderValue(e)
                              setValue('minSellingPrice', e[0])
                              setValue('maxSellingPrice', e[1])
                              setIsSellingPriceExpanded(true)
                            }}
                            steps={5}
                          ></DualSlider>
                        </Box>
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleSellingPriceClose(event, 'minSellingPrice'),
                        handleSellingPriceClose(event, 'maxSellingPrice')
                    },
                    onOpenTab(event) {
                      handleSellingPriceOpen(event, 'minSellingPrice'),
                        handleSellingPriceOpen(event, 'maxSellingPrice')
                    },

                    title: 'Selling Price',
                    key: 'string',
                    subtitle:
                      getValues('minSellingPrice') || getValues('maxSellingPrice') ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                  }
                ]}
              />
            </Box>
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isYearExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap">
                        <Grid theme={theme} container item spacing={2}>
                          <Grid theme={theme} item xs={6} md={6} lg={6}>
                            <Controller
                              name="year_from"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  fullWidth
                                  theme={theme}
                                  label={'From'}
                                  value={field.value}
                                  type="number"
                                  onBlur={(e: any) => {
                                    if (parseInt(e.target.value) < getValues('year_to')) {
                                      var gettingYear: any = getValues('year_to')
                                      setYearSliderValue([+e.target.value, parseInt(gettingYear)])
                                      setIsYearExpanded(true)
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid theme={theme} item xs={6} md={6} lg={6}>
                            <Controller
                              name="year_to"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  fullWidth
                                  theme={theme}
                                  label={'To'}
                                  type="number"
                                  value={field.value}
                                  onBlur={(e: any) => {
                                    if (
                                      parseInt(e.target.value) <=
                                      (validYears.length > 0 ? Math.max(...validYears) : 0)
                                    ) {
                                      var gettingYear: any = getValues('year_from')
                                      setYearSliderValue([parseInt(gettingYear), +e.target.value])
                                      setIsYearExpanded(true)
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Box theme={theme} sx={{ mt: 2, width: '100%' }}>
                          <Controller
                            name={`years`}
                            control={control}
                            render={({ field }) => (
                              <DualSlider
                                {...field}
                                theme={theme}
                                valuetext={() => ''}
                                label=""
                                value={yearSliderValue}
                                minValue={validYears.length > 0 ? Math.min(...validYears) : 0}
                                maxValue={validYears.length > 0 ? Math.max(...validYears) : 0}
                                defaultValue={[
                                  validYears.length > 0 ? Math.min(...validYears) : 0,
                                  validYears.length > 0 ? Math.max(...validYears) : 0
                                ]}
                                onChange={(e) => {
                                  setYearSliderValue(e)
                                  setValue('year_from', e[0])
                                  setValue('year_to', e[1])
                                  setIsYearExpanded(true)
                                }}
                                steps={1}
                              ></DualSlider>
                            )}
                          />
                        </Box>
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleYearClose(event, 'year_from')
                      handleYearClose(event, 'year_to')
                    },
                    onOpenTab(event) {
                      handleYearOpen(event, 'year_from')
                      handleYearOpen(event, 'year_to')
                    },
                    title: 'Year',
                    key: 'string',
                    subtitle:
                      getValues('year_from') || getValues('year_to') ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                  }
                ]}
              />
            </Box>
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isMakeModelTrimExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap multi-select">
                        <MultiSelectWrap>
                          <Controller
                            name="makes"
                            control={control}
                            render={({ field }) => (
                              <MultiSelect
                                disablePortal
                                {...field}
                                id={'make_id'}
                                sxProps={{ m: 0, width: '100%' }}
                                theme={theme}
                                label={'Make'}
                                items={inventoryMakeItems ?? []}
                                value={field.value || []}
                                onChange={(e: any) => {
                                  field.onChange(e)
                                  setValue('models', [])
                                  setValue('trims', [])
                                  setIsMakeModelTrimExpanded(true)
                                }}
                              />
                            )}
                          />
                        </MultiSelectWrap>
                        <MultiSelectWrap>
                          <Controller
                            name="models"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <MultiSelect
                                disablePortal
                                {...field}
                                sxProps={{ m: 0, width: '100%' }}
                                theme={theme}
                                sx={{ mb: 2 }}
                                label={'Model'}
                                disabled={selectedMake?.length < 1}
                                items={inventoryModelItems ?? []}
                                value={field.value || []}
                                onChange={(e) => {
                                  field.onChange(e)
                                  setValue('trims', [])
                                  setIsMakeModelTrimExpanded(true)
                                }}
                              />
                            )}
                          />
                        </MultiSelectWrap>
                        <MultiSelectWrap>
                          <Controller
                            name="trims"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <MultiSelect
                                disablePortal
                                {...field}
                                sxProps={{ m: 0, width: '100%' }}
                                theme={theme}
                                label={'Trim'}
                                disabled={selectedModel?.length < 1 || selectedMake?.length < 1}
                                items={inventoryTrimItems ?? []}
                                value={field.value || []}
                                onChange={(e) => {
                                  field.onChange(e)
                                  setIsMakeModelTrimExpanded(true)
                                }}
                              />
                            )}
                          />
                        </MultiSelectWrap>
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleMakeModelClose(event, 'makes')
                      handleMakeModelClose(event, 'models')
                      handleMakeModelClose(event, 'trims')
                    },
                    onOpenTab(event) {
                      handleMakeModelTrimOpen(event, 'makes')
                      handleMakeModelTrimOpen(event, 'models')
                      handleMakeModelTrimOpen(event, 'trims')
                    },
                    title: 'Make / Model / Trim',
                    key: 'string',
                    subtitle:
                      (getValues('makes') && getValues('makes').length > 0) ||
                      (getValues('models') && getValues('models').length > 0) ||
                      (getValues('trims') && getValues('trims').length > 0) ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                  }
                ]}
              />
            </Box>
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isVinExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap">
                        <Controller
                          name="vin"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              {...field}
                              theme={theme}
                              fullWidth
                              placeholder="Enter VIN..."
                              type={'text'}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e)
                                setIsVinExpanded(true)
                              }}
                            />
                          )}
                        />
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleVinClose(event, 'vin')
                    },
                    onOpenTab(event) {
                      handleVinOpen(event, 'vin')
                    },
                    title: 'VIN',
                    key: 'string',
                    subtitle: watch('vin') ? (
                      <>
                        <span className="ind" />
                      </>
                    ) : (
                      <></>
                    )
                  }
                ]}
              />
            </Box>
            <Box className="filter-item" theme={theme}>
              <Accordion
                theme={theme}
                items={[
                  {
                    isExpanded: isStockNumberExpanded,
                    content: (
                      <Box theme={theme} className="checks-wrap">
                        <Controller
                          name="stock"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              {...field}
                              theme={theme}
                              fullWidth
                              placeholder="Enter Stock Number..."
                              type={'text'}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e)
                                setIsStockNumberExpanded(true)
                              }}
                            />
                          )}
                        />
                      </Box>
                    ),
                    onCloseTab(event) {
                      handleStockNumClose(event, 'stock')
                    },
                    onOpenTab(event) {
                      handleStockNoOpen(event, 'stock')
                    },
                    title: 'Stock Number',
                    key: 'string',
                    subtitle: watch('stock') ? (
                      <>
                        <span className="ind" />
                      </>
                    ) : (
                      <></>
                    )
                  }
                ]}
              />
            </Box>
          </>
          <Box
            mt={3}
            theme={theme}
            className="filter-item filters-cta btn-sticky"
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            <Tooltip theme={theme} title="Reset Filters">
              <Button
                theme={theme}
                secondary
                iconText={<Icon name="ResetIcon" />}
                onClick={() => {
                  reset(defaultValue, {
                    keepValues: false,
                    keepIsSubmitted: false
                  })

                  setSliderValue([referenceData?.selling_price[0], referenceData?.selling_price[1]])
                  setYearSliderValue([
                    Math.min(...referenceData?.years),
                    Math.max(...referenceData?.years)
                  ])

                  onSubmit()
                }}
              ></Button>
            </Tooltip>
            <Button
              theme={theme}
              type="submit"
              fullWidth
              primary
              text={'Apply Filters'}
              onClick={() => setLeadFilter(true)}
            ></Button>{' '}
          </Box>
        </form>
      </Box>
    </CustomFilters>
  )
}

export default CustomFilterControl
