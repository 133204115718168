import "./personal-details.scss";
import "../../atoms/form-inputs/form-input.style.css";
import { Button, Input } from "@src/components/atoms";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";

export const PersonalDetails = ({
  stepOneDataReceivedEvent,
}: {
  stepOneDataReceivedEvent: () => void;
}) => {
  return (
    <div className="form-wrapper">
      <p className="form-wrapper header text--body-2">
        To confirm your identity, please provide the following information.
      </p>
      <form className="form-wrapper personal-details-form-wrapper personal-details-form">
        <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
          <div>
            <Input
              id={"first_name"}
              name={"first_name"}
              placeholder="First name"
              label="First name"
            />
          </div>
          <div>
            <Input
              id={"middle_name"}
              name={"middle_name"}
              placeholder="Middle name"
              label="Middle name (optional)"
            />
          </div>
          <div>
            <Input
              id={"last_name"}
              name={"last_name"}
              placeholder="Last name"
              label="Last name"
            />
          </div>
        </div>
        <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
          <div>
            <Datepicker
              id={"date_of_birth"}
              name={"date_of_birth"}
              placeholder="Choose"
              label="Date of birth"
              format="MMM DD, YYYY"
            />
          </div>
          <div>
            <Input
              id={"social_security_number"}
              name={"social_security_number"}
              placeholder="000-00-0000"
              label="Social security number"
            />
          </div>
        </div>
        <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
          <div>
            <Phone country="us" label="Phone number" value="" />
          </div>
          <div>
            <Phone country="gb" label="Mobile number (optional)" value="" />
          </div>
        </div>
        <div className="form-wrapper personal-details-form-wrapper personal-detail-form--row">
          <div>
            <Input
              id={"email_address"}
              name={"email_address"}
              placeholder="Email address"
              label="Email address"
              type="email"
            />
          </div>
        </div>

        <p className="form-wrapper personal-details-form-wrapper disclaimer text--disclaimer">
          By entering my phone and continuing, I agree that the BMW Center, its
          affiliates, and service providers may make autodialed calls or send
          automated or auto dialed text messages relating to my application and
          vehicle purchase, and related issues. By providing us with your phone
          number, you provide us with your prior written express consent to
          receive such calls or text messages. You represent that the phone
          number is yours. Message and data rates may apply. You agree to the
          BMW Center Terms and Privacy Policy.
        </p>
      </form>
      <div className="form-wrapper form-wrapper--button-top-spacer">
        <Button
          type="default"
          htmlType={"button"}
          text="Save & continue"
          onClick={stepOneDataReceivedEvent}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
