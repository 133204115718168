import { FC, useEffect, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { Button, Grid, Box, DrawerWrap, ProductBanner, PageHeader } from 'components'
import { useStoreContext } from '@store/storeContext'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import {
  useUpdateDocument,
  useReSubmitOrderById,
  useDeleteDocument
} from '@apis/order-management.service'
import FinancialStipulation from './financial-stipulation'
import NonFinancialStipulation from './non-financial-stipulation'
import {
  FinancialStipType,
  HeaderButton,
  StipulationType
} from '@helpers/enums/stipulation-type.enum'
import { OrderApproved, Notification } from './order-stipulations-style'
import {
  useGetOrderSubmissionResponse,
  useGetNonFinancialStips,
  useGetStipulationsByLenderId
} from '@apis/stipulation.service'
import { IOrderDetail, IStipulationResponse } from '@models'
import { useGetOrderInformation, useUploadDocument } from '@apis/order-management.service'
import { useGetInventoryByVin } from '@apis/inventory-management.service'
import { CreditApplicationStatus, Status } from '@helpers/enums'
import { Icon } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { createOrderRequestPayloadFromState } from '@app/desking/utils'

export const OrderStipulation: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const reference_id = searchParams.get('reference_id')
  const vin = searchParams.get('vin')
  const { state } = location

  const { actions, states } = useStoreContext()
  const { state: deskingState, dispatch } = useDeskingContext()
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const [filteredStipulations, setFilteredStipulations] = useState<IStipulationResponse[]>([])

  const [order, setOrder] = useState<IOrderDetail>()
  const [nonStips, setNonStips] = useState<IStipulationResponse[]>([])
  const { mutateAsync: resubmitOrder, isLoading: _updateRequestLoading } = useReSubmitOrderById()
  const { mutate: getOrderSubmissionRecord, data: orderSubmission } =
    useGetOrderSubmissionResponse()
  const { mutate: getNonStips } = useGetNonFinancialStips()
  const { mutate: getStipsByLenderId } = useGetStipulationsByLenderId()
  const { mutate: createDocument } = useUploadDocument()
  const { mutate: getOrderInformation } = useGetOrderInformation()
  const { mutate: uploadDocument } = useUpdateDocument()
  const { mutate: deleteDocument } = useDeleteDocument()
  const [stipulationsByLender, setStipulationsByLender] = useState([])
  const { mutate: getVehicleByVin, data: vehicle } = useGetInventoryByVin()
  const [tempNonStip, setTempNonStip] = useState<IStipulationResponse[]>()
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const [documents, setDocuments] = useState([])
  const [generalizedStipText, setGeneralizedStipText] = useState<string | undefined>(undefined)

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    getVehicleByVin({ vin })
    getOrderInformation(
      { reference_number: reference_id },
      {
        onSuccess(response: any) {
          let submission_id = state?.submission_id
          if (!submission_id) {
            submission_id = findConditionedId(response?.order_submissions)
          }
          getOrderSubmissionRecord({ submission_id })
          setOrder(response)
          if (submission_id) {
            dispatch({
              type: DeskingActionTypes.SELECTED_LENDER,
              payload: response?.order_submissions?.find((item: any) => item.id === submission_id)
                ?.lender_id
            })
          }
          dispatch({
            type: DeskingActionTypes.MAP_ORDER_TO_STORE,
            payload: response
          })
        }
      }
    )
  }, [])

  useEffect(() => {
    if (nonStips?.length > 0) {
      const newArray: any = nonStips.map((item: any) => ({
        ...item,
        content_type: 'Application'
      }))
      setDocuments(newArray)
    }
  }, [nonStips])

  useEffect(() => {
    if (
      filteredStipulations?.length > 0 &&
      filteredStipulations?.every((stip) => stip.fulfilled === true)
    ) {
      setIsDisable(false)
    } else {
      setIsDisable(true)
    }
  }, [filteredStipulations])

  useEffect(() => {
    if (order?.id) {
      getNonStips(
        { order_id: order?.id },
        {
          onSuccess(res: any) {
            setNonStips(res)
            setTempNonStip(res)
          },
          onError() {
            setNonStips([] as any)
            setTempNonStip([] as any)
          }
        }
      )
    }
  }, [order])

  useEffect(() => {
    if (filteredStipulations?.length > 0) {
      const filteredNonStips = (nonStips as any)?.filter(
        (stips: any) => stips.document_status?.toLowerCase() !== 'draft'
      )

      const tempArr = [...filteredStipulations]
      for (const stips of tempArr) {
        if (
          stips.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL?.toLowerCase()
        ) {
          if (filteredNonStips?.length === 0) stips.fulfilled = false
          filteredNonStips?.find((st: any) => {
            if (st.stipulation_code === stips.stipulation_code && st.document_url !== null) {
              stips.fulfilled = true
            } else {
              stips.fulfilled = false
            }
          })
        }
      }
      setFilteredStipulations(tempArr)
    }
  }, [nonStips])

  useEffect(() => {
    if (stipulationsByLender?.length > 0) {
      const tempStips: IStipulationResponse[] = []
      stipulationsByLender?.filter((stip: any) =>
        orderSubmission?.decision?.stipulations?.forEach((el: any) => {
          if (stip?.stipulation_code === el?.stipulation_code) {
            stip.fulfilled = false
            ;(stip.value_to = el.value_to), (stip.value_from = el.value_from)
            tempStips.push(stip)
          }
        })
      )

      const stipulations = orderSubmission?.decision?.stipulations
      if (stipulations?.length > 0 && tempStips?.length < 1) {
        const genText = stipulations
          ?.map((item: any) => item?.comments)
          ?.filter((comment: string) => comment !== null)
          ?.join(' ')
        setGeneralizedStipText(genText)
      }

      setFilteredStipulations(tempStips)
    }
  }, [stipulationsByLender, orderSubmission])

  useEffect(() => {
    const tempStips = [...filteredStipulations]
    if (states?.stipulationsData) {
      tempStips
        ?.filter((stip) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL)
        ?.forEach((st) => {
          if (st.parameter === FinancialStipType.TERMS) {
            if (
              deskingState?.preview?.quote_params?.contract_term >= st?.value_from &&
              deskingState?.preview?.quote_params?.contract_term <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          } else if (st.parameter === FinancialStipType.FINANCED_AMOUNT) {
            if (
              deskingState?.preview?.calculationsBoxes?.adjusted_capitalized_cost >=
                st?.value_from &&
              deskingState?.preview?.calculationsBoxes?.adjusted_capitalized_cost <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          } else if (st.parameter === FinancialStipType.PERIODIC_PAYMENT) {
            if (
              deskingState?.preview?.calculationsBoxes?.monthly_payment >= st?.value_from &&
              deskingState?.preview?.calculationsBoxes?.monthly_payment <= st?.value_to
            ) {
              st.fulfilled = true
            } else {
              st.fulfilled = false
            }
          }
        })
      setFilteredStipulations(tempStips)
    }
  }, [deskingState?.preview?.quote_params, deskingState?.preview?.calculationsBoxes])

  useEffect(() => {
    if (states.lenderInfo.id) {
      getStipsByLenderId(
        { company_id: states.lenderInfo.id },
        {
          onSuccess(res: any) {
            setStipulationsByLender(res)
            dispatch({
              type: DeskingActionTypes.ADD_STIPULATIONS_DATA,
              payload: res
            })
          }
        }
      )
    }
  }, [states.lenderInfo.id])

  useEffect(() => {
    if (nonStips !== undefined && filteredStipulations?.length > 0 && order) {
      if ((nonStips as any)?.length === 0) {
        const tempArr = [] as any
        filteredStipulations
          ?.filter((stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL)
          ?.forEach((stip) => {
            const data = {
              customer_reference_id: order?.customer_info?.reference_id,
              external_reference_id: order?.id,
              document_upload_indicator: false,
              stipulation_code: stip.stipulation_code,
              document_type: stip.description,
              created_by: states.lenderInfo.id
            }
            tempArr.push(data)
          })
        tempArr?.forEach((obj: any) => {
          createDocument(obj, {
            onSuccess() {
              getNonStips(
                { order_id: order?.id },
                {
                  onSuccess(res: any) {
                    setNonStips(res)
                    setTempNonStip(res)
                  },
                  onError() {
                    setNonStips([] as any)
                    setTempNonStip([] as any)
                  }
                }
              )
            }
          })
        })
      }
    }
  }, [nonStips, filteredStipulations, order])

  const findConditionedId = (data: any) => {
    let conditionedId = null,
      deferredCount = 0
    data?.forEach((item: any) => {
      if (item.status === CreditApplicationStatus.CONDITIONED) conditionedId = item.id
      else if (item.status === CreditApplicationStatus.DEFERRED) deferredCount++
    })
    return conditionedId !== null && deferredCount === data?.length - 1 ? conditionedId : undefined
  }

  const reSubmitOrder = () => {
    const orderReqObj = createOrderRequestPayloadFromState(
      deskingState,
      states.dealerInfo,
      deskingState.lender_id
    )
    resubmitOrder(orderReqObj, {
      onSuccess(response: any) {
        actions.setToast({
          toastMessage: 'Deal Submitted for Credit Response',
          toastState: true
        })
        navigate(
          `/lane/${response?.status === Status.Approved ? 'deal-approved/' : 'deal-summary/'}${
            response?.reference_number
          }`
        )
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const financialStipulations = filteredStipulations?.filter(
    (stip) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL
  )

  const nonFinancialStipulations = filteredStipulations?.filter(
    (stip) => stip.stipulation_type?.toLowerCase() === StipulationType.NON_FINANCIAL
  )

  const saveOrderDocumentsHandler = (docs: any) => {
    uploadDocument(docs, {
      onSuccess() {
        getNonStipsHandler()
      }
    })
  }

  const deleteOrderDocumentsHandler = (document_identifier: string) => {
    deleteDocument(
      {
        document_identifier,
        deleted_by: states?.dealerInfo?.dealer_name
      },
      {
        onSuccess() {
          getNonStipsHandler()
        }
      }
    )
  }

  const getNonStipsHandler = () => {
    getNonStips(
      { order_id: order?.id },
      {
        onSuccess(res: any) {
          setNonStips(res)
          setTempNonStip(res)
        },
        onError() {
          setNonStips([] as any)
          setTempNonStip([] as any)
        }
      }
    )
  }

  return (
    <>
      <OrderApproved theme={theme}>
        <DrawerWrap>
          <LayoutWithSideNav theme={theme}>
            <PageHeader className="main-page-header border-bottom">
              <Box theme={theme} flexShrink={0} display={'flex'} sx={{ mr: 3 }}>
                <Button
                  theme={theme}
                  iconText={
                    <Icon
                      name="IconLeftArrow"
                      onClick={() => navigate(`/lane/deal-summary/${order?.reference_number}`)}
                    />
                  }
                  className="btn-back"
                />
              </Box>
              <Box flexGrow={'1'} sx={{ mr: 3 }}>
                <Typography variant="h2" component="h2">
                  Deal Conditioned
                </Typography>
                <Typography variant="body2" component="p" className="caption">
                  Please see the information below regarding the status of your credit application
                  review.
                </Typography>
              </Box>
              <Box className="action-area" flexShrink={0} display={'flex'} alignItems={'center'}>
                <Button
                  theme={theme}
                  primary
                  disabled={_updateRequestLoading || (isDisable && !generalizedStipText)}
                  text={HeaderButton.RE_SUBMIT_ORDER}
                  onClick={reSubmitOrder}
                  startIcon={_updateRequestLoading && <img src={DisableLoader} alt="Loader" />}
                />
              </Box>
            </PageHeader>
            <ProductBanner theme={theme} className="product-banner">
              <Box theme={theme} className="pb-wrap">
                <Box className="thumbnail">
                  <img
                    src={
                      vehicle?.photo_urls && vehicle?.photo_urls?.length > 0
                        ? vehicle?.photo_urls[0]?.location
                        : ''
                    }
                    alt="Product"
                  />
                </Box>
                <Box theme={theme} className="product-details">
                  <Grid theme={theme} container columnSpacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Box className="title-md">{vehicle?.vehicle_display_name}</Box>
                      <Box className="title-lg">
                        <Typography
                          className="text-muted"
                          display={'block'}
                          variant="body1"
                          component="p"
                        >
                          {vehicle?.year
                            ? vehicle?.year
                            : order
                            ? order?.Vehicle_details?.year
                            : ''}{' '}
                          {vehicle?.make
                            ? vehicle?.make
                            : order
                            ? order?.Vehicle_details?.make
                            : ''}{' '}
                          {vehicle?.model
                            ? vehicle?.model
                            : order
                            ? order?.Vehicle_details?.model
                            : ''}
                        </Typography>

                        {vehicle?.trim_description}
                      </Box>
                      <Box className="title-sm">
                        Deal ID:{' '}
                        <b>{order?.reference_number ? order?.reference_number : reference_id}</b>
                      </Box>

                      <Box className="title-sm">
                        VIN:{' '}
                        <b>
                          {vehicle?.vin ? vehicle?.vin : order ? order?.Vehicle_details?.vin : ''}
                        </b>
                      </Box>
                    </Grid>
                    <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                      <Box theme={theme} className="actions-wrap">
                        <Box theme={theme} className="action-details">
                          <Box theme={theme} className="title-md">
                            Net Financed Amount
                          </Box>
                          <Box theme={theme} className="title-lg">
                            {defaultCurrency}
                            {order?.net_finance_amount &&
                              order?.net_finance_amount.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                          </Box>
                          <Box theme={theme} className="title-sm">
                            Type:{' '}
                            <b>
                              {order?.contract_term} Month {order?.finance_type}
                            </b>
                            , Rate:{' '}
                            <b>
                              {order?.apr &&
                                order?.apr?.toLocaleString(undefined, {
                                  maximumFractionDigits: 4,
                                  minimumFractionDigits: 4
                                })}
                              %
                            </b>
                          </Box>
                          <Box theme={theme} className="title-sm">
                            {order?.status == Status.SchedulePickup ||
                            order?.status == Status.Appointment ||
                            order?.status == Status.Completed ? (
                              <>Periodic Payment: </>
                            ) : (
                              <>Est. Periodic Payment: </>
                            )}

                            <b>
                              {defaultCurrency}
                              {order?.estimated_monthly_payment &&
                                order?.estimated_monthly_payment?.toLocaleString(undefined, {
                                  maximumFractionDigits: 4,
                                  minimumFractionDigits: 2
                                })}
                            </b>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </ProductBanner>
            {(financialStipulations?.length > 0 || generalizedStipText) && (
              <Notification className="notification" theme={theme}>
                <FinancialStipulation
                  filteredStips={financialStipulations}
                  generalizedStipText={generalizedStipText}
                />
              </Notification>
            )}
            {(nonFinancialStipulations?.length > 0 ||
              (generalizedStipText &&
                orderSubmission?.status !== CreditApplicationStatus.APPROVED)) && (
              <Notification className="notification" sx={{ mt: 3 }} theme={theme}>
                <NonFinancialStipulation
                  stipulations={nonFinancialStipulations}
                  setDocuments={setDocuments}
                  documents={documents}
                  customer={order?.customer_info as any}
                  nonStips={tempNonStip as any}
                  setNonStips={setNonStips}
                  setTempNonStip={setTempNonStip}
                  orderReference={order?.reference_number}
                  generalizedStipText={generalizedStipText}
                  orderId={order?.id}
                  saveOrderDocumentsHandler={saveOrderDocumentsHandler}
                  deleteOrderDocumentsHandler={deleteOrderDocumentsHandler}
                />
              </Notification>
            )}
          </LayoutWithSideNav>
        </DrawerWrap>
      </OrderApproved>
    </>
  )
}

export default OrderStipulation
