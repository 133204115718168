import { useState } from "react";

import { Button, Checkbox, Input, Radio, Select, Tooltip } from "../atoms";
import "./login.scss";
import { Datepicker } from "../atoms/date-picker/date-picker.component";
import { Phone } from "../atoms/phone-input/phone-input.component";

export const LoginComponent = () => {
  const [state, setState] = useState();
  return (
    <div className="login-form" style={{ margin: 15 }}>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button type="primary" htmlType={"button"} text="Label" fixed />
          <br />
          <Button type="primary" htmlType={"button"} text="Label" />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            fullWidth
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fullWidth
          />
          <br />
        </div>
        <div>
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
          />
          <br />
          <Button type="primary" htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
          />
          <br />
        </div>
        <div>
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            disabled
            isLoading
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            type="primary"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button type="default" htmlType={"button"} text="Label" fixed />
          <br />
          <Button type="default" htmlType={"button"} text="Label" />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
          />
          <br />
        </div>
        <div>
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
          />
          <br />
          <Button type="default" htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
          />
          <br />
        </div>
        <div>
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            disabled
            isLoading
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            type="default"
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button htmlType={"button"} text="Label" fixed />
          <br />
          <Button htmlType={"button"} text="Label" />
          <br />
          <Button htmlType={"button"} text="Label" size="small" fixed />
          <br />
          <Button htmlType={"button"} text="Label" size="small" />
          <br />
        </div>
        <div>
          <Button htmlType={"button"} text="Label" fixed disabled />
          <br />
          <Button htmlType={"button"} text="Label" disabled />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
          />
          <br />
          <Button htmlType={"button"} text="Label" size="small" disabled />
          <br />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            disabled
            isLoading
            loadingIconPosition="end"
          />
          <br />
          <Button htmlType={"button"} text="Label" disabled isLoading />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            fixed
            disabled
            isLoading
          />
          <br />
          <Button
            htmlType={"button"}
            text="Label"
            size="small"
            disabled
            isLoading
          />
          <br />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            // fixed
            linkButton
            withIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            size="small"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            size="small"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            // fixed
            linkButton
            withIcon
            dataIcon="car_front"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            onDark
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            dataIcon="car_front"
            isIconEnd
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon={false}
            dataIcon="car_front"
            size="small"
            onDark
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            text="Label"
            fixed
            linkButton
            withIcon
            isIconEnd
            dataIcon="car_front"
            disabled
            size="small"
            onDark
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginBottom: 16,
          background: "#000",
          padding: 16,
        }}
      >
        <div>
          <Button htmlType={"button"} text="Label" fixed onDark />
        </div>
        <div>
          <Button htmlType={"button"} text="Label" fixed onDark disabled />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
            disabled
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="primary"
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
            disabled
          />
        </div>
        <div>
          <Button
            htmlType={"button"}
            type="default"
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Button htmlType={"button"} onlyIcon dataIcon="car_front" />
        </div>
        <div>
          <Button htmlType={"button"} onlyIcon dataIcon="car_front" disabled />
        </div>
        <div>
          <Button
            htmlType={"button"}
            onlyIcon
            dataIcon="car_front"
            disabled
            isLoading
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
          />
        </div>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            errorText="Error message"
            label="Label"
            showInfoIcon
            status="error"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            size="small"
          />
        </div>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            disabled
            size="small"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            errorText="Error message"
            label="Label"
            showInfoIcon
            status="error"
            size="small"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_1" htmlFor="id_1" />
        </div>
        <div>
          <Radio label="Label" id="id_2" htmlFor="id_2" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_3" error htmlFor="id_3" />
        </div>
        <div>
          <Radio label="Label" id="id_4" error htmlFor="id_4" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Radio label="Label" id="id_5" disabled htmlFor="id_5" />
        </div>
        <div>
          <Radio label="Label" id="id_6" disabled htmlFor="id_6" checked />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox
            id={"id-1"}
            label="Enabled"
            htmlFor="id-1"
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-2"}
            label="Enabled"
            htmlFor="id-2"
            checked
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-3"}
            label="Enabled"
            htmlFor="id-3"
            checked
            indeterminate
            onChangeHandler={() => {}}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox
            id={"id-4"}
            label="Enabled"
            htmlFor="id-4"
            error
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-5"}
            label="Enabled"
            htmlFor="id-5"
            error
            checked
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-6"}
            label="Enabled"
            htmlFor="id-6"
            error
            checked
            indeterminate
            onChangeHandler={() => {}}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Checkbox
            id={"id-1"}
            label="Enabled"
            htmlFor="id-1"
            disabled
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-2"}
            label="Enabled"
            htmlFor="id-2"
            disabled
            checked
            onChangeHandler={() => {}}
          />
        </div>
        <div>
          <Checkbox
            id={"id-3"}
            label="Enabled"
            htmlFor="id-3"
            disabled
            checked
            indeterminate
            onChangeHandler={() => {}}
          />
        </div>
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          helpText="Optional helper text"
        />
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          disabled
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          helpText="Optional helper text"
        />
      </div>
      <div>
        <Select
          id={""}
          placeholder="Content"
          label="Label"
          showInfoIcon
          status="error"
          options={[
            { value: "1", label: "Option 1", disabled: false },
            { value: "2", label: "Option 2", disabled: true },
          ]}
          errorText="Error message"
        />
      </div>
      <div style={{ marginBlock: 15 }} id="hello-world">
        <Datepicker
          id="datePicker"
          name="date-picker"
          placeholder="Choose"
          label="Label"
          showInfoIcon
          helpText="Optional helper text"
          // open
          prevIcon="arrow_left"
          nextIcon="arrow_right"
        />
      </div>
      <div style={{ marginBlock: 15 }}>
        <Datepicker
          id="datePickerNew"
          name="date picker"
          placeholder="Choose"
          status="error"
        />
      </div>
      <div style={{ marginBlock: 15 }}>
        <Datepicker
          id="datePickerNew"
          name="date picker"
          placeholder="Choose"
          disabled
        />
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            helpText="Optional helper text"
          />
        </div>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            helpText="Optional helper text"
            disabled
          />
        </div>
        <div>
          <Phone
            country="us"
            label="Label"
            showInfoIcon
            errorText="Optional helper text"
            status="error"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
            disabled
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <div style={{ flex: 1 }}>
          <Input
            id={""}
            name={""}
            placeholder="Input"
            helpText="Optional helper text"
            label="Label"
            showInfoIcon
            autoSize={{ minRows: 2, maxRows: 6 }}
            type="textarea"
            showCount
            onChange={(e: any) => {
              setState(e.target.value);
            }}
            value={state}
            maxLength={100}
            status="error"
            errorText="Error message"
          />
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
      <div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
        <Tooltip
          text="Tooltip will show on mouse enter."
          prompt="Tooltip"
          placement="bottomRight"
          arrow={false}
        />
      </div>
    </div>
  );
};
