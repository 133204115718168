import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ICreditRating, ICreditRatingFilter } from "Interface";

export const getAllRatingsFromServer = () => {
  return ConfigurationAPI.get("/credit-ratings").then((res) => {
    return res.data;
  });
};
export const getActiveRatingsFromServer = () => {
  return ConfigurationAPI.get("/credit-rating/active").then((res) => {
    return res.data;
  });
};

export const getAllCreditRatingsFilterFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/credit-ratings/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllCreditRatings = () => {
  return useQuery(QueryKeys.GET_ALL_CREDIT_RATINGS, async () => {
    return getAllRatingsFromServer().then((data) => {
      return data;
    });
  });
};
export const UseQuery_getActiveCreditRatings = () => {
  return useQuery(QueryKeys.GET_ALL_CREDIT_RATINGS_ACTIVE, async () => {
    return getActiveRatingsFromServer().then((data) => {
      return data;
    });
  });
};

export const UseQuery_getAllCreditRatings_filter = (columnFilters: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_CREDIT_RATING_FILTER, columnFilters],
    async () => {
      return getAllCreditRatingsFilterFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseMutation_AddCreditRating = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICreditRating>(
    (creditRating) => {
      return ConfigurationAPI.post("/credit-ratings", creditRating);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATING_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_AddCreditRatingMultiple = (): any => {
  const queryClient = useQueryClient();
  return useMutation<ICreditRating>(
    (creditRatings) => {
      return ConfigurationAPI.post(
        "/credit-ratings/add-multiple",
        creditRatings
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATING_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATINGS);
      },
    }
  );
};

export const UseMutation_UpdateCreditRating = (ratingId: number): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<ICreditRatingFilter>(
    (creditRating: any) => {
      return ConfigurationAPI.patch(
        `/credit-ratings/${creditRating.id}`,
        creditRating
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATING_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
    }
  );
};

export const UseMutation_DeleteCreditRating = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<ICreditRating>(
    (creditRatingId) => {
      return ConfigurationAPI.delete(`/credit-ratings/${creditRatingId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATING_FILTER);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_CREDIT_RATINGS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const getAllLendersFromServer = () => {
  return ConfigurationAPI.get("/lender").then((res) => {
    return res.data;
  });
};

export const UseQuery_getAllLenders = () => {
  return useQuery(QueryKeys.GET_ALL_LENDER, async () => {
    return getAllLendersFromServer().then((data) => {
      return data;
    });
  });
};