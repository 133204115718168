import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Box, Grid, Icon, Menu, Button } from '@ntpkunity/controls'
import { IMenuOptions, IWorkOrder } from '@models'
import { Status, WorkQueueMenuOption, WorkQueueState } from '@helpers/enums'
import { useNavigate } from 'react-router'
import { formatedDate, dateFormat, formatTime } from 'helpers/methods'
import { VehicleCardUi } from 'components/VehicleCardUi'

const VehicleOrderCard: FC<{
  data: IWorkOrder
  defaultCurrency: string
  menuOptions?: Array<IMenuOptions>
  handleOrderSelection?: (event: any, key: string, value: IWorkOrder) => void
  cardType?: WorkQueueState
}> = ({ data, menuOptions, handleOrderSelection, cardType, defaultCurrency }) => {
  const theme = useTheme()
  let navigate = useNavigate()
  return (
    <>
      <VehicleCardUi
        theme={theme}
        className="vehicle-card"
        onDoubleClick={() => {
          if (cardType == WorkQueueState.isOrder) {
            if (data?.status === Status.Conditioned) {
              navigate(
                `/lane/deal-stipulation/?vin=${data?.vin}&reference_id=${data?.reference_number}&customer_reference_id=${data?.customer_reference_id}`
              )
            } else if (data?.status == Status.Approved) {
              navigate(`/lane/deal-approved/${data?.reference_number}`)
            } else if (
              data?.status == Status.Submitted ||
              data?.status == Status.PendingTradeInConfirmation ||
              data?.status == Status.PendingTradeInInspection ||
              data?.status === Status.Submitted ||
              data?.status === Status.Reviewed ||
              data?.status === Status.InReview ||
              data?.status === Status.AwaitingMore ||
              data?.status === Status.FullyReceived
            ) {
              navigate(`/lane/deal-summary/${data?.reference_number}`)
            } else {
              navigate(
                `/lane/desking?reference_id=${data?.reference_number}&customer_reference_id=${data?.customer_reference_id}`
              )
            }
          } else if (
            cardType == WorkQueueState.isSchedulePickup ||
            cardType == WorkQueueState.isAppointment
          ) {
            navigate(
              `/lane/create-deal-ui/${data.vin}/${data.customer_reference_id}/${data.reference_number}`
            )
          } else if (
            cardType == WorkQueueState.isCancel ||
            cardType == WorkQueueState.isDeclined ||
            cardType == WorkQueueState.isWithdrawn
          ) {
            navigate(`/lane/deal-cancelled/${data?.reference_number}`)
          } else if (cardType == WorkQueueState.isComplete) {
            navigate(`/lane/deal-summary/${data?.reference_number}`)
          } else if (cardType == WorkQueueState.isPendingConfirmation) {
            navigate(`/lane/deal-summary/${data?.reference_number}`)
          }
        }}
      >
        <Grid container spacing={2} theme={theme} sx={{ mb: 2 }}>
          <Grid item xs={7} theme={theme}>
            <Box theme={theme}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                textAlign="left"
                className="w-100 text-muted"
              >
                {data?.year + ' ' + data?.make}
              </Typography>
            </Box>
            <Box theme={theme}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                textAlign="left"
                className="w-100 text-dark text-overflow"
              >
                {data?.model + ' ' + data?.trim_description}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5} theme={theme}>
            <Box theme={theme}>
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                textAlign="right"
                className="w-100 text-muted"
              >
                Selling Price
              </Typography>
            </Box>
            <Box theme={theme}>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                textAlign="right"
                className="w-100 text-dark"
              >
                {defaultCurrency}{' '}
                {data?.selling_price?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box theme={theme} className="vc-img-wrap">
          <img src={data?.photo_urls?.[0]?.location.toString()} alt="vehicle" />
        </Box>
        <Box theme={theme} className="vc-footer w-100">
          <Box theme={theme} className="specs">
            <Box theme={theme} className="w-100">
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                className="text-muted"
                sx={{ mr: 0.5 }}
              >
                Deal ID:
              </Typography>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                className="text-dark subtitle2"
              >
                {data?.reference_number}
              </Typography>
            </Box>
            {(cardType == WorkQueueState.isOrder ||
              cardType == WorkQueueState.isPendingConfirmation ||
              cardType == WorkQueueState.isSchedulePickup) && (
              <Box theme={theme} className="w-100">
                <Typography
                  theme={theme}
                  variant="caption"
                  component="span"
                  className="text-muted"
                  sx={{ mr: 0.5 }}
                >
                  Status:
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  className="text-dark subtitle2"
                >
                  {data?.status}
                </Typography>
              </Box>
            )}
            {cardType &&
              [
                WorkQueueState.isCancel,
                WorkQueueState.isDeclined,
                WorkQueueState.isWithdrawn
              ].includes(cardType as WorkQueueState) && (
                <Box theme={theme} className="w-100 text-overflow">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="text-muted"
                    sx={{ mr: 0.5 }}
                  >
                    Reason:
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="subtitle2"
                    component="span"
                    className="text-dark subtitle2"
                  >
                    {data?.reason}
                  </Typography>
                </Box>
              )}
            {(cardType == WorkQueueState.isOrder ||
              cardType == WorkQueueState.isPendingConfirmation ||
              cardType == WorkQueueState.isCancel ||
              cardType == WorkQueueState.isComplete ||
              cardType == WorkQueueState.isAppointment ||
              cardType == WorkQueueState.isDeclined ||
              cardType == WorkQueueState.isWithdrawn) && (
              <Box theme={theme} className="w-100">
                <Typography
                  theme={theme}
                  variant="caption"
                  component="span"
                  className="text-muted"
                  sx={{ mr: 0.5 }}
                >
                  {cardType === WorkQueueState.isOrder &&
                    data?.status === Status.Draft &&
                    data?.expired_at !== null &&
                    'Expiry:'}
                  {cardType == WorkQueueState.isComplete && 'Completed Date:'}
                  {cardType == WorkQueueState.isAppointment && 'Scheduling Date:'}
                  {cardType == WorkQueueState.isCancel && 'Cancellation Date:'}
                  {cardType == WorkQueueState.isDeclined && 'Declined Date:'}
                  {cardType == WorkQueueState.isWithdrawn && 'Withdrawn Date:'}
                </Typography>
                <Typography
                  theme={theme}
                  variant="subtitle2"
                  component="span"
                  className="text-dark subtitle2"
                >
                  {cardType == WorkQueueState.isOrder &&
                    data?.status == Status.Draft &&
                    data?.expired_at &&
                    formatedDate(new Date(data?.expired_at), dateFormat)}
                  {(cardType == WorkQueueState.isComplete ||
                    cardType == WorkQueueState.isCancel ||
                    cardType == WorkQueueState.isDeclined ||
                    cardType == WorkQueueState.isWithdrawn) &&
                    data?.order_updated_at &&
                    formatedDate(
                      new Date(data?.order_updated_at).toLocaleDateString('en-US'),
                      dateFormat
                    )}
                  {cardType == WorkQueueState.isAppointment &&
                    data?.appointment_date &&
                    `${formatTime(data?.appointment_date)}`}
                </Typography>
              </Box>
            )}
            {(cardType == WorkQueueState.isSchedulePickup ||
              cardType == WorkQueueState.isAppointment) && (
              <>
                <Box theme={theme} className="w-100">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="text-muted"
                    sx={{ mr: 0.5 }}
                  >
                    VIN:
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="subtitle2"
                    component="span"
                    className="text-dark subtitle2"
                  >
                    {data?.vin}
                  </Typography>
                </Box>
                <Box theme={theme} className="w-100">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="text-muted"
                    sx={{ mr: 0.5 }}
                  >
                    Customer Name:
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="subtitle2"
                    component="span"
                    className="text-dark subtitle2"
                  >
                    {[data?.first_name, data?.middle_name, data?.last_name]
                      ?.filter(Boolean)
                      ?.join(' ')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box theme={theme} className="action">
            {cardType != WorkQueueState.isCancel &&
              cardType != WorkQueueState.isDeclined &&
              cardType != WorkQueueState.isWithdrawn &&
              cardType != WorkQueueState.isComplete && (
                <Button
                  fullWidth
                  theme={theme}
                  primary
                  text={
                    cardType == WorkQueueState.isOrder ||
                    cardType == WorkQueueState.isPendingConfirmation
                      ? 'View/Edit Deal'
                      : cardType == WorkQueueState.isSchedulePickup
                      ? 'Schedule'
                      : cardType == WorkQueueState.isAppointment
                      ? 'Complete Deal'
                      : ''
                  }
                  onClick={() => {
                    if (cardType == WorkQueueState.isOrder) {
                      if (data?.status === Status.Conditioned) {
                        navigate(
                          `/lane/deal-stipulation/?vin=${data?.vin}&reference_id=${data?.reference_number}&customer_reference_id=${data?.customer_reference_id}`
                        )
                      } else if (data?.status == Status.Approved) {
                        navigate(`/lane/deal-approved/${data?.reference_number}`)
                      } else if (
                        data?.status == Status.Submitted ||
                        data?.status == Status.PendingTradeInConfirmation ||
                        data?.status == Status.PendingTradeInInspection ||
                        data?.status === Status.Submitted ||
                        data?.status === Status.Reviewed ||
                        data?.status === Status.InReview ||
                        data?.status === Status.AwaitingMore ||
                        data?.status === Status.FullyReceived
                      ) {
                        navigate(`/lane/deal-summary/${data?.reference_number}`)
                      } else {
                        navigate(
                          `/lane/desking?reference_id=${data?.reference_number}&customer_reference_id=${data?.customer_reference_id}`
                        )
                      }
                    } else if (cardType == WorkQueueState.isSchedulePickup) {
                      navigate(
                        `/lane/create-deal-ui/${data.vin}/${data.customer_reference_id}/${data.reference_number}`
                      )
                    } else if (cardType == WorkQueueState.isAppointment) {
                      handleOrderSelection &&
                        handleOrderSelection(
                          'Complete Deal',
                          WorkQueueMenuOption.COMPLETE_ORDER,
                          data
                        )
                    } else if (cardType == WorkQueueState.isPendingConfirmation) {
                      navigate(`/lane/deal-summary/${data?.reference_number}`)
                    }
                  }}
                />
              )}
            {(cardType == WorkQueueState.isOrder ||
              cardType == WorkQueueState.isPendingConfirmation ||
              cardType == WorkQueueState.isSchedulePickup ||
              cardType == WorkQueueState.isAppointment) && (
              <Menu
                theme={theme}
                options={
                  menuOptions?.map((option: any) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: data
                    }
                  }) ?? []
                }
                render={(cb: any) => (
                  <Button
                    fullWidth
                    theme={theme}
                    secondary
                    iconText={<Icon name="MoreIcon" />}
                    onClick={cb}
                  />
                )}
                handleOptionClick={handleOrderSelection}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            )}
          </Box>
        </Box>
      </VehicleCardUi>
    </>
  )
}

export default VehicleOrderCard
