import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Icon, Input, Select, Tooltip, Typography } from '@ntpkunity/controls'

const FIPriceDialog: FC<{
  isOpen: any
  setIsOpen: any
  details: any
  defaultCurrency: any
  rates?: any
  selectDataObj?: any
  minimunDedatablePrice?: any
  editPopupCallback?: any
}> = ({
  isOpen,
  setIsOpen,
  details,
  defaultCurrency,
  rates,
  selectDataObj,
  minimunDedatablePrice,
  editPopupCallback
}) => {
  const theme = useTheme()
  const [selectedFinalCoverage, setSelectedFinalCoverage] = useState<any>([])
  const [coverageName, setCoverageName] = useState<any>(details?.coverage_name ?? '')
  const [deductiblePrice, setDeductiblePrice] = useState<any>(
    minimunDedatablePrice ? [minimunDedatablePrice] : []
  )
  const [retailPrice, setRetailPrice] = useState<any>(details?.price || '')

  const [coverageData, setCoverageData] = useState<{
    term_miles: Array<string>
    term_months: Array<string>
  }>({ term_miles: [], term_months: [] })

  useEffect(() => {
    if (details?.price) {
      setRetailPrice(details.price)
    }
    if (details?.term_months) {
      setCoverageData((prevState) => ({
        ...prevState,
        ...(details?.term_months ? { term_months: [details.term_months] } : {}),
        ...(details?.term_miles ? { term_miles: [details.term_miles] } : {})
      }))
    }
  }, [details])

  useEffect(() => {
    if (rates && Array.isArray(rates) && rates.length > 0) {
      const finalCoverage = rates.find((product) => product.product_id === details?.product_id)
      if (finalCoverage) {
        setSelectedFinalCoverage(finalCoverage)
      }
    }
  }, [rates, details?.product_id])
  const handleClose = () => {
    setIsOpen(false)
  }

  const selectFAndIData = (data: any) => {
    const coverageObj = selectedFinalCoverage?.coverages?.coverage?.find(
      (x: any) => x?.coverage_name === data
    )
    if (coverageObj) {
      const newDeductiblePrice =
        coverageObj?.deductibles?.deductible?.retail_price || details?.price
      setDeductiblePrice([newDeductiblePrice])
      const applyMarkup = newDeductiblePrice + (details?.markup / 100) * newDeductiblePrice
      setRetailPrice(applyMarkup)
      setCoverageData({
        term_miles: coverageObj?.term_miles ? [coverageObj.term_miles] : [],
        term_months: coverageObj?.term_months ? [coverageObj.term_months] : []
      })
    }
  }
  const updatePrice = () => {
    const coverageObj = selectDataObj.find((x: any) => x?.product_id === details?.product_id)
    if (coverageObj) {
      coverageObj.price = retailPrice
      coverageObj.coverage_name = coverageName
      selectDataObj = selectDataObj.map((obj: any) =>
        obj?.product_id === details?.product_id
          ? {
              ...obj,
              price: coverageObj.price,
              coverage_name: coverageObj?.coverage_name
            }
          : obj
      )
      editPopupCallback(coverageObj)
    }
    setIsOpen(false)
  }

  const getPriceToolTip = (price: any): string | null => {
    const finalPrice = price / (1 + details?.markup / 100)
    return `${(price - finalPrice).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })} ( ${details?.markup} %)`
  }

  const getBaseAmount = (price: any): any => {
    const finalPrice = price / (1 + details?.markup / 100)
    const baseAmount = price - finalPrice
    return (price - baseAmount).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  return (
    <Dialog
      theme={theme}
      title={details?.product_name}
      size="xs"
      open={isOpen}
      onCloseDialog={handleClose}
      customFooter={
        <>
          <Button theme={theme} secondary text={'Cancel'} onClick={handleClose} />
          <Button
            theme={theme}
            disabled={false}
            type="submit"
            primary
            text="Save"
            onClick={updatePrice}
          />
        </>
      }
    >
      <Box theme={theme}>
        <Select
          theme={theme}
          disablePortal={true}
          disabled={false}
          label={'Coverage Name'}
          value={coverageName}
          items={
            Array.isArray(selectedFinalCoverage?.coverages?.coverage)
              ? selectedFinalCoverage.coverages.coverage.map((category: any) => ({
                  value: category?.coverage_name,
                  text: category?.coverage_name
                }))
              : selectedFinalCoverage?.coverages?.coverage
              ? [
                  {
                    value: selectedFinalCoverage.coverages.coverage.coverage_name,
                    text: selectedFinalCoverage.coverages.coverage.coverage_name
                  }
                ]
              : []
          }
          onChange={(e: any) => {
            const coverage = e?.target?.value
            if (coverage) {
              setCoverageName(coverage)
              selectFAndIData(coverage)
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          disabled={false}
          label={'Term'}
          value={coverageData?.term_months?.[0] || ''}
          items={(coverageData?.term_months || [])?.map((term: any) => ({
            value: term,
            text: `${term} Months`
          }))}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          disabled={false}
          label={'Mileage'}
          value={coverageData?.term_miles?.[0] || ''}
          items={coverageData?.term_miles?.map((milage: any) => ({
            value: milage,
            text: `${milage ?? 0} Miles`
          }))}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          disabled={false}
          label={'Deductible'}
          value={deductiblePrice?.[0] ?? '00000'}
          items={
            deductiblePrice?.map((price: any) => ({
              value: price,
              text: `${(price ?? 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            })) || []
          }
          startAdornment={defaultCurrency}
          onChange={(e: any) => {
            const selectedPrice = e?.target?.value
            if (selectedPrice) {
              setDeductiblePrice([selectedPrice])
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Box theme={theme}>
          <Input
            theme={theme}
            fullWidth
            label={'Price'}
            type={'text'}
            masking
            maskNumeric
            maskDecimalScale={2}
            value={retailPrice}
            onChange={(value) => setRetailPrice(Number(value))}
            startAdornment={defaultCurrency}
            endAdornment={
              <Tooltip
                theme={theme}
                title={
                  <>
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Markup: ${defaultCurrency}${getPriceToolTip(retailPrice ?? 0)}`}
                    />
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Base Amount: ${defaultCurrency}${getBaseAmount(retailPrice ?? 0)}`}
                    />
                  </>
                }
              >
                <Icon name="InfoIcon" />
              </Tooltip>
            }
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default FIPriceDialog
