import { Button } from "@src/components/atoms";
import "./financial-information-completed.style.scss";

export const FinancialInformationCompleted = ({
  changeStateOfEditModeHandler,
}: {
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  return (
    <div className="checkout-step-financial-info">
      <div className="checkout-success-financial-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            03
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Financial Information.
          </h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-three")}
          size="small"
        />
      </div>
      <div className="financial-success-info">
        <div className="financial-success-info-container">
          <div className="financial-row-grid">
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Occupation
              </p>
              <p className="financial-success-subtitle text--body-2">
                Senior Marketing Director
              </p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Employment type
              </p>
              <p className="financial-success-subtitle text--body-2">
                Employed
              </p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Annual income
              </p>
              <p className="financial-success-subtitle text--body-2">
                $999,999
              </p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Start date
              </p>
              <p className="financial-success-subtitle text--body-2">
                Jan 24,2025
              </p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Duration of employment
              </p>
              <p className="financial-success-subtitle text--body-2">1 year</p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Employer name
              </p>
              <p className="financial-success-subtitle text--body-2">
                Sarah Schumacher
              </p>
            </div>
            <div className="financial-row">
              <p className="financial-success-title text--disclaimer text-secondary">
                Employer phone number
              </p>
              <p className="financial-success-subtitle text--body-2">
                (555) 555-1234
              </p>
            </div>
          </div>
          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Address
            </p>
            <div className="financial-address">
              <p className="financial-success-subtitle text--body-2">
                610-200 Varick Street,New York, NY
              </p>
              <p className="financial-success-subtitle text--body-2">
                10454, United States
              </p>
            </div>
          </div>
        </div>
        <div className="financial-residence-block" />
        <div className="financial-success-info-container">
          <div className="financial-row-grid">
            <div className="financial-row mb-0">
              <p className="financial-success-title text--disclaimer text-secondary">
                Additional annual income
              </p>
              <p className="financial-success-subtitle text--body-2">$5,000</p>
            </div>
            <div className="financial-row mb-0">
              <p className="financial-success-title text--disclaimer text-secondary">
                Source
              </p>
              <p className="financial-success-subtitle text--body-2">
                Freelance
              </p>
            </div>
          </div>
        </div>
        <div className="financial-residence-block" />
        <div className="financial-rent-container">
          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Residence status
            </p>
            <p className="financial-success-subtitle text--body-2">Mortgage</p>
          </div>
          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Monthly Payments
            </p>
            <p className="financial-success-subtitle text--body-2">$5,000</p>
          </div>
          <div>
            <p className="financial-success-title text--disclaimer text-secondary">
              Have You Ever Applied for Bankruptcy?
            </p>
            <p className="financial-success-subtitle text--body-2">No</p>
          </div>
        </div>
      </div>
    </div>
  );
};
