import { FullScreenContent } from "@src/components/molecules";
import { Button, IconList } from "@src/components/atoms";
import { BaseLayout } from "@src/layouts";

const DealStatePage = () => {
  return (
    <BaseLayout hasMenu={false} hasFooter={false}>
      <FullScreenContent
        title={<h1 className="text-white">Application received!</h1>}
        subtitle={
          <p className="text--body-1 text-white">
            Your reservation timer is paused for you while we process your
            application. Once we provide finance offers, the reservation timer
            will continue at <span className="fw-bold">XX:XX.</span>
          </p>
        }
        content={
          <>
            <IconList
              size="big"
              onDark
              listItems={[
                {
                  type: "checklist",
                  text: "Application received",
                },
                {
                  type: "loading",
                  text: "Confirming lease offer",
                },
              ]}
            />
          </>
        }
        // actionArea={
        //   <>
        //     <Button text="Contact dealer" type="primary" htmlType="button" fullWidth />
        //     <Button text="Cancel deal" linkButton withIcon dataIcon="trash_can" onDark htmlType="button" />
        //   </>
        // }
        footer={
          <p className="text--label text-white">
            This process can take between 30 seconds and {"XX"} minutes. You
            will also receive an email notification from{" "}
            <span className="fw-bold">email@email.com</span> when this is
            complete.
          </p>
        }
      />
    </BaseLayout>
  );
};

export default DealStatePage;
