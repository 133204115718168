// @ts-nocheck
import {
  ICustomer,
  IOrderTradeIn,
  IProgramDetails,
  IDownpaymentChart,
  IVehicleDetail,
  IStipulationResponse
} from '@models'
import { createContext, FC, ReactNode, useContext, useReducer } from 'react'
import { useStoreContext } from '@store/storeContext'
import { Outlet, useSearchParams } from 'react-router-dom'
import {
  createCurrencyFormatter,
  organizeByFinanceType,
  selectDefaultMileages,
  selectDefaultTerms
} from './utils'
import { DealDeskingState } from '@store/ducks/desking'
import { FeeEvent } from '@helpers/enums/finance-type.enum'
import { PricingTypes } from '@helpers/enums/pricing-type.enum'

export const FinanceTypes = {
  FINANCE: 'Finance' as const,
  LEASE: 'Lease' as const
}
export const OrderStage = {
  QUOTATION: 'Quotation' as const,
  APPLICATION: 'Application' as const
}

export const VehiclesForComparisonTypes = {
  SINGLE: 'single' as const,
  MULTIPLE: 'multiple' as const,
  PREVIEW: 'preview' as const
}

export type Vehicle = {
  id: string
  make: string
  model: string
  price: number
  vin: string
}

export type PricingApiResult = {
  monthlyPayment: number
  totalCost: number
  leaseOrFinance: string
  otherDetails: string
}

export type DownPayments = {
  value: number[] | number
  minimunDownPayment: number
  maximumDownPayment: number
}

export type QuoteParams = {
  contract_term: any
  apr: number
  rv_value: number
  annual_usage?: any
}

type DeskingContextType =
  | {
    state: DeskingState
    dispatch: React.Dispatch<DeskingActions>
    formatCurrency: (
      amount: number | string,
      minimumFractionDigits?: number,
      maximumFractionDigits?: number
    ) => string
  }
  | undefined

export enum DeskingActionTypes {
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  ADD_CUSTOMER_ADDRESS = 'ADD_CUSTOMER_ADDRESS',
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  ADD_TRADE_IN = 'ADD_TRADE_IN',
  DELETE_TRADE_IN = 'DELETE_TRADE_IN',
  ADD_VEHICLE_VIN = 'ADD_VEHICLE_VIN',
  ADD_VEHICLE = 'ADD_VEHICLE',
  DELETE_VEHICLE_VIN = 'DELETE_VEHICLE_VIN',
  UPDATE_VEHICLE_VIN = 'UPDATE_VEHICLE_VIN',
  ADD_PROGRAM = 'ADD_PROGRAM',
  UPDATE_FINANCE_TYPE = 'UPDATE_FINANCE_TYPE',
  UPDATE_OPTIONS_FEES_FNI = 'UPDATE_OPTIONS_FEES_FNI',
  UPDATE_VEHICLES_FOR_COMPARISON = 'UPDATE_VEHICLES_FOR_COMPARISON',
  ADD_INSURANCE = 'ADD_INSURANCE',
  DELETE_INSURANCE = 'DELETE_INSURANCE',
  UPDATE_QUOTE_PARAM_DOWN_PAYMENT = 'UPDATE_QUOTE_PARAM_DOWN_PAYMENT',
  UPDATE_CALCULATION_GRID_CELL = 'UPDATE_CALCULATION_GRID_CELL',
  UPDATE_SELECTION_DATA = 'UPDATE_SELECTION_DATA',
  UPDATE_STAGE = 'UPDATE_STAGE',
  UPDATE_STATUS = 'UPDATE_STATUS',
  VEHICLE_SELECTED_AND_QUOTATION_SAVED = 'VEHICLE_SELECTED_AND_QUOTATION_SAVED',
  SELECTED_LENDER = 'SELECTED_LENDER',
  MAP_ORDER_TO_STORE = 'MAP_ORDER_TO_STORE',
  RESET_CALCULATIONS = 'RESET_CALCULATIONS',
  UPDATE_SELLING_PRICE = 'UPDATE_SELLING_PRICE',
  UPDATE_CALCULATION_DATA = 'UPDATE_CALCULATION_DATA',
  ADD_SUBMISSION_TIER = 'ADD_SUBMISSION_TIER',
  SAVE_ORDER = 'SAVE_ORDER',
  ADD_STIPULATIONS_DATA = 'ADD_STIPULATIONS_DATA',
  SET_PROGRAM_LOADING = 'SET_PROGRAM_LOADING',
  UPDATE_HAS_UNSAVED_ORDER_CHANGES = 'UPDATE_HAS_UNSAVED_ORDER_CHANGES',
  UPDATE_PROCEEDED_WITHOUT_PAYMENT = 'UPDATE_PROCEEDED_WITHOUT_PAYMENT',
  ADD_VEHICLE_LOADING = 'ADD_VEHICLE_LOADING',
  SKIP_RESERVATION = 'SKIP_RESERVATION'
}

type EmptyObject = {}
export type FinanceType = (typeof FinanceTypes)[keyof typeof FinanceTypes]
type VehicleForComparison =
  (typeof VehiclesForComparisonTypes)[keyof typeof VehiclesForComparisonTypes]
type FinanceLeaseStructure = {
  vin: string
  down_payments: DownPayments
  quote_params: QuoteParams[] | QuoteParams
  calculationsBoxes: PricingApiResult[][] | PricingApiResult
  program: any
  misc: any[][]
}
type popUpStructure = {
  fni: any[]
  dealer_options?: any[]
  fees?: any[]
  vinUOFF: string
}
export type DeskingActions =
  | { type: DeskingActionTypes.ADD_CUSTOMER; payload: ICustomer }
  | { type: DeskingActionTypes.ADD_CUSTOMER_ADDRESS; payload: any }
  | { type: DeskingActionTypes.DELETE_CUSTOMER }
  | { type: DeskingActionTypes.ADD_TRADE_IN; payload: IOrderTradeIn }
  | { type: DeskingActionTypes.DELETE_TRADE_IN }
  | { type: DeskingActionTypes.UPDATE_HAS_UNSAVED_ORDER_CHANGES; payload: boolean }
  | { type: DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT; payload: boolean }
  | { type: DeskingActionTypes.SKIP_RESERVATION; payload: boolean }
  | { type: DeskingActionTypes.ADD_VEHICLE_VIN; payload: any }
  | {
    type: DeskingActionTypes.RESET_CALCULATIONS
    payload: { vinForCalculationsToReset: string; indexToReset: number }
  }
  | { type: DeskingActionTypes.ADD_VEHICLE; payload: IVehicleDetail }
  | {
    type: DeskingActionTypes.DELETE_VEHICLE_VIN
    payload: { vinDelete: string; indexDelete: number }
  }
  | {
    type: DeskingActionTypes.UPDATE_VEHICLE_VIN
    payload: { vinUpdate: string; originalVin: string; indexUpdate: number; vehicleData: any }
  }
  | {
    type: DeskingActionTypes.ADD_PROGRAM
    payload: {
      vin: string
      index: number
      program: IProgramDetails[]
      term: number
      mileage: number
      rowIndex?: number
    }
  }
  | { type: DeskingActionTypes.UPDATE_FINANCE_TYPE; payload: FinanceType }
  | { type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI; payload: popUpStructure }
  | { type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON; payload: VehicleForComparison }
  | { type: DeskingActionTypes.ADD_INSURANCE; payload: any[] }
  | { type: DeskingActionTypes.ADD_STIPULATIONS_DATA; payload: IStipulationResponse[] }
  | { type: DeskingActionTypes.DELETE_INSURANCE }
  | {
    type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT
    payload: {
      vinQPDP: string
      rowIndex: number
      colIndex: number
      value: any
      field?: string
      restrictCaculations?: boolean
    }
  }
  | {
    type: DeskingActionTypes.UPDATE_CALCULATION_GRID_CELL
    payload: {
      vinCGC: string
      rowIndexCGC: number
      colIndexCGC: number
      vehicleForComparisonCGC: string
      finance_typeCGC: string
      valueCGC: any
    }
  }
  | {
    type: DeskingActionTypes.UPDATE_SELECTION_DATA
    payload: { vinUSD: string; rowIndexUSD: number; colIndexUSD: number }
  }
  | { type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED; payload: boolean }
  | { type: DeskingActionTypes.SELECTED_LENDER; payload: number }
  | { type: DeskingActionTypes.MAP_ORDER_TO_STORE; payload: any }
  | { type: DeskingActionTypes.UPDATE_STAGE; payload: string }
  | { type: DeskingActionTypes.UPDATE_STATUS; payload: string }
  | { type: DeskingActionTypes.UPDATE_SELLING_PRICE; payload: { vinUSP: string; valueUSP: number } }
  | {
    type: DeskingActionTypes.UPDATE_CALCULATION_DATA
    payload: { property: string; valueUCD: any }
  }
  | { type: DeskingActionTypes.ADD_SUBMISSION_TIER; payload: string }
  | { type: DeskingActionTypes.SAVE_ORDER; payload: boolean }
  | { type: DeskingActionTypes.SET_PROGRAM_LOADING; payload: boolean }
  | { type: DeskingActionTypes.ADD_VEHICLE_LOADING; payload: boolean }

const createInitialMiscBoxes = (rows: number, columns: number): any[][] => {
  const boxes = Array.from({ length: rows }, (_, rowIndex) =>
    Array.from({ length: columns }, (_, colIndex) => ({}))
  )
  return boxes
}

const createInitialCalculationsBoxes = (rows: number, columns: number): any[][] => {
  const boxes = Array.from({ length: rows }, (_, rowIndex) =>
    Array.from({ length: columns }, (_, colIndex) => {
      if (rowIndex === 0) {
        return colIndex === 0 ? 'Down Payment' : '0'
      } else if (rowIndex === rows - 2) {
      } else if (rowIndex === rows - 1) {
      } else {
        if (colIndex === 0) {
          return {
            contract_term: '0',
            apr: '0',
            rv_value: '0',
            annual_usage: '0'
          }
        }
        return '0'
      }
    })
  )

  return boxes
}

const getGridCellValue = (
  rowIndex: number,
  colIndex: number,
  down_payments: number[],
  quote_params: QuoteParams[],
  calculationsBoxes: any[][],
  program: any
) => {
  if (rowIndex === 0 && colIndex !== 0 && program) {
    return down_payments[colIndex - 1]
  }
  if (rowIndex > 0 && rowIndex <= quote_params.length && colIndex === 0) {
    return quote_params[rowIndex - 1]
  }
  return calculationsBoxes[rowIndex][colIndex]
}

const makeCalculationGrid = (financeLeaseStructure: FinanceLeaseStructure) => {
  const { down_payments, quote_params, calculationsBoxes, program } = financeLeaseStructure

  return Array.isArray(calculationsBoxes)
    ? calculationsBoxes.map((row, rowIndex) =>
      row.map((_, colIndex) =>
        getGridCellValue(
          rowIndex,
          colIndex,
          down_payments as number[],
          quote_params as QuoteParams[],
          calculationsBoxes,
          program
        )
      )
    )
    : calculationsBoxes
}

const createFinanceLeaseStructureForCalculationGrid = () => ({
  [FinanceTypes.FINANCE]: {
    maximumRv: 0,
    minimumRv: 0,
    vin: '',
    down_payments: {
      value: Array(3).fill(0),
      minimunDownPayment: 0,
      maximumDownPayment: 0
    },
    quote_params: Array(3).fill({
      contract_term: 0,
      apr: 0,
      rv_value: 0
    }),
    calculationsBoxes: createInitialCalculationsBoxes(6, 4),
    misc: createInitialMiscBoxes(3, 3)
  },
  [FinanceTypes.LEASE]: {
    maximumRv: 0,
    minimumRv: 0,
    vin: '',
    down_payments: {
      value: Array(3).fill(0),
      minimunDownPayment: 0,
      maximumDownPayment: 0
    },
    quote_params: Array(3).fill({
      contract_term: 0,
      apr: 0,
      rv_value: 0,
      annual_usage: 0
    }),
    calculationsBoxes: createInitialCalculationsBoxes(6, 4),
    misc: createInitialMiscBoxes(3, 3)
  }
})

const createFinanceLeaseStructureForMultiVehicleComparison = () => ({
  [FinanceTypes.FINANCE]: Array(3).fill({
    vin: '',
    down_payments: {
      value: 0,
      minimunDownPayment: 0,
      maximumDownPayment: 0
    },
    quote_params: {
      contract_term: 0,
      apr: 0,
      rv_value: 0
    },
    calculationsBoxes: '0',
    misc: {}
  }),
  [FinanceTypes.LEASE]: Array(3).fill({
    vin: '',
    down_payments: {
      value: 0,
      minimunDownPayment: 0,
      maximumDownPayment: 0
    },
    quote_params: {
      contract_term: 0,
      apr: 0,
      rv_value: 0,
      annual_usage: 0
    },
    calculationsBoxes: '0',
    misc: {}
  })
})

const createPreviewDefaults = () => ({
  vin: '',
  vinIndex: 0,
  down_payments: {
    value: 0,
    minimunDownPayment: 0,
    maximumDownPayment: 0
  },
  quote_params: {
    contract_term: 0,
    apr: 0,
    rv_value: 0,
    annual_usage: 0
  },
  calculationsBoxes: '0',
  misc: {}
})

const resetCalculationsForVin = (state: DeskingState, index: number) => ({
  [VehiclesForComparisonTypes.SINGLE]:
    index === 0
      ? createFinanceLeaseStructureForCalculationGrid()
      : state[VehiclesForComparisonTypes.SINGLE],
  [VehiclesForComparisonTypes.MULTIPLE]: {
    [FinanceTypes.FINANCE]: state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE].map(
      (dqp, idx) =>
        idx === index
          ? {
            vin: '',
            down_payments: {
              value: 0,
              minimunDownPayment: 0,
              maximumDownPayment: 0
            },
            quote_params: {
              contract_term: 0,
              apr: 0,
              rv_value: 0
            },
            calculationsBoxes: '0',
            misc: {}
          }
          : dqp
    ),
    [FinanceTypes.LEASE]: state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE].map(
      (dqp, idx) =>
        idx === index
          ? {
            vin: '',
            down_payments: {
              value: 0,
              minimunDownPayment: 0,
              maximumDownPayment: 0
            },
            quote_params: {
              contract_term: 0,
              apr: 0,
              rv_value: 0,
              annual_usage: 0
            },
            calculationsBoxes: '0',
            misc: {}
          }
          : dqp
    )
  }
})

const resetSelectionAndCalculationsForVin = (state: DeskingState, index: number) => ({
  selection: {},
  ...resetCalculationsForVin(state, index)
})

const calculateDefaultDownPaymentInAmount = (
  downPaymentPercent: number | undefined,
  internetPrice: number
) => Number((((downPaymentPercent ?? 0) * internetPrice) / 100).toFixed(2))

const calculateDownPaymentsWithRange =
  (comparison: string) => (downPaymentChart: IDownpaymentChart, internetPrice: number) => {
    const defaultDownPayment = calculateDefaultDownPaymentInAmount(
      downPaymentChart?.default_down_payment as number,
      internetPrice
    )
    const minimumDownPayment = calculateDefaultDownPaymentInAmount(
      downPaymentChart?.minimum_down_payment as number,
      internetPrice
    )
    const maximumDownPayment = calculateDefaultDownPaymentInAmount(
      downPaymentChart?.maximum_down_payment as number,
      internetPrice
    )
    const downPayments = [
      Math.max(minimumDownPayment ?? 0, defaultDownPayment - 1000),
      defaultDownPayment,
      Math.min(maximumDownPayment ?? defaultDownPayment + 1000 ?? 0, defaultDownPayment + 1000)
    ]
    const downPaymentsWithRanges = {
      value: comparison === VehiclesForComparisonTypes.SINGLE ? downPayments : defaultDownPayment,
      minimunDownPayment: minimumDownPayment ?? 0,
      maximumDownPayment: maximumDownPayment ?? defaultDownPayment + 1000
    }

    return downPaymentsWithRanges
  }

const calculateDownPaymentsWithRangeForSingle = calculateDownPaymentsWithRange(
  VehiclesForComparisonTypes.SINGLE
)
const calculateDownPaymentsWithRangeForMultiple = calculateDownPaymentsWithRange(
  VehiclesForComparisonTypes.MULTIPLE
)

const mapProgramDataToDownPaymentAndQuoteParamsForSingleVehicleComparison = (
  vin: string,
  state: DeskingState,
  programData: IProgramDetails[],
  globalState: DealDeskingState,
  term: number,
  mileage: number,
  rowIndex?: number
) => {
  const internetPrice = state.vehiclesData.get(vin).vehicle.internet_price

  const orderFinanceType = state?.order?.finance_type ?? ''

  const financeProgram = programData?.find((p) => p.finance_type === FinanceTypes.FINANCE)
  const financeDownpaymentChart = Array.isArray(financeProgram?.downpayment_chart)
    ? financeProgram?.downpayment_chart?.[0]
    : financeProgram?.downpayment_chart
  const financeDownPaymentsWithRanges = calculateDownPaymentsWithRangeForSingle(
    financeDownpaymentChart,
    internetPrice
  )
  const financeDownPaymentInOrder =
    state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE].down_payments.value?.[1]
  const financeDownPaymentsWithRangesForOrder = {
    ...financeDownPaymentsWithRanges,
    value: [
      financeDownPaymentInOrder === financeDownPaymentsWithRanges.value[0]
        ? Math.max(
          financeDownPaymentsWithRanges.value[0],
          financeDownPaymentsWithRanges.minimunDownPayment
        )
        : financeDownPaymentsWithRanges.value[0],
      financeDownPaymentInOrder,
      financeDownPaymentInOrder === financeDownPaymentsWithRanges.value[2]
        ? Math.min(
          financeDownPaymentInOrder + 1000,
          financeDownPaymentsWithRanges.maximumDownPayment
        )
        : financeDownPaymentsWithRanges.value[2]
    ]
  }

  const leaseProgram = programData.find((p) => p.finance_type === FinanceTypes.LEASE)
  const leaseDownpaymentChart = Array.isArray(leaseProgram?.downpayment_chart)
    ? leaseProgram?.downpayment_chart?.[0]
    : leaseProgram?.downpayment_chart
  const leaseDownPaymentsWithRanges = calculateDownPaymentsWithRangeForSingle(
    leaseDownpaymentChart,
    internetPrice
  )
  const leaseDownPaymentInOrder =
    state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE].down_payments.value?.[1]
  const leaseDownPaymentsWithRangesForOrder = {
    ...leaseDownPaymentsWithRanges,
    value: [
      leaseDownPaymentInOrder === leaseDownPaymentsWithRanges.value[0]
        ? Math.max(
          leaseDownPaymentsWithRanges.value[0] - 1000,
          leaseDownPaymentsWithRanges.minimunDownPayment
        )
        : leaseDownPaymentsWithRanges.value[0],
      leaseDownPaymentInOrder,
      leaseDownPaymentInOrder === leaseDownPaymentsWithRanges.value[2]
        ? Math.min(leaseDownPaymentInOrder + 1000, leaseDownPaymentsWithRanges.maximumDownPayment)
        : leaseDownPaymentsWithRanges.value[2]
    ]
  }

  const defaultContractTermsFinance = selectDefaultTerms(
    globalState.contractTerms.filter((term) => term.finance_type?.includes(FinanceTypes.FINANCE)),
    globalState.dealerPreferences.default_term_finance_id
  )
  const defaultContractTermsLease = selectDefaultTerms(
    globalState.contractTerms.filter((term) => term.finance_type?.includes(FinanceTypes.LEASE)),
    globalState.dealerPreferences.default_term_lease_id
  )
  const defaultAllowedMillage = selectDefaultMileages(
    globalState.allowedMillages,
    globalState.dealerPreferences.default_mileage_id
  )

  const defaultFinanceApr = (financeProgram?.final_customer_rate ?? 0) as number
  const defaultLeaseApr = (leaseProgram?.final_customer_rate ?? 0) as number

  const defaultFinanceRvValue = (financeProgram?.rv_chart?.[0]?.rv_value ?? 0) as number
  const defaultLeaseRvValue =
    leaseProgram?.pricing_method === PricingTypes.EXTERNAL
      ? leaseProgram?.rv_chart?.[0]?.rv_value
        ? (Number(leaseProgram?.rv_chart?.[0]?.rv_value) /
          Number(state?.vehiclesData?.get(vin)?.vehicle?.internet_price)) *
        100
        : 0
      : ((leaseProgram?.rv_chart?.[0]?.rv_value ?? 0) as number)

  const rvValueRangeFinance =
    financeProgram?.pricing_method === PricingTypes.INTERNAL
      ? {
        minimumRv: financeProgram?.rv_chart?.[0]?.minimum_rv,
        maximumRv: financeProgram?.rv_chart?.[0]?.maximum_rv
      }
      : {
        minimumRv: 0,
        maximumRv: 0
      }
  const rvValueRangeLease =
    leaseProgram?.pricing_method === PricingTypes.INTERNAL
      ? {
        minimumRv: leaseProgram?.rv_chart?.[0]?.minimum_rv,
        maximumRv: leaseProgram?.rv_chart?.[0]?.maximum_rv
      }
      : {
        minimumRv: 0,
        maximumRv: 0
      }
  const financeDefaultQuoteParams = (
    state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE].quote_params as QuoteParams[]
  ).map((quote_params: QuoteParams, index: number) => {
    if (quote_params.contract_term === 0) {
      return {
        contract_term: defaultContractTermsFinance[index]?.term,
        apr: term === defaultContractTermsFinance[index]?.term ? defaultFinanceApr : 0,
        rv_value: defaultFinanceRvValue ?? 0
      }
    }

    if (quote_params.contract_term === term) {
      return {
        contract_term: term,
        apr: defaultFinanceApr,
        rv_value: defaultFinanceRvValue ?? 0
      }
    }

    return quote_params
  })

  const leaseDefaultQuoteParams = (
    state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE].quote_params as QuoteParams[]
  ).map((quote_params: QuoteParams, index: number) => {
    if (quote_params.contract_term === 0) {
      return {
        contract_term: defaultContractTermsLease[index]?.term,
        apr: term === defaultContractTermsLease[index]?.term ? defaultLeaseApr : 0,
        rv_value: defaultLeaseRvValue ?? 0,
        annual_usage: defaultAllowedMillage[index]?.value
      }
    }

    if (quote_params.contract_term === term && quote_params.annual_usage === mileage) {
      return {
        contract_term: term,
        apr: defaultLeaseApr,
        rv_value: defaultLeaseRvValue ?? 0,
        annual_usage: mileage
      }
    }

    return quote_params
  })

  let leaseCalculationsBoxes = undefined
  let financeCalculationBoxes = undefined

  if (!financeProgram && rowIndex) {
    const newQuoteParam = financeDefaultQuoteParams?.find((item) => item.contract_term === term)
    financeCalculationBoxes = state[VehiclesForComparisonTypes.SINGLE][
      FinanceTypes.FINANCE
    ].calculationsBoxes?.map((item, index) => {
      if (index === rowIndex && item?.[0]) return [newQuoteParam, '0', '0', '0']
      return item
    })
  }

  if (!leaseProgram && rowIndex) {
    const newQuoteParam = leaseDefaultQuoteParams?.find((item) => item.contract_term === term)
    leaseCalculationsBoxes = state[VehiclesForComparisonTypes.SINGLE][
      FinanceTypes.LEASE
    ].calculationsBoxes?.map((item, index) => {
      if (index === rowIndex && item?.[0]) return [newQuoteParam, '0', '0', '0']
      return item
    })
  }

  return {
    [FinanceTypes.FINANCE]: {
      ...state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE],
      ...(!state.isVehicleSelectedAndOrderSaved ||
        orderFinanceType !== FinanceTypes.FINANCE ||
        state?.submission_tier
        ? {
          ...rvValueRangeFinance,
          vin,
          ...(financeProgram ? { down_payments: financeDownPaymentsWithRanges } : {}),
          quote_params: financeDefaultQuoteParams,
          calculationsBoxes:
            financeCalculationBoxes ??
            makeCalculationGrid({
              vin,
              down_payments: financeDownPaymentsWithRanges.value,
              quote_params: financeDefaultQuoteParams,
              program: financeProgram,
              calculationsBoxes:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE].calculationsBoxes
            })
        }
        : {
          ...(financeProgram ? { down_payments: financeDownPaymentsWithRangesForOrder } : {}),
          ...rvValueRangeFinance,
          calculationsBoxes:
            financeCalculationBoxes ??
            makeCalculationGrid({
              vin,
              down_payments: financeDownPaymentsWithRangesForOrder.value,
              quote_params:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE].quote_params,
              program: financeProgram,
              calculationsBoxes:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.FINANCE].calculationsBoxes
            })
        })
    },
    [FinanceTypes.LEASE]: {
      ...state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE],
      ...(!state.isVehicleSelectedAndOrderSaved ||
        orderFinanceType !== FinanceTypes.LEASE ||
        state?.submission_tier
        ? {
          ...rvValueRangeLease,
          vin,
          ...(leaseProgram ? { down_payments: leaseDownPaymentsWithRanges } : {}),
          quote_params: leaseDefaultQuoteParams,
          calculationsBoxes:
            leaseCalculationsBoxes ??
            makeCalculationGrid({
              vin,
              down_payments: leaseDownPaymentsWithRanges.value,
              quote_params: leaseDefaultQuoteParams,
              program: leaseProgram,
              calculationsBoxes:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE].calculationsBoxes
            })
        }
        : {
          ...(leaseProgram ? { down_payments: leaseDownPaymentsWithRangesForOrder } : {}),
          ...rvValueRangeLease,
          calculationsBoxes:
            leaseCalculationsBoxes ??
            makeCalculationGrid({
              vin,
              down_payments: leaseDownPaymentsWithRangesForOrder.value,
              quote_params:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE].quote_params,
              program: leaseProgram,
              calculationsBoxes:
                state[VehiclesForComparisonTypes.SINGLE][FinanceTypes.LEASE].calculationsBoxes
            })
        })
    }
  }
}

const mapProgramDataToDownPaymentAndQuoteParamsForMultiVehicleComparison = (
  vin: string,
  state: DeskingState,
  programData: IProgramDetails[],
  index: number,
  globalState: DealDeskingState
) => {
  const internetPrice = state.vehiclesData.get(vin).vehicle.internet_price

  const orderFinanceType = state?.order?.finance_type ?? ''

  const financeProgram = programData.find((p) => p.finance_type === FinanceTypes.FINANCE)
  const financeDownPaymentsWithRanges = calculateDownPaymentsWithRangeForMultiple(
    financeProgram?.downpayment_chart?.[0],
    internetPrice
  )

  const leaseProgram = programData.find((p) => p.finance_type === FinanceTypes.LEASE)
  const leaseDownPaymentsWithRanges = calculateDownPaymentsWithRangeForMultiple(
    leaseProgram?.downpayment_chart?.[0],
    internetPrice
  )

  const defaultContractTerms = globalState.contractTerms.find(
    (ct) => ct.id === globalState.dealerPreferences.default_term_id
  )
  const defaultAllowedMillage = globalState.allowedMillages.find(
    (am) => am.id === globalState.dealerPreferences.default_mileage_id
  )

  const defaultFinanceApr = financeProgram?.final_customer_rate as number
  const defaultLeaseApr = leaseProgram?.final_customer_rate as number

  const defaultFinanceRvValue = financeProgram?.rv_chart?.[0]?.rv_value as number
  const defaultLeaseRvValue = leaseProgram?.rv_chart?.[0]?.rv_value as number

  const financeDefaultQuoteParams = {
    contract_term: defaultContractTerms?.term,
    apr: defaultFinanceApr,
    rv_value: defaultFinanceRvValue ?? 0
  }
  const leaseDefaultQuoteParams = {
    contract_term: defaultContractTerms?.term,
    apr: defaultLeaseApr,
    rv_value: defaultLeaseRvValue ?? 0,
    annual_usage: defaultAllowedMillage?.value
  }

  const updatedFinanceObject = {
    vin,
    down_payments: financeDownPaymentsWithRanges,
    quote_params: financeDefaultQuoteParams,
    calculationsBoxes: makeCalculationGrid({
      vin,
      down_payments: financeDownPaymentsWithRanges.value,
      quote_params: financeDefaultQuoteParams,
      program: financeProgram,
      calculationsBoxes:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE][index].calculationsBoxes
    })
  }

  const updatedFinanceObjectForOrder = {
    down_payments: {
      ...financeDownPaymentsWithRanges,
      value:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE][index].down_payments.value
    },
    calculationsBoxes: makeCalculationGrid({
      vin,
      down_payments:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE][index].down_payments.value,
      quote_params:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE][index].quote_params,
      program: financeProgram,
      calculationsBoxes:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE][index].calculationsBoxes
    })
  }

  const updatedLeaseObject = {
    vin,
    down_payments: leaseDownPaymentsWithRanges,
    quote_params: leaseDefaultQuoteParams,
    calculationsBoxes: makeCalculationGrid({
      vin,
      down_payments: leaseDownPaymentsWithRanges.value,
      quote_params: leaseDefaultQuoteParams,
      program: leaseProgram,
      calculationsBoxes:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE][index].calculationsBoxes
    })
  }

  const updatedLeaseObjectForOrder = {
    down_payments: {
      ...leaseDownPaymentsWithRanges,
      value:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE][index].down_payments.value
    },
    calculationsBoxes: makeCalculationGrid({
      vin,
      down_payments:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE][index].down_payments.value,
      quote_params:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE][index].quote_params,
      program: leaseProgram,
      calculationsBoxes:
        state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE][index].calculationsBoxes
    })
  }

  return {
    [FinanceTypes.FINANCE]: state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.FINANCE].map(
      (item, i) =>
        i === index
          ? orderFinanceType !== FinanceTypes.FINANCE
            ? { ...item, ...updatedFinanceObject }
            : { ...item, ...updatedFinanceObjectForOrder }
          : item
    ),
    [FinanceTypes.LEASE]: state[VehiclesForComparisonTypes.MULTIPLE][FinanceTypes.LEASE].map(
      (item, i) =>
        i === index
          ? orderFinanceType !== FinanceTypes.LEASE
            ? { ...item, ...updatedLeaseObject }
            : { ...item, ...updatedLeaseObjectForOrder }
          : item
    )
  }
}

const updateMiscBoxCell = (
  matrix: any[][],
  rowIndex: number,
  colIndex: number,
  property: string,
  value: any
): any[][] => {
  return matrix.map((row, rIdx) =>
    row.map((cell, cIdx) => {
      if (rIdx === rowIndex && cIdx === colIndex) {
        return { ...cell, [property]: value }
      }
      return cell
    })
  )
}

const updateCalculationsBoxCell = (
  matrix: PricingApiResult[][],
  rowIndex: number,
  colIndex: number,
  value: any
): PricingApiResult[][] => {
  return matrix.map((row, rIdx) =>
    row.map((cell, cIdx) => {
      if (rIdx === rowIndex && cIdx === colIndex) {
        return value
      }
      return cell
    })
  )
}

const getCalculationsDataFromOrder = (order) => ({
  monthly_payment: order.estimated_monthly_payment,
  tax_on_selling_price: order.tax_amount,
  tax_amount: order.tax_amount,
  tspk: order.tax_amount
})

export type DeskingState = {
  tradeIn: IOrderTradeIn | EmptyObject
  customer: ICustomer | EmptyObject
  finance_type: FinanceType
  vehiclesForComparison: VehicleForComparison
  hasUnsavedOrderChanges: boolean
  skipReservation: boolean
  vehiclesVins: string[]
  proceededWithoutPayment: false
  vehiclesData: Map<string, any> //options, program, fees, vehicle_id_by_trim_code and fni are added here
  insurance: any[]
  [VehiclesForComparisonTypes.SINGLE]: {
    [FinanceTypes.FINANCE]: FinanceLeaseStructure
    [FinanceTypes.LEASE]: FinanceLeaseStructure
  }
  [VehiclesForComparisonTypes.MULTIPLE]: {
    [FinanceTypes.FINANCE]: FinanceLeaseStructure[]
    [FinanceTypes.LEASE]: FinanceLeaseStructure[]
  }
  selection: any | EmptyObject
  isVehicleSelectedAndOrderSaved: boolean
  isStipulationScreen: boolean
  submission_tier: string
  order: any | EmptyObject
  preview: any | EmptyObject
  lender_id: number
  hasUnsavedOrderChanges: boolean
  stipulationData: IStipulationResponse[]
  isProgramLoading: boolean
  isVehicleLoading: boolean
}

export const deskingInitialState = (defaults: any): DeskingState => ({
  tradeIn: {},
  customer: {},
  finance_type: FinanceTypes.FINANCE,
  vehiclesForComparison: VehiclesForComparisonTypes.SINGLE,
  vehiclesVins: [],
  vehiclesData: new Map(),
  insurance: [],
  [VehiclesForComparisonTypes.SINGLE]: createFinanceLeaseStructureForCalculationGrid(),
  [VehiclesForComparisonTypes.MULTIPLE]: createFinanceLeaseStructureForMultiVehicleComparison(),
  selection: {},
  isVehicleSelectedAndOrderSaved: defaults?.hasReferenecId,
  isStipulationScreen: false,
  skipReservation: false,
  stipulationData: [],
  proceededWithoutPayment: false,
  order: {},
  preview: createPreviewDefaults(),
  lender_id: 0,
  hasUnsavedOrderChanges: false,
  isProgramLoading: false,
  isVehicleLoading: false
})

export const deskingReducer =
  (globalState: DealDeskingState) =>
    (state: DeskingState, action: DeskingActions): DeskingState => {
      switch (action.type) {
        case DeskingActionTypes.ADD_CUSTOMER:
          return { ...state, customer: { ...action.payload } }
        case DeskingActionTypes.ADD_CUSTOMER_ADDRESS:
          return {
            ...state,
            customer: {
              ...state.customer,
              customer_addresses: action.payload
            }
          }
        case DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT:
          return {
            ...state,
            proceededWithoutPayment: action.payload
          }
        case DeskingActionTypes.SKIP_RESERVATION:
          return {
            ...state,
            skipReservation: action.payload
          }
        case DeskingActionTypes.UPDATE_HAS_UNSAVED_ORDER_CHANGES:
          return {
            ...state,
            hasUnsavedOrderChanges: action.payload
          }
        case DeskingActionTypes.DELETE_CUSTOMER:
          return { ...state, customer: {} }
        case DeskingActionTypes.ADD_TRADE_IN:
          return { ...state, tradeIn: { ...action.payload } }
        case DeskingActionTypes.DELETE_TRADE_IN:
          return { ...state, tradeIn: {} }
        case DeskingActionTypes.ADD_STIPULATIONS_DATA:
          return { ...state, stipulationData: action.payload }
        case DeskingActionTypes.RESET_CALCULATIONS:
          const { vinForCalculationsToReset, indexToReset } = action.payload
          const calculationsReset = resetCalculationsForVin(state, indexToReset)
          return {
            ...state,
            ...calculationsReset
          }
        case DeskingActionTypes.ADD_VEHICLE_VIN:
          const vhcledta = action.payload
          const vinAVV = vhcledta.vin
          const preInstalledDealerOptionsAVV = vhcledta.dealer_options
          const updatedVehicleMapAddVehicleVin = new Map(state.vehiclesData)
          updatedVehicleMapAddVehicleVin.set(vinAVV, {
            ...(updatedVehicleMapAddVehicleVin.get(vinAVV) ?? {}),
            preInstalledDealerOptions: preInstalledDealerOptionsAVV
          })

          return {
            ...state,
            vehiclesVins: [...state.vehiclesVins, vinAVV],
            vehiclesData: updatedVehicleMapAddVehicleVin
          }
        case DeskingActionTypes.ADD_VEHICLE:
          const _vehicleData = action.payload
          const updatedVehicleMapAddVehicle = new Map(state.vehiclesData)
          updatedVehicleMapAddVehicle.set(_vehicleData.vin, {
            ...(updatedVehicleMapAddVehicle.get(_vehicleData.vin) ?? {}),
            ..._vehicleData
          })
          return {
            ...state,
            vehiclesData: updatedVehicleMapAddVehicle
          }
        case DeskingActionTypes.DELETE_VEHICLE_VIN:
          const { vinDelete, indexDelete } = action.payload
          const updatedVehicleDeleteVins = state.vehiclesVins.filter(
            (_, index) => index !== indexDelete
          )
          const calculationsResetDVV = resetSelectionAndCalculationsForVin(state, indexDelete)
          // const updatedVehicleMapDeleteData = new Map(state.vehiclesData)
          // updatedVehicleMapDeleteData.delete(vinDelete)

          return {
            ...state,
            vehiclesVins: updatedVehicleDeleteVins,
            // vehiclesData: updatedVehicleMapDeleteData,
            ...calculationsResetDVV
          }
        case DeskingActionTypes.UPDATE_VEHICLE_VIN:
          const { vinUpdate, originalVin, indexUpdate, vehicleData } = action.payload
          const updatedVehicleUpdateVins = state.vehiclesVins.map((vin, index) =>
            index === indexUpdate ? vinUpdate : vin
          )
          const preInstalledDealerOptionsUVV = vehicleData.dealer_options

          const updatedVehicleMapUpdateData = new Map(state.vehiclesData)
          updatedVehicleMapUpdateData.set(vinUpdate, {
            ...(updatedVehicleMapUpdateData.get(vinUpdate) ?? {}),
            preInstalledDealerOptions: preInstalledDealerOptionsUVV
          })

          const calculationsResetUVV = resetSelectionAndCalculationsForVin(state, indexUpdate)

          return {
            ...state,
            vehiclesVins: updatedVehicleUpdateVins,
            vehiclesData: updatedVehicleMapUpdateData,
            ...calculationsResetUVV
          }
        case DeskingActionTypes.ADD_PROGRAM:
          const { vin, index, program: vehicle_program, term, mileage } = action.payload
          const caluculationBoxRowIndex = action.payload.rowIndex
          const updatedVehicleMapAddProgram = new Map(state.vehiclesData)

          const program = vehicle_program?.map((p) => {
            return { ...p, contract_term: term, mileage }
          })
          const financeProgram = program.find((p) => p.finance_type === FinanceTypes.FINANCE)
          const leaseProgram = program.find((p) => p.finance_type === FinanceTypes.LEASE)
          const currentVehicle = updatedVehicleMapAddProgram.get(vin)

          const existingFinancePrograms =
            currentVehicle?.program?.[FinanceTypes.FINANCE]?.filter(
              (p) => p?.contract_term !== term
            ) ?? []

          const existingLeasePrograms =
            (!mileage
              ? currentVehicle?.program?.[FinanceTypes.LEASE]
              : currentVehicle?.program?.[FinanceTypes.LEASE]?.filter(
                (p) => p?.contract_term !== term && p?.mileage !== mileage
              )) ?? []

          updatedVehicleMapAddProgram.set(vin, {
            ...currentVehicle,
            program: {
              [FinanceTypes.FINANCE]: financeProgram
                ? [...existingFinancePrograms, financeProgram]
                : existingFinancePrograms,
              [FinanceTypes.LEASE]: leaseProgram
                ? [...existingLeasePrograms, leaseProgram]
                : existingLeasePrograms
            }
          })

          const internetPrice = state.vehiclesData.get(vin).vehicle.internet_price
          const financeDownPaymentsWithRanges = calculateDownPaymentsWithRangeForMultiple(
            Array.isArray(financeProgram?.downpayment_chart)
              ? financeProgram?.downpayment_chart?.[0]
              : financeProgram?.downpayment_chart,
            internetPrice
          )
          const leaseDownPaymentsWithRanges = calculateDownPaymentsWithRangeForMultiple(
            Array.isArray(leaseProgram?.downpayment_chart)
              ? leaseProgram?.downpayment_chart?.[0]
              : leaseProgram?.downpayment_chart,
            internetPrice
          )

          const singleVehicleMappedData =
            mapProgramDataToDownPaymentAndQuoteParamsForSingleVehicleComparison(
              vin,
              state,
              program,
              globalState,
              term,
              mileage,
              caluculationBoxRowIndex
            )

          const multiVehicleMappedData =
            mapProgramDataToDownPaymentAndQuoteParamsForMultiVehicleComparison(
              vin,
              state,
              program,
              index,
              globalState
            )
          let updatedPreview = state?.preview
          if (state?.submission_tier && state?.finance_type) {
            const updatedQuoteParams = singleVehicleMappedData[
              state?.finance_type
            ]?.quote_params?.filter((item) => item.contract_term === state?.order?.contract_term)
            if (updatedQuoteParams[0] && updatedQuoteParams[0]?.apr) {
              updatedPreview = {
                ...state?.preview,
                quote_params: updatedQuoteParams[0]
              }
            }
          }

          const _minimunDP =
            state.finance_type === FinanceTypes.FINANCE
              ? financeDownPaymentsWithRanges.minimunDownPayment
              : leaseDownPaymentsWithRanges.minimunDownPayment
          const _maximumDP =
            state.finance_type === FinanceTypes.FINANCE
              ? financeDownPaymentsWithRanges.maximumDownPayment
              : leaseDownPaymentsWithRanges.maximumDownPayment

          return {
            ...state,
            vehiclesData: updatedVehicleMapAddProgram,
            ...(Object.keys(state.selection).length > 0
              ? {
                selection: {
                  ...state.selection,
                  downPayment: {
                    ...state.selection.downPayment,
                    minimunDownPayment: _minimunDP,
                    maximumDownPayment: _maximumDP
                  }
                }
              }
              : {}),
            preview: {
              ...updatedPreview,
              down_payments: {
                ...state.preview.down_payments,
                minimunDownPayment: _minimunDP,
                maximumDownPayment: _maximumDP
              }
            },
            [VehiclesForComparisonTypes.SINGLE]: singleVehicleMappedData,
            [VehiclesForComparisonTypes.MULTIPLE]: multiVehicleMappedData
          }
        case DeskingActionTypes.UPDATE_FINANCE_TYPE:
          return { ...state, finance_type: action.payload }
        case DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI:
          const { fni, dealer_options, fees, vinUOFF } = action.payload
          const updatedVehicleMapAddData = new Map(state.vehiclesData)
          const existingVehicleData = updatedVehicleMapAddData.get(vinUOFF)

          updatedVehicleMapAddData.set(vinUOFF, {
            ...existingVehicleData,
            dealer_options,
            fees,
            fni
          })
          return {
            ...state,
            vehiclesData: updatedVehicleMapAddData
          }
        case DeskingActionTypes.UPDATE_STAGE:
          return {
            ...state,
            order: {
              ...state.order,
              order_stage: action.payload
            }
          }
        case DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON:
          const currentMappedVehicle = new Map(state.vehiclesData)
          const selectedVehicle = currentMappedVehicle.get(state.vehiclesVins?.[0])
          currentMappedVehicle.set(state.vehiclesVins?.[0], {
            ...selectedVehicle,
            program: {
              [FinanceTypes.FINANCE]: [],
              [FinanceTypes.LEASE]: []
            }
          })

          return {
            ...state,
            vehiclesData: currentMappedVehicle,
            vehiclesForComparison: action.payload,
            isVehicleSelectedAndOrderSaved: false,
            order: {
              ...state.order,
              order_stage: OrderStage.QUOTATION
            }
          }

        case DeskingActionTypes.UPDATE_STATUS:
          return {
            ...state,
            order: {
              ...state.order,
              status: action.payload
            }
          }
        case DeskingActionTypes.SELECTED_LENDER:
          return {
            ...state,
            lender_id: action.payload
          }
        case DeskingActionTypes.ADD_INSURANCE:
          return { ...state, insurance: [...action.payload] }
        case DeskingActionTypes.DELETE_INSURANCE:
          return { ...state, insurance: [] }
        case DeskingActionTypes.UPDATE_SELLING_PRICE:
          const { vinUSP, valueUSP } = action.payload

          if (state.isVehicleSelectedAndOrderSaved) {
            return {
              ...state,
              order: {
                ...state.order,
                order_asset: {
                  ...state.order.order_asset,
                  unit_price: valueUSP
                }
              }
            }
          }

          const updatedVehicleMapUSP = new Map(state.vehiclesData)
          const vehObjWithUpdatedSP = {
            ...(updatedVehicleMapUSP.get(vinUSP) ?? {})?.vehicle,
            internet_price: valueUSP
          }
          updatedVehicleMapUSP.set(vinUSP, {
            ...(updatedVehicleMapUSP.get(vinUSP) ?? {}),
            vehicle: vehObjWithUpdatedSP
          })
          return {
            ...state,
            vehiclesData: updatedVehicleMapUSP
          }
        case DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT:
          const { vinQPDP, rowIndex, colIndex, value, field, restrictCaculations } = action.payload
          if (state.isVehicleSelectedAndOrderSaved) {
            return {
              ...state,
              hasUnsavedOrderChanges: true,
              preview: {
                ...state.preview,
                down_payments: !field
                  ? { ...state.preview.down_payments, value }
                  : state.preview.down_payments,
                quote_params: !!field ? value : state.preview.quote_params
              }
            }
          }

          if (state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE) {
            let updatedCalculationBoxesForQPDP =
              state[state.vehiclesForComparison][state.finance_type].calculationsBoxes
            if (!restrictCaculations) {
              updatedCalculationBoxesForQPDP = updateCalculationsBoxCell(
                state[state.vehiclesForComparison][state.finance_type].calculationsBoxes,
                rowIndex,
                colIndex,
                value
              )
            }

            let updatedQuoteParams =
              state?.[state.vehiclesForComparison][state.finance_type]?.quote_params
            if (field === 'quote_params') {
              updatedQuoteParams = updatedQuoteParams?.map((item, index) => {
                if (index + 1 === rowIndex) return value
                return item
              })
            }

            return {
              ...state,
              [state.vehiclesForComparison]: {
                ...state[state.vehiclesForComparison],
                [state.finance_type]: {
                  ...state[state.vehiclesForComparison][state.finance_type],
                  quote_params: updatedQuoteParams,
                  calculationsBoxes: updatedCalculationBoxesForQPDP
                }
              }
            }
          }

          return {
            ...state,
            [state.vehiclesForComparison]: {
              ...state[state.vehiclesForComparison],
              [state.finance_type]: state[VehiclesForComparisonTypes.MULTIPLE][
                state.finance_type
              ].map((financeItem: any, _index: number) => {
                if (financeItem.vin === vinQPDP) {
                  return {
                    ...financeItem,
                    down_payments: !field
                      ? { ...financeItem.down_payments, value }
                      : financeItem.down_payments,
                    quote_params: !!field ? value : financeItem.quote_params
                  }
                }
                return financeItem
              })
            }
          }
        case DeskingActionTypes.UPDATE_CALCULATION_DATA:
          const { property, valueUCD } = action.payload

          state.selection = {
            ...state.selection,
            calculations: valueUCD
          }
          if (state.isVehicleSelectedAndOrderSaved) {
            return {
              ...state,
              preview: {
                ...state.preview,
                calculationsBoxes: valueUCD,
                misc: {
                  ...state.preview.misc,
                  [property]: valueUCD[property]
                }
              }
            }
          }

          if (state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE) {
            const updatedCalculationBoxesForUCD = updateCalculationsBoxCell(
              state[VehiclesForComparisonTypes.SINGLE][state.finance_type].calculationsBoxes,
              state.selection.row,
              state.selection.col,
              valueUCD
            )
            const updatedMiscBoxesForUCD = updateMiscBoxCell(
              state[VehiclesForComparisonTypes.SINGLE][state.finance_type].misc,
              state.selection.row - 1,
              state.selection.col - 1,
              property,
              valueUCD[property]
            )

            return {
              ...state,
              [VehiclesForComparisonTypes.SINGLE]: {
                ...state[VehiclesForComparisonTypes.SINGLE],
                [state.finance_type]: {
                  ...state[VehiclesForComparisonTypes.SINGLE][state.finance_type],
                  calculationsBoxes: updatedCalculationBoxesForUCD,
                  misc: updatedMiscBoxesForUCD
                }
              }
            }
          }

          return {
            ...state,
            [VehiclesForComparisonTypes.MULTIPLE]: {
              ...state[VehiclesForComparisonTypes.MULTIPLE],
              [state.finance_type]: state[VehiclesForComparisonTypes.MULTIPLE][
                state.finance_type
              ].map((financeItem: any, _index: number) => {
                if (financeItem.vin === state.selection.vin) {
                  return {
                    ...financeItem,
                    calculationsBoxes: valueUCD,
                    misc: {
                      ...financeItem.misc,
                      [property]: valueUCD[property]
                    }
                  }
                }
                return financeItem
              })
            }
          }
        case DeskingActionTypes.UPDATE_CALCULATION_GRID_CELL:
          const {
            vinCGC,
            rowIndexCGC,
            colIndexCGC,
            vehicleForComparisonCGC,
            finance_typeCGC,
            valueCGC
          } = action.payload

          if (state.isVehicleSelectedAndOrderSaved) {
            return {
              ...state,
              preview: {
                ...state.preview,
                calculationsBoxes: valueCGC
              },
              [VehiclesForComparisonTypes.SINGLE]: {
                ...state[VehiclesForComparisonTypes.SINGLE],
                [finance_typeCGC]: {
                  ...state[VehiclesForComparisonTypes.SINGLE][finance_typeCGC],
                  calculationsBoxes:
                    typeof state[VehiclesForComparisonTypes.SINGLE][finance_typeCGC]
                      .calculationsBoxes[rowIndexCGC][colIndexCGC] !== 'object'
                      ? updateCalculationsBoxCell(
                        state[VehiclesForComparisonTypes.SINGLE][finance_typeCGC].calculationsBoxes,
                        rowIndexCGC,
                        colIndexCGC,
                        valueCGC
                      )
                      : state[VehiclesForComparisonTypes.SINGLE][finance_typeCGC].calculationsBoxes
                }
              },
              [VehiclesForComparisonTypes.MULTIPLE]: {
                ...state[VehiclesForComparisonTypes.MULTIPLE],
                [finance_typeCGC]: state[VehiclesForComparisonTypes.MULTIPLE][finance_typeCGC].map(
                  (financeItem: any, index: number) => {
                    if (
                      financeItem.vin === vinCGC &&
                      typeof financeItem.calculationsBoxes !== 'object'
                    ) {
                      return {
                        ...financeItem,
                        calculationsBoxes: valueCGC
                      }
                    }
                    return financeItem
                  }
                )
              }
            }
          }

          if (vehicleForComparisonCGC === VehiclesForComparisonTypes.SINGLE) {
            const updatedCalculationBoxesForCGCWithCalculationResponse = updateCalculationsBoxCell(
              state[vehicleForComparisonCGC][finance_typeCGC].calculationsBoxes,
              rowIndexCGC,
              colIndexCGC,
              valueCGC
            )
            const updatedCalculationBoxesForCGCWithUodatedFinanceAmouont = updateCalculationsBoxCell(
              updatedCalculationBoxesForCGCWithCalculationResponse,
              updatedCalculationBoxesForCGCWithCalculationResponse.length - 2,
              colIndexCGC,
              valueCGC.adjusted_capitalized_cost
            )
            const LTVPercentValue =
              (valueCGC.adjusted_capitalized_cost / state.vehiclesData.get(vinCGC).vehicle.msrp) * 100
            const updatedCalculationBoxesForCGC = updateCalculationsBoxCell(
              updatedCalculationBoxesForCGCWithUodatedFinanceAmouont,
              updatedCalculationBoxesForCGCWithUodatedFinanceAmouont.length - 1,
              colIndexCGC,
              LTVPercentValue
            )
            return {
              ...state,
              [VehiclesForComparisonTypes.SINGLE]: {
                ...state[VehiclesForComparisonTypes.SINGLE],
                [finance_typeCGC]: {
                  ...state[VehiclesForComparisonTypes.SINGLE][finance_typeCGC],
                  calculationsBoxes: updatedCalculationBoxesForCGC
                }
              }
            }
          }

          return {
            ...state,
            [VehiclesForComparisonTypes.MULTIPLE]: {
              ...state[VehiclesForComparisonTypes.MULTIPLE],
              [finance_typeCGC]: state[VehiclesForComparisonTypes.MULTIPLE][finance_typeCGC].map(
                (financeItem: any, _index: number) => {
                  if (financeItem.vin === vinCGC) {
                    return {
                      ...financeItem,
                      calculationsBoxes: valueCGC
                    }
                  }
                  return financeItem
                }
              )
            }
          }

        case DeskingActionTypes.UPDATE_SELECTION_DATA:
          const { vinUSD, rowIndexUSD, colIndexUSD } = action.payload

          const calculationsUSD =
            state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
              ? state[state.vehiclesForComparison][state.finance_type].calculationsBoxes[rowIndexUSD][
              colIndexUSD
              ]
              : state[state.vehiclesForComparison][state.finance_type][colIndexUSD].calculationsBoxes

          const downPaymentUSD =
            state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
              ? {
                ...state[state.vehiclesForComparison][state.finance_type].down_payments,
                value:
                  state[state.vehiclesForComparison][state.finance_type].calculationsBoxes[0][
                  colIndexUSD
                  ]
              }
              : state[state.vehiclesForComparison][state.finance_type][colIndexUSD].down_payments

          const quoteParamsUSD =
            state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
              ? state[state.vehiclesForComparison][state.finance_type].calculationsBoxes[
              rowIndexUSD
              ][0]
              : state[state.vehiclesForComparison][state.finance_type][colIndexUSD].quote_params

          return {
            ...state,
            selection: {
              row: rowIndexUSD,
              col: colIndexUSD,
              vin: vinUSD,
              finance_type: state.finance_type,
              vehicleForComparison: state.vehiclesForComparison,
              downPayment: downPaymentUSD,
              quoteParams: quoteParamsUSD,
              calculations: calculationsUSD
            }
          }
        case DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED:
          const vIdx = state.vehiclesVins.findIndex((vn) => vn === state?.selection?.vin)

          return {
            ...state,
            isVehicleSelectedAndOrderSaved: action.payload,
            preview: {
              ...state.preview,
              finance_type: state?.selection?.finance_type,
              vin: state?.selection?.vin,
              vinIndex: vIdx,
              down_payments: state?.selection?.downPayment,
              quote_params: state?.selection?.quoteParams,
              calculationsBoxes: state?.selection?.calculations
            }
          }
        case DeskingActionTypes.MAP_ORDER_TO_STORE:
          const order = action.payload
          const finance_type = order.finance_type
          const vehicleVin = order.Vehicle_details.vin

          const updatedVehiclesVins = state.vehiclesVins.includes(vehicleVin)
            ? state.vehiclesVins
            : [...state.vehiclesVins, vehicleVin]
          const vinIndex = updatedVehiclesVins.findIndex((vn) => vn === vehicleVin)

          const _orderFees = {
            [FinanceTypes.FINANCE]:
              finance_type === FinanceTypes.FINANCE
                ? order.order_fees?.filter((fee) => fee?.fee_type === FeeEvent.INCEPTION) ?? []
                : globalState?.dealerFees?.[FinanceTypes.FINANCE]?.filter(
                  (fee) => fee?.fee_type === FeeEvent.INCEPTION
                ) ?? [],
            [FinanceTypes.LEASE]:
              finance_type === FinanceTypes.LEASE
                ? order.order_fees?.filter((fee) => fee?.fee_type === FeeEvent.INCEPTION) ?? []
                : globalState?.dealerFees?.[FinanceTypes.LEASE]?.filter(
                  (fee) => fee?.fee_type === FeeEvent.INCEPTION
                ) ?? []
          }

          const _orderFni = {
            [FinanceTypes.FINANCE.toLowerCase()]:
              finance_type === FinanceTypes.FINANCE
                ? order.order_fnI ?? []
                : globalState?.fni?.[FinanceTypes.FINANCE.toLowerCase()] ?? [],
            [FinanceTypes.LEASE.toLowerCase()]:
              finance_type === FinanceTypes.LEASE
                ? order.order_fnI ?? []
                : globalState?.fni?.[FinanceTypes.LEASE.toLowerCase()] ?? []
          }

          const updatedVehiclesData = new Map(state.vehiclesData)
          updatedVehiclesData.set(vehicleVin, {
            ...updatedVehiclesData.get(vehicleVin),
            dealer_options: order.order_options ?? [],
            fees: _orderFees,
            fni: _orderFni ?? [],
            vehicle: {
              ...updatedVehiclesData.get(vehicleVin)?.vehicle,
              ...order?.order_asset,
              type: order.order_asset.type ?? 'New',
              internet_price: order?.order_asset?.unit_price,
              msrp: order?.order_asset?.msrp ?? order?.msrp,
              mileage: order?.order_asset.mileage ?? 0
            }
          })

          const orderDownPaymentChartFromProgram = (state.vehiclesData.get(vehicleVin)?.program ??
            {})?.[finance_type]?.find(
              (p) =>
                p?.contract_term === order?.contract_term &&
                (finance_type === FinanceTypes.FINANCE || p?.mileage === order?.allowed_usage)
            )?.downpayment_chart?.[0]

          const downPaymentChart = {
            default_down_payment: order.down_payment,
            maximum_down_payment:
              orderDownPaymentChartFromProgram?.maximum_down_payment ?? order.down_payment,
            minimum_down_payment: orderDownPaymentChartFromProgram?.minimum_down_payment ?? 0
          }
          const downPaymentsForSingle = calculateDownPaymentsWithRangeForSingle(
            downPaymentChart,
            order.selling_price
          )
          const downPaymentsForMultiple = calculateDownPaymentsWithRangeForMultiple(
            downPaymentChart,
            order.selling_price
          )

          const orderContractTermId = globalState?.contractTerms?.find(
            (ct) => ct.term === order.contract_term
          )?.id
          const allowedUsageId = globalState?.allowedMillages?.find(
            (am) => am.value === order.allowed_usage
          )?.id
          const defaultContractTerms = selectDefaultTerms(
            globalState.contractTerms,
            orderContractTermId
          )
          const defaultAllowedMillage = selectDefaultMileages(
            globalState.allowedMillages,
            allowedUsageId
          )

          const apr = order.apr ?? 0
          const rvBalloonValue = order.rv_balloon_percentage ?? 0

          const singleDefaultQuoteParams = (
            state[VehiclesForComparisonTypes.SINGLE][finance_type].quote_params as QuoteParams[]
          ).map((_, index: number) => ({
            contract_term: defaultContractTerms[index]?.term,
            apr: apr,
            rv_value: rvBalloonValue,
            ...(finance_type === FinanceTypes.LEASE
              ? { annual_usage: defaultAllowedMillage[index].value }
              : {})
          }))

          const multipleDefaultQuoteParams = {
            contract_term: order.contract_term,
            apr: apr,
            rv_value: rvBalloonValue,
            ...(finance_type === FinanceTypes.LEASE ? { annual_usage: order.allowed_usage } : {})
          }
          return {
            ...state,
            isVehicleSelectedAndOrderSaved: true,
            isStipulationScreen: order?.order_submissions?.length > 0,
            vehiclesVins: updatedVehiclesVins,
            vehiclesData: updatedVehiclesData,
            finance_type: finance_type,
            vehiclesForComparison: VehiclesForComparisonTypes.PREVIEW,
            tradeIn: order.order_tradein ?? {},
            customer: { ...order.customer_info, credit_rating: order?.credit_rating } ?? {},
            insurance: order?.Insurance_Inforation ? [order.Insurance_Inforation] : [],
            order: order,
            selection: {
              row: 2,
              col: 2,
              vin: vehicleVin,
              finance_type: finance_type,
              vehicleForComparison: VehiclesForComparisonTypes.SINGLE,
              downPayment: downPaymentsForMultiple,
              quoteParams: multipleDefaultQuoteParams,
              calculations: state?.selection?.calculations ?? getCalculationsDataFromOrder(order)
            },
            preview: {
              ...state.preview,
              finance_type: state?.selection?.finance_type ?? finance_type,
              status: order.status,
              vin: state?.selection?.vin ?? vehicleVin,
              vinIndex,
              down_payments: state?.selection?.downPayment ?? downPaymentsForMultiple,
              quote_params: state?.selection?.quoteParams ?? multipleDefaultQuoteParams,
              calculationsBoxes: state?.selection?.calculations ?? getCalculationsDataFromOrder(order)
            },
            [VehiclesForComparisonTypes.SINGLE]: {
              ...state[VehiclesForComparisonTypes.SINGLE],
              [finance_type]: {
                ...state[VehiclesForComparisonTypes.SINGLE][finance_type],
                vin: vehicleVin,
                down_payments: downPaymentsForSingle,
                quote_params: singleDefaultQuoteParams,
                calculationsBoxes: makeCalculationGrid({
                  vin: vehicleVin,
                  down_payments: downPaymentsForSingle.value,
                  quote_params: singleDefaultQuoteParams,
                  program: true,
                  calculationsBoxes:
                    state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes
                })
              }
            },
            [VehiclesForComparisonTypes.MULTIPLE]: {
              ...state[VehiclesForComparisonTypes.MULTIPLE],
              [finance_type]: state[VehiclesForComparisonTypes.MULTIPLE][finance_type].map(
                (item: any, i: any) =>
                  i === vinIndex
                    ? {
                      vin: vehicleVin,
                      down_payments: downPaymentsForMultiple,
                      quote_params: multipleDefaultQuoteParams,
                      calculationsBoxes: makeCalculationGrid({
                        vin,
                        down_payments: downPaymentsForMultiple.value,
                        quote_params: multipleDefaultQuoteParams,
                        calculationsBoxes:
                          state[VehiclesForComparisonTypes.MULTIPLE][finance_type][vinIndex]
                            .calculationsBoxes
                      })
                    }
                    : item
              )
            }
          }

        case DeskingActionTypes.SAVE_ORDER:
          return {
            ...state,
            hasUnsavedOrderChanges: action.payload,
            preview: {
              ...state.preview,
              calculationsBoxes: {
                ...state.preview.calculationsBoxes,
                final_rate: state.preview.quote_params.apr
              },
              quote_params: {
                ...state.preview.quote_params,
                apr: state.preview.quote_params.apr
              }
            },
            order: {
              ...state.order,
              apr: state.preview.quote_params.apr,
              margin: state.preview.quote_params.apr
            },
            selection: {
              ...state.selection,
              quoteParams: {
                ...state.selection.quoteParams,
                apr: state.preview.quote_params.apr
              },
              downPayment: state.preview.down_payments,
              calculations: {
                ...state.preview.calculationsBoxes
              }
            }
          }
        case DeskingActionTypes.ADD_SUBMISSION_TIER:
          return { ...state, submission_tier: action.payload }
        case DeskingActionTypes.SET_PROGRAM_LOADING:
          return {
            ...state,
            isProgramLoading: action.payload
          }
        case DeskingActionTypes.ADD_VEHICLE_LOADING:
          return {
            ...state,
            isVehicleLoading: action.payload
          }
        default:
          return state
      }
    }

export const DeskingContext = createContext<DeskingContextType>(undefined)

export const useDeskingContext = () => {
  const context = useContext(DeskingContext)
  if (context === undefined) {
    throw new Error('useDesking must be used within a DeskingProvider')
  }
  return context
}

export const DeskingProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { states } = useStoreContext()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')

  const [state, dispatch] = useReducer(
    deskingReducer(states?.dealDesk),
    deskingInitialState({ hasReferenecId: !!orderReferenceId })
  )

  const formatCurrency = createCurrencyFormatter(states?.dealDesk?.defaultCurrency?.code)

  return (
    <DeskingContext.Provider value={{ state, dispatch, formatCurrency }}>
      {children ? children : <Outlet />}
    </DeskingContext.Provider>
  )
}
