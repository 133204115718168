import styled from "@emotion/styled";
import { GLOBAL_THEME as Theme } from "@styles/theme";
import loadingIndicator from "../../../shared/assets/icons/button/loading-indicator.svg";

export const ButtonWrap = styled("div")({
  "&.button-wrap": {
    ".ant-btn": {
      height: 52,
      span: {
        alignSelf: "center",
      },
      ".ant-btn-loading-icon": {
        width: 20,
        height: 20,
        ".anticon": {
          "&.anticon-loading": {
            backgroundImage: `url(${loadingIndicator})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: "100%",
            height: "100%",
            display: "block",
            svg: {
              display: "none",
            },
          },
        },
      },
      "&.ant-btn-primary": {
        "&:hover": {
          backgroundColor: Theme.components?.Button?.textTextActiveColor,
        },
      },
      "&.ant-btn-color-default": {
        "&:hover": {
          boxShadow:
            "0 0 0 2px" + Theme.components?.Button?.defaultActiveBorderColor,
          borderColor: Theme.components?.Button?.defaultActiveBg,
        },
        "&.button--btn-disabled": {
          "&:hover": {
            boxShadow: "none",
            borderColor: Theme.token?.colorBorderSecondary,
          },
        },
        "&.button--btn-secondary": {
          backgroundColor: Theme.components?.Button?.defaultActiveBorderColor,
          boxShadow: "none",
          color: Theme.token?.colorWhite,
          "&:hover": {
            backgroundColor: Theme.components?.Button?.ghostBg,
          },
        },
      },
      "&.button--btn-fixed": {
        width: 224,
        padding: "2px 24px",
        "&.button--btn-disabled": {
          cursor: "default",
          color: Theme.token?.colorBorder,
          background: Theme.components?.Button?.defaultBg,
          borderColor: Theme.token?.colorBorderSecondary,
        },
      },
      "&.button--link-button": {
        padding: 0,
        width: "auto",
        height: "auto",
        border: "none",
        gap: 4,
        "&.button--btn-small": {
          width: "auto",
          height: "auto",
        },
        "&.icon--after-text": {
          flexDirection: "row-reverse",
        },
        "&:hover, &:focus": {
          boxShadow: "none",
          color: Theme.components?.Button?.textTextActiveColor,
        },
        "&.button--btn-disabled": {
          "&:hover, &:focus": {
            color: Theme.token?.colorBorder,
          },
        },
      },
      "&.button--btn-small": {
        width: "auto",
        height: 36,
        "&.button--btn-fixed": {
          width: 120,
        },
        "&.button--link-button": {
          width: "auto",
          height: "auto",
          ".bmw-icon": {
            "&.bmw-icon--before": {
              "&:before": {
                fontSize: "1rem",
              },
            },
          },
        },
      },
      "&.ant-btn-primary, &.button--btn-secondary": {
        "&.button--btn-disabled": {
          cursor: "default",
          color: Theme.token?.colorBorder,
          background: Theme.token?.colorTextLightSolid,
          borderColor: Theme.token?.colorTextLightSolid,
          "&:hover": {
            cursor: "default",
            color: Theme.token?.colorBorder,
            background: Theme.token?.colorTextLightSolid,
            borderColor: Theme.token?.colorTextLightSolid,
          },
        },
      },
      "&.button--btn-dark": {
        "&.button--btn-fixed": {
          borderColor: Theme.token?.colorTextLightSolid,
          color: Theme.token?.colorTextLightSolid,
          "&:hover": {
            boxShadow:
              "0 0 0 2px" + Theme.components?.Button?.colorPrimaryTextActive,
            color: Theme.components?.Button?.colorPrimaryTextActive,
            borderColor: Theme.components?.Button?.defaultActiveBg,
          },
          "&.button--btn-disabled": {
            color: "#8e8e8e",
            background: "rgba(0, 0, 0, 0.48)",
            borderColor: "#8e8e8e",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
        "&.button--link-button": {
          color: Theme.token?.colorTextLightSolid,
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
      "&.button--btn-disabled": {
        cursor: "default",
        color: Theme.token?.colorBorder,
        background: Theme.components?.Button?.defaultBg,
        borderColor: Theme.token?.colorBorderSecondary,
      },
      "&.button--only-icon": {
        width: 52,
        padding: "10px",
        "@media (min-width: 1920px)": {
          ".bmw-icon": {
            "&.bmw-icon--before": {
              "&::before": {
                fontSize: 24,
              },
            },
          },
        },
      },
      "@media (max-width: 768px)": {
        "&.button--full-width": {
          width: "100%",
          "&.button--btn-fixed": {
            width: "100%",
          },
        },
      },
    },
    "@media (max-width: 768px)": {
      "&.button-wrap--full-width": {
        width: "100%",
      },
    },
  },
});
