import clsx from "clsx";
import { useRef } from "react";
import { DatePicker as AntDatepikcer } from "antd";
import { DatepickerWrap } from "./date-picker.style";
import { DatepickerProps } from "./date-picker.props";

export const Datepicker = ({
  className,
  id,
  label,
  name,
  value,
  placeholder,
  open,
  onChange,
  status,
  errorText,
  disabled,
  showInfoIcon,
  prevIcon,
  nextIcon,
  helpText,
  format = "DD. MMM YYYY",
}: DatepickerProps) => {
  const datepickerWrapRef = useRef<HTMLDivElement>(null);
  return (
    <DatepickerWrap className="date-picker-wrap" ref={datepickerWrapRef}>
      {label && (
        <label
          className={clsx({
            "input-label text--label text-secondary": true,
          })}
        >
          <span>{label}</span>
          {showInfoIcon && (
            <div
              className="input-label-icon"
              role="img"
              aria-label="Icon for information"
              data-icon="information"
            />
          )}
        </label>
      )}
      <AntDatepikcer
        className={clsx(className, {
          "input--input-error": status === "error",
        })}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        format={format}
        allowClear={false}
        value={value}
        open={open}
        suffixIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.125 2.5H6.875V1.25H5.625V2.5H4.375C3.8588 2.5 3.3741 2.66076 3.01743 3.01743C2.66076 3.3741 2.5 3.8588 2.5 4.375V15.625C2.5 16.1412 2.66076 16.6259 3.01743 16.9826C3.3741 17.3392 3.8588 17.5 4.375 17.5H15.625C16.1412 17.5 16.6259 17.3392 16.9826 16.9826C17.3392 16.6259 17.5 16.1412 17.5 15.625V4.375C17.5 3.8588 17.3392 3.3741 16.9826 3.01743C16.6259 2.66076 16.1412 2.5 15.625 2.5H14.375V1.25H13.125V2.5ZM3.75 4.375C3.75 4.1162 3.82674 3.9759 3.90132 3.90132C3.9759 3.82674 4.1162 3.75 4.375 3.75H15.625C15.8838 3.75 16.0241 3.82674 16.0987 3.90132C16.1732 3.9759 16.25 4.1162 16.25 4.375V6.25H3.75V4.375ZM3.75 7.5H16.25V15.625C16.25 15.8838 16.1732 16.0241 16.0987 16.0987C16.0241 16.1732 15.8838 16.25 15.625 16.25H4.375C4.1162 16.25 3.9759 16.1732 3.90132 16.0987C3.82674 16.0241 3.75 15.8838 3.75 15.625V7.5Z"
              fill="#262626"
            />
            <path
              d="M6.63056 13.4068V14.3352C6.98869 14.4015 7.4595 14.4877 8.08281 14.4877C8.83875 14.4877 9.3295 14.3087 9.66769 13.9572C9.91306 13.6986 10.0391 13.314 10.0391 12.9227C10.0391 12.5514 9.94619 12.2662 9.734 12.0209C9.62787 11.8949 9.52844 11.8153 9.42231 11.7424C9.47537 11.6694 9.58812 11.5037 9.68094 11.3578C9.84675 11.0992 9.96606 10.8869 9.96606 10.5753C9.96606 10.2238 9.84013 9.89225 9.5815 9.65356C9.28306 9.37506 8.85206 9.26231 8.17562 9.26231C7.738 9.26231 7.02181 9.39494 6.67037 9.46125V10.383C7.00856 10.3167 7.68494 10.1841 8.15575 10.1841C8.54037 10.1841 8.74594 10.2238 8.86531 10.3299C8.96475 10.4228 8.98469 10.5156 8.98469 10.6549C8.98469 10.8273 8.87194 11.0129 8.71944 11.2185L8.56025 11.4374H7.13456V12.3326H8.02975C8.295 12.3326 8.54038 12.3326 8.71281 12.4188C8.94488 12.5315 9.04437 12.6841 9.04437 12.9227C9.04437 13.1217 8.99131 13.2742 8.83875 13.3936C8.67963 13.5129 8.42762 13.566 8.06294 13.566C7.52581 13.566 7.04169 13.4798 6.63056 13.4068Z"
              fill="#262626"
            />
            <path
              d="M13.0327 14.375V9.23581L10.7714 9.93206V10.9135L12.038 10.5289V14.375H13.0327Z"
              fill="#262626"
            />
          </svg>
        }
        prevIcon=<div
          className="bmw-icon bmw-icon--before data-icon"
          data-icon={prevIcon}
        />
        nextIcon=<div
          className="bmw-icon bmw-icon--before data-icon"
          data-icon={nextIcon}
        />
        getPopupContainer={(trigger) =>
          datepickerWrapRef.current || document.body
        }
      />
      {helpText && (
        <label className="help-text text-secondary text--disclaimer">
          {helpText}
        </label>
      )}
      {status === "error" && errorText && (
        <label className="error-text text-error text--disclaimer">
          {errorText}
        </label>
      )}
    </DatepickerWrap>
  );
};
