import { Header } from "@modules/components";
import { useUploadTemplate } from "@modules/services/configureTemplateService";
import { Slide, useTheme } from "@mui/material";
import {
  Box,
  Button,
  FileDragDrop,
  FilePreview,
  Icon,
  Input,
  Select,
  Snackbar,
  Switch,
} from "@ntpkunity/controls";
import { APP_ROUTES } from "@router/path";
import { TEMPLATE_NAME_REGEX } from "@shared/constants";
import { FC, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UploadTemplatePageWrap } from "./uploadTemplatePageStyle";

const UploadTemplatePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [fileMetadata, setFileMetadata] = useState({
    name: "",
    size: "",
    extension: "",
  });
  // const [templateType, setTemplateType] = useState(
  //   "quote_summary_hire_purchase"
  // );
  const [search, setSearch] = useState("");
  const [toastData, setToastData] = useState({
    toastLoading: false,
    toastMessage: "",
    variant: "",
  });
  const [base64string, setBase64String] = useState<string | ArrayBuffer>("");
  const [htmlstring, setHTMLString] = useState("");
  const { mutate: uploadTemplate } = useUploadTemplate();
  const dealerCode = new URLSearchParams(location.search).get("dealer_code");
  const apiKey = new URLSearchParams(location.search).get("x-api-key");
  
  const appendParamsToUrl = (path: string) => {
    const url = new URL(path, window.location.href);
    if (dealerCode) {
      url.searchParams.append("dealer_code", dealerCode);
    }
    if (apiKey) {
      url.searchParams.append("x-api-key", apiKey);
    }
    return url.pathname + url.search;
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToastData({
      toastLoading: false,
      toastMessage: "",
      variant: "",
    });
  };

  // Common function to handle file selection and drag-and-drop events
  const handleFile = (file) => {
    setFileMetadata({
      name: file.name,
      size: file.size,
      extension: file.name.split(".").pop(),
    });

    setSearch(file ? file.name.split(".").slice(0, -1).join(".") : "");
    convertFileToBase64(file);
  };

  // Event handler for file selection
  const onFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  // Event handler for drag-and-drop
  const onFileDrag = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64String = reader.result;
      setBase64String(base64String);
      const htmlString = await convertBase64ToHTML(base64String, file.type);
      setHTMLString(htmlString);
    };
    reader.readAsDataURL(file);
  };

  const convertBase64ToHTML = async (base64String, fileType) => {
    let htmlString = "";
    switch (fileType) {
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        htmlString = await convertDocxToHTML(base64String);
        break;
      case "text/html":
        htmlString = base64String.replace(/^data:text\/html;base64,/, "");
        htmlString = decodeURIComponent(escape(atob(htmlString)));
        break;
      case "text/plain":
        htmlString = base64String.replace(/^data:text\/plain;base64,/, "");
        htmlString = `<pre>${decodeURIComponent(
          escape(atob(htmlString))
        )}</pre>`;
        break;
      default:
        htmlString = "Unsupported file type";
        break;
    }

    const content = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body>
        ${htmlString}
      </body>
      </html>
    `;
    return content;
  };

  const convertDocxToHTML = async (base64String) => {
    const mammoth = require("mammoth");
    const arrayBuffer = base64ToArrayBuffer(
      base64String.replace(
        /^data:application\/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,/,
        ""
      )
    );
    try {
      const result = await mammoth.convertToHtml({ arrayBuffer });
      return result.value;
    } catch (error) {
      return "Error converting DOCX to HTML";
    }
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const data = {
    is_active: checked,
    is_deleted: false,
    template_name: `${search.replace(/\s+/g, '_')}.${fileMetadata.extension}`,
    template_url: "string",
    template_html: JSON.stringify(htmlstring),
    template_type:
      fileMetadata.extension === "txt" ? "SMS" : "quote_summary_hire_purchase",
    storage_type: "S3",
    base64_html_template: "string",
    base64_file_template:
      typeof base64string === "string"
        ? base64string.substring(base64string.indexOf(",") + 1)
        : "",
    default: false,
  };

  function handleUploadTemplate(data) {
    const trimmedTemplateName = data.template_name.trim();
    const isOnlyFileExtension = [".docx", ".txt", ".html"].includes(
      trimmedTemplateName.toLowerCase()
    );

    if (!trimmedTemplateName || isOnlyFileExtension) {
      setToastData({
        toastLoading: true,
        toastMessage: "Please Enter a Valid Template Name",
        variant: "error",
      });
      return;
    } else if (data.base64_file_template === "") {
      setToastData({
        toastLoading: true,
        toastMessage: "A file must be selected for upload",
        variant: "error",
      });
      return;
    } else {
      if (dealerCode) {
        data.dealer_code = dealerCode;
      }
      uploadTemplate(data, {
        onSuccess: () => {
          setSearch("");
          setBase64String("");
          setToastData({
            toastLoading: true,
            toastMessage: "Template uploaded successfully",
            variant: "success",
          });
          setTimeout(() => (
            navigate(appendParamsToUrl(APP_ROUTES.TEMPLEX_HOME_PAGE))
          ), 1000)
        },
        onError: () => {
          setToastData({
            toastLoading: true,
            toastMessage: "An error occurred while uploading the template",
            variant: "error",
          });
        },
      });
    }
  }

  return (
    <>
      <UploadTemplatePageWrap
        theme={theme}
        className="upload-template-page-wrap"
      >
        <Header
          onBack={() => navigate(appendParamsToUrl(APP_ROUTES.TEMPLEX_HOME_PAGE))}
          actionArea={
            <>
              <Button
                theme={theme}
                secondary
                iconText={<Icon name="MoreIcon" />}
              />
              <Button
                theme={theme}
                primary
                text="Save Template"
                onClick={() => handleUploadTemplate(data)}
              />
            </>
          }
        />
        <Box theme={theme} className="main-content">
          <Box theme={theme} className="upload-container inner-container">
            <Box theme={theme} className="filter">
              <Box theme={theme} className="filter-wrapper">
                <Select
                  theme={theme}
                  value={"Storage: S3"}
                  items={[{ value: "Storage: S3", text: "Storage: S3" }]}
                  disabled
                />
                {/* <Select
                  theme={theme}
                  value={templateType}
                  disablePortal={false}
                  items={[
                    {
                      value: "quote_summary_hire_purchase",
                      text: "Quote Summary-Hire Purchase",
                    },
                    {
                      value: "quote_summary_finance_lease",
                      text: "Quote Summary-Finance Lease",
                    },
                    {
                      value: "quote_summary_loan",
                      text: "Quote Summary-Loan",
                    },
                    {
                      value: "quote_repayment",
                      text: "Quote Repayment",
                    },
                    {
                      value: "hire_purchase",
                      text: "Hire Purchase",
                    },
                    {
                      value: "finance_lease",
                      text: "Finance Lease",
                    },
                    {
                      value: "loan",
                      text: "Loan",
                    },
                  ]}
                  onChange={(e) => setTemplateType(e.target.value.toString())}
                /> */}
              </Box>
              <Box theme={theme} className="switch-container">
                <Switch
                  theme={theme}
                  varient={"basic"}
                  // checked={checked}
                  switchEnabled={checked}
                  onChange={() => setChecked(!checked)}
                  label={checked ? "Enabled" : "Disabled"}
                />
              </Box>
            </Box>
            <Box theme={theme} className="input-container">
              <Input
                theme={theme}
                placeholder="Type template name here..."
                type="text"
                fullWidth
                value={search}
                onChange={(event) => {
                  TEMPLATE_NAME_REGEX.test(event)
                    ? setSearch(event)
                    : setSearch(search);
                }}
              />
            </Box>
            <Box theme={theme} className="upload-wrapper">
              <>
                {base64string ? (
                  <FilePreview
                    theme={theme}
                    files={[
                      {
                        key: "image-1",
                        file: {
                          size: fileMetadata.size,
                          name: fileMetadata.name,
                          type: "image",
                        },
                        viewIcon: false,
                        preview: "fileIcon",
                        isUploading: false,
                        error: false,
                        uploadingLabel: "Uploading",
                        uploadingProgress: 0,
                        onRemoveFile: (fileKey: string, index: number) => {
                          setBase64String("");
                        },
                      },
                    ]}
                  />
                ) : (
                  <FileDragDrop
                    theme={theme}
                    allowMultiple={false}
                    onChange={onFileSelect}
                    onDrop={onFileDrag}
                    accept=".pdf, .docx, .txt, .html"
                    hoverLabel={
                      <>
                        Drag and drop document, or{" "}
                        <span className="text-primary link">browse</span>
                        <Box theme={theme} mt={3}>
                          <Button theme={theme} secondary text="Upload File" />
                        </Box>
                      </>
                    }
                  />
                )}
              </>
            </Box>
          </Box>
        </Box>
      </UploadTemplatePageWrap>
      <Snackbar
        theme={theme}
        btn={<></>}
        action={"btn"}
        autoHideDuration={3000}
        onClose={handleClose}
        open={toastData?.toastLoading ? toastData?.toastLoading : false}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        variant={toastData.variant === "success" ? "success" : "error"}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      />
    </>
  );
};

export default UploadTemplatePage;
