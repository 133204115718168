import styled from "@emotion/styled";
import { GLOBAL_THEME as Theme } from "@styles/theme";
import arrowDown from "../../../shared/assets/icons/input-icons/arrow-down.svg";
import arrowDownDisabled from "../../../shared/assets/icons/input-icons/arrow-down-disabled.svg";
import arrowDownError from "../../../shared/assets/icons/input-icons/arrow-down-error.svg";
import errorIcon from "../../../shared/assets/icons/input-icons/error.svg";
import box from "../../../shared/assets/icons/checkbox/box.svg";

export const PhoneInputWrap = styled("div")({
  "&.phone-input-wrap": {
    ".phone-label": {
      marginBottom: 8,
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".phone-label-icon": {
        "&:before": {
          fontFamily: "bmw_next_icons",
          display: "block",
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: 1,
          textTransform: "none",
          wordWrap: "normal",
          whiteSpace: "nowrap",
          direction: "ltr",
          textAlign: "left",
          speak: "none",
          height: "1em",
          outline: "1px solid transparent",
          "-webkit-font-smoothing": "antialiased",
          textRendering: "optimizeLegibility",
          "-moz-osx-font-smoothing": "grayscale",
          fontFeatureSettings: "liga",
          content: "attr(data-icon)",
          width: "auto",
        },
      },
    },
    ".react-tel-input": {
      ".flag-dropdown": {
        ".selected-flag": {
          paddingInlineStart: 20,
          width: 80,
          "&:hover, &:focus": {
            backgroundColor: Theme.components?.Input?.activeBorderColor,
          },
          ".flag": {
            transform: "translateY(-50%) scale(1.5)",
            left: 24,
            margin: 0,
          },
          ".arrow": {
            left: 22,
            border: "none",
            width: 14,
            height: 14,
            backgroundImage: `url(${arrowDown})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            transform: "translateY(-50%)",
            marginTop: 0,
          },
          "&:after": {
            content: '""',
            position: "absolute",
            width: 1,
            height: 24,
            background: Theme.token?.colorBorderSecondary,
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
          },
        },
        backgroundColor: Theme.components?.Input?.activeBorderColor,
        border: "none",
        borderRadius: 0,
        "&.open": {
          ".selected-flag": {
            backgroundColor: Theme.components?.Input?.activeBorderColor,
            borderRadius: 0,
          },
        },
        ".country-list": {
          width: "100%",
          minWidth: 320,
          margin: 0,
          boxShadow:
            "0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "0 0 3px 3px",
          ".country": {
            padding: "8px 12px 8px 14px",
            display: "flex",
            alignItems: "center",
            height: 52,
            ".dial-code": {
              textAlign: "end",
            },
            ".flag": {
              marginRight: 10,
              marginTop: 0,
              transform: "scale(1.5)",
            },
            ".country-name": {
              flex: 1,
              marginRight: 8,
            },
            "&:hover": {
              background: Theme.token?.colorText,
              color: Theme.token?.colorWhite,
              ".dial-code": {
                color: Theme.token?.colorWhite,
              },
            },
            "&.highlight": {
              padding: "8px 40px 8px 16px",
              background: Theme.token?.colorText,
              color: Theme.token?.colorWhite,
              ".dial-code": {
                color: Theme.token?.colorWhite,
                position: "relative",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  width: 22,
                  height: 22,
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: -28,
                  backgroundImage: `url(${box})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                },
              },
            },
          },
          "&::-webkit-scrollbar": {
            width: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#4d4d4d",
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-track": {
            background: Theme.token?.colorBorderSecondary,
            borderRadius: 8,
          },
        },
      },
      ".form-control": {
        padding: "14px 20px 14px 90px",
        height: 51.6,
        width: "100%",
        borderColor: Theme.components?.Input?.colorBorder,
        borderRadius: 3,
        color: Theme.token?.colorText,
        fontFamily: Theme.token?.fontFamily,
        "&:hover, &:focus": {
          boxShadow: Theme.components?.Input?.activeShadow,
        },
      },
    },
    "&.phone-input-disabled": {
      ".react-tel-input": {
        ".form-control": {
          background: Theme.token?.colorTextLightSolid,
          borderColor: Theme.token?.colorBorderSecondary,
          color: Theme.token?.colorBorder,
          "&:hover, &:focus": {
            boxShadow: "none",
          },
        },
        ".flag-dropdown": {
          ".selected-flag": {
            ".arrow": {
              backgroundImage: `url(${arrowDownDisabled})`,
            },
          },
        },
      },
      ".phone-label, .help-text": {
        "&.text-secondary": {
          color: Theme.token?.colorBorder,
        },
      },
      "&.ant-select-selection-placeholder": {
        color: Theme.token?.colorBorder,
      },
    },
    "&.phone-input-invalid": {
      ".react-tel-input": {
        ".form-control": {
          borderColor: Theme.token?.colorError,
          backgroundColor: Theme.token?.colorErrorBg,
          color: Theme.token?.colorError,
          backgroundImage: `url(${errorIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "calc(100% - 20px)",
          backgroundSize: 20,
          paddingInlineEnd: 48,
          "&:hover, &:focus": {
            boxShadow: Theme.components?.Input?.colorErrorBorderHover,
          },
        },
        ".flag-dropdown": {
          ".selected-flag": {
            ".arrow": {
              backgroundImage: `url(${arrowDownError})`,
            },
            "&:after": {
              background: Theme.token?.colorError,
            },
          },
        },
      },
      ".phone-label, .help-text": {
        "&.text-secondary": {
          color: Theme.token?.colorBorder,
        },
      },
      "&.ant-select-selection-placeholder": {
        color: Theme.token?.colorBorder,
      },
    },
  },
});
