import { Button, CardSelect, Checkbox } from "@src/components/atoms";
import "./finance-offer-provider.style.scss";
import BMWFSLogo from "../../../../../../shared/assets/img/[ XL ] BMW Logo.jpg";
import GroupImage from "../../../../../../shared/assets/img/Group.svg";

export const FinanceOfferProvider = ({
  stepFiveDataReceivedEvent,
}: {
  stepFiveDataReceivedEvent: () => void;
}) => {
  return (
    <div className="select-finance-offer-provider">
      <div className="select-finance-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            05
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Select finance offer provider(s).
          </h3>
        </div>
      </div>
      <div className="select-finance-body">
        <div className="description-container">
          <span className="text--body-2">
            Your credit application will be processed by BMW Financial Services,
            for whom this platform is optimized. You may also request quotes
            from other finance offer providers through BMW of Manhattan.
          </span>
          <span className="text--body-2 your-finance">
            Your finance offer provider.
          </span>
        </div>
        <div className="card-container">
          <CardSelect
            logo={BMWFSLogo}
            text={"BMW Financial Service"}
            defaultCard
          />
          <CardSelect
            logo={GroupImage}
            text={"Preferred Lenders for BMW of Manhattan"}
            hasCheckbox={<Checkbox label="Select" onChangeHandler={() => {}} />}
          />
        </div>
        <div className="disclaimer-provider">
          <p>By selecting [Partners of BMW of Manhattan], you:</p>
          <ul className="bullet-point-1 text--body-2">
            <li>
              Authorize BMW of Manhattan to share your credit application with
              financial institutions and credit agencies for credit offers and
              lawful purposes.
            </li>
          </ul>
          <p className="text--body-2">
            By submitting this credit application, you:
          </p>
          <ul className="text--body-2">
            <li>
              Understand that no offer is guaranteed, and that you may or may
              not receive offers from BMW Financial Services or credit partners
              of BMW of Manhattan.
            </li>
          </ul>
        </div>
        <Button
          type="primary"
          htmlType={"button"}
          text="Agree &amp; continue"
          onClick={stepFiveDataReceivedEvent}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
