import {
  COMPANY_PROFILE,
  GET_ALL_COUNTRIES,
  THEME_BUILDER,
  GET_ASSET_CATEGORIES,
  GET_ASSET_TYPES,
  GET_ASSET_SUB_TYPES,
  GET_ASSET_CONDITIONS,
  GET_PRODUCT_TYPES,
  GET_FINANCE_TYPES,
  GET_CLIENT_TYPES,
  GET_COMPLIANCE_DOCUMENTS,
  GET_DOCUMENT_TYPES,
  GET_ACTIVE_DEALERS,
  GET_LENDERS,
  GET_DEALER_PROFILE,
  SAVE_CONFIGURATION,
} from "@helpers/endpoints";
import { indexAPI } from "./instances";
import { Events, NotificationType } from "@helpers/enum";
import { SaveFinanceConfigReq } from "@_types/index";

export const getAllCountries = async () => {
  return indexAPI.get(GET_ALL_COUNTRIES).then((response) => response.data);
};

export const getThemeBuilderByEmail = async (userEmail: string) => {
  return await indexAPI
    .get(`${THEME_BUILDER}?user_email=${userEmail}`)
    .then((res: any) => {
      return res.data;
    });
};

export const getCompanyByTenantId = async (tenant_id: number) => {
  return await indexAPI
    .get(`${COMPANY_PROFILE}/${tenant_id}`)
    .then((res: any) => {
      return res.data;
    });
};

export const getAssetCategories = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_CATEGORIES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetSubTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_SUB_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAssetConditions = async (companyId: number) => {
  return await indexAPI
    .get(GET_ASSET_CONDITIONS, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getProductTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_PRODUCT_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getFinanceTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_FINANCE_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getClientTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_CLIENT_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getDocumentTypes = async (companyId: number) => {
  return await indexAPI
    .get(GET_DOCUMENT_TYPES, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getComplianceDocuments = async (
  companyId: number,
  financeType?: string
) => {
  const params = new URLSearchParams();
  if (financeType) {
    params.append("finance_type", financeType);
  }

  return await indexAPI
    .get(
      `${GET_COMPLIANCE_DOCUMENTS}/${NotificationType.DOCUMENT}/${Events.GENERATE_COMPLIANCE_DOCUMENTS}`,
      {
        headers: {
          company_id: companyId,
        },
        params,
      }
    )
    .then((res) => res.data);
};

export const getAllDealers = async (companyId: number) => {
  return await indexAPI
    .get(GET_ACTIVE_DEALERS, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getAllLenders = async (companyId: number) => {
  return await indexAPI
    .get(GET_LENDERS, {
      headers: {
        company_id: companyId,
      },
    })
    .then((res) => res.data);
};

export const getDealerProfile = async (dealerCode: string) => {
  return await indexAPI
    .get(GET_DEALER_PROFILE.replace(":dealer_code", dealerCode))
    .then((res) => res.data);
};

export const saveFinanceConfigurations = async (body: SaveFinanceConfigReq) => {
  const { financeType, ...restPayload } = body;
  return await indexAPI.post(`${SAVE_CONFIGURATION}`, {
    finance_type: financeType,
    config: JSON.stringify(restPayload),
  });
};
