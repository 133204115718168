import Http from "shared/helper/http-api";
import { ConfigurationAPI } from "services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { useStoreContext } from "Stores/SetupStore/Store";
import { IStationeryFilter, IStationery } from "Interface";

export const UseQuery_getAllStationeryFilter = (columnFilters: string) => {
  return useQuery<IStationeryFilter>(
    [QueryKeys.GET_ALL_STATIONERY_RECORDS, columnFilters],
    async () => {
      return ConfigurationAPI.get(
        `/stationery/filter/?`.concat(columnFilters)
      ).then((response) => {
        return response.data;
      });
    },
    { enabled: !!columnFilters, keepPreviousData: true }
  );
};

export const useMutation_getTenantDocuments = () => {
  return useMutation((dealer_code?: string) => {
    let query = `/stationery/get_documents/`
    if (dealer_code) {
      query += `?dealer_code=${dealer_code}`
    }
    return ConfigurationAPI.get(query);
  });
};

export const UseMutation_AddStationery = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStationery>(
    (stationery) => {
      return ConfigurationAPI.post("/stationery", stationery);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError: (error: any) => {
        actions.setToast({
          toastMessage:
            error?.response?.data?.detail?.message ?? "Internal Server Error",
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_UpdateStationery = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  return useMutation<IStationery>(
    (stationery: any) => {
      return ConfigurationAPI.patch(`/stationery/${stationery.id}`, stationery);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_DeleteStationery = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  return useMutation<IStationery>(
    (stationeryId) => {
      return ConfigurationAPI.delete(`/stationery/${stationeryId}`);
    },
    {
      onSuccess: async (response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_STATIONERY_RECORDS);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError: async (error: any) => {
        actions.setToast({
          toastMessage: error?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
};

export const UseMutation_GetStationeryById = (): any => {
  return useMutation<IStationery>((id) => {
    return ConfigurationAPI.get(`/stationery/${id}`);
  });
};

export const useGetStationeryByTypeAndEvent = (): any => {
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`stationery/type/${body.type}/${body.event}`);
    },
    {
      onSuccess: () => { },
    }
  );
  return { data, isLoading, error, mutate };
};
