import { Step } from "@src/components/atoms/step/step.component";
import { RadioSelector, PersonalDetails } from "@src/components/molecules";

import "./vehicle-ownership.style.scss";

export const VehicleOwnership = ({
  stepOneDataReceivedEvent,
}: {
  stepOneDataReceivedEvent: () => void;
}) => {
  return (
    <div className="who-i-am">
      <div className="who-i-am section-wrapper">
        <Step />
        <RadioSelector />
      </div>
      <PersonalDetails stepOneDataReceivedEvent={stepOneDataReceivedEvent} />
    </div>
  );
};
