import { FC, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, PersistentDrawer } from "@ntpkunity/controls";
import { DataTableWrap } from "@sharedComponents";
import { useQueryClient } from "react-query";
import DealerFeeDrawer from "./dealer-fee-drawer";
import DealerFeesTable from "./dealer-fee-table";
import { useForm } from "react-hook-form";
import {
  useSaveDealerFee,
  useUpdateDealerFeeById,
} from "services/dealer-configurations.service";
import { useStoreContext } from "Stores/SetupStore/Store";
import { ToastMessages } from "Enums";
import { QueryKeys } from "Enums";

import { IDealerFee, IStates } from "Interface";
import { ReactQueryDevtools } from "react-query/types/devtools";

interface IFormInputs {
  id?: number;
  fee_name: string;
  states: number[];
  applicable_finance_type: string[];
  default_amount: number;
  taxable: boolean;
  fee_handling_override: boolean;
  is_active: boolean;
  dealer_code: string;
  event: string;
  calculation_type: string;
  multiplication_factor: number;
}
const DealerFees: FC<{
  form: any;
  onPopupStateChange: (popUpState: any) => unknown;
  dealer_code: string;
}> = ({ onPopupStateChange, dealer_code }) => {
  const [popUpState, setPopUpState] = useState(false);
  const [actionTitle, setActionTitle] = useState<string>("");
  const [formData, setFormData] = useState<IFormInputs>();
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  onPopupStateChange(popUpState);

  const handleFormSubmit = () => {
    if (!isError && !isDisable) {
      form.handleSubmit(onSubmit)();
    }
  };

  const onSubmit = (formValues: IFormInputs): void => {
    setFormData(formValues);
    saveFormData(formValues);
  };

  const handleshow = () => {
    setFormDefaultData({} as IFormInputs);
    form.reset({} as IFormInputs);
    setActionTitle("Add Dealer Fees");
    setPopUpState(true);
    setIsEditMode(false);
  };

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };

  const setFieldErrors = (errors) => {
    if (errors.fee_name) {
      form.setError("fee_name", { message: errors.fee_name });
    }
    if (errors.states) {
      form.setError("states", { message: errors.states });
    }
    if (errors.applicable_finance_type) {
      form.setError("applicable_finance_type", {
        message: errors.applicable_finance_type,
      });
    }
    setIsError(true);
  };
  const form: {
    handleSubmit;
    control;
    setValue;
    reset;
    getValues;
    resetField;
    watch;
    setError;
    clearErrors;
    formState: { errors; isDirty };
  } = useForm<IFormInputs>();

  const theme = useTheme();
  const { actions } = useStoreContext();

  const { mutate: SaveDealeFee } = useSaveDealerFee();
  const { mutate: updateDealerFee } = useUpdateDealerFeeById();
  const [stateNames, setStateNames] = useState<string[]>([]);

  const defaultValues: IFormInputs = useMemo(() => {
    return formDefaultData;
  }, [formDefaultData]) as unknown as IFormInputs;

  const onEdit = (formData: IFormInputs) => {
    setFormDefaultData(formData);
    setActionTitle("Edit Dealer Fees");
    setIsEditMode(true);
    setPopUpState(true);
  };
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false);
    }
  }, [popUpState]);

  const saveFormData = async (data: any) => {
    if (data) {
      const multiplication_factor =
        data?.calculation_type === "Fixed amount"
          ? null
          : data?.multiplication_factor || null;

      if (isEditMode) {
        setIsDisable(true);
        updateDealerFee(
          {
            ...data,
            multiplication_factor,
            dealer_code: dealer_code,
          },
          {
            onSuccess(response: IDealerFee) {
              form.reset();
              onClose();
              setIsDisable(false);
            },
            onError(error: any) {
              if (
                error?.error?.response?.data?.detail?.message ===
                "Duplicate record found."
              ) {
                const errors =
                  error?.error?.response?.data?.detail?.errors || {};
                setFieldErrors(errors);
              }
              if (error?.error?.response?.status != 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              }
              setIsDisable(false)
            },
          } 
        );
      } else {
        setIsDisable(true);
        SaveDealeFee(
          {
            ...data,
            multiplication_factor,
            dealer_code: dealer_code,
            default_amount: +data.default_amount,
          },
          {
            onSuccess(response: IDealerFee) {
              actions.addDealerFeeRow({
                ...response,
                state_name: stateNames.join(","),
              });
              actions.setToast({
                toastMessage: ToastMessages.Record_Added_Success,
                toastState: true,
              });
              form.setValue("fee_name", null);
              form.reset();
              onClose();
              setIsDisable(false);
            },
            onError: async (error: any) => {
              if (
                error?.error?.response?.data?.detail?.message ===
                "Duplicate record found."
              ) {
                const errors =
                  error?.error?.response?.data?.detail?.errors || {};
                setFieldErrors(errors);
              }
              if (error?.error?.response?.status != 400) {
                actions.setToast({
                  toastMessage: error?.error?.response?.data?.detail?.message,
                  toastState: true,
                  variant: "error",
                });
              }
              setIsDisable(false)
            },
          }
        );
      }
    }
  };
  return (
    <>
      <DataTableWrap theme={theme} className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DealerFeesTable
          onEdit={(data) => onEdit(data)}
          dealer_code={dealer_code}
        />
        <Button
          theme={theme}
          secondary
          fullWidth
          text={"Add New Fees"}
          onClick={handleshow}
        />
      </DataTableWrap>
      {popUpState && (
        <PersistentDrawer
          title={actionTitle}
          openPopUp={popUpState}
          setPopUpState={setPopUpState}
          enabledPin={true}
          theme={theme}
          children={
            <>
              <form onSubmit={(e) => e.preventDefault()}>
                <DealerFeeDrawer
                  form={form}
                  setStateNames={setStateNames}
                  formData={formData}
                  isEditMode={isEditMode}
                  setIsError={setIsError}
                  dealer_code={dealer_code}
                ></DealerFeeDrawer>
              </form>
            </>
          }
          customFooter={
            <Button
              theme={theme}
              primary
              type="submit"
              text="Save"
              fullWidth
              disabled={isDisable}
              onClick={handleFormSubmit}
            />
          }
        />
      )}
      {/* {popUpState && ( */}

      {/* )} */}
      {/* <DealerFeeDrawer></DealerFeeDrawer> */}
    </>
  );
};

export default DealerFees;