import { FC, useState, useEffect } from 'react'
import { Icon, Box, DataTable, Input, Button, Menu, Checkbox } from '@ntpkunity/controls'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DataTableWrap } from '@components'
import FIPriceDialog from './products-rate-dialog'
import FAndISupplier from './../finance-insurance-supplier/finance-insurance-supplier'
import { CoverageResponse } from './../../FinanceInsuranceControl/finance-insurance-types'
import ChevronDown from '@public/assets/icons/chevron-down'

const FiProductsTable: FC<{
  isMSP?: boolean
  setSelectedLeaseItems?: any
  setSelectedFinanceItems?: any
  selectedFinanceItems?: any
  selectedLeaseItems?: any
  financeRates?: any
  leaseRates?: any
  defaultCurrency?: any
  terms?: any
  data?: any
  activeTab: 0 | 1
  setColumnFilters?: any
  filters?: any
  setFniPrice?: any
  fniPrice?: any
  addedItems?: any
  setAddedItems?: any
  paginationData?: any
  setFilters?: any
}> = ({
  financeRates,
  leaseRates,
  selectedFinanceItems,
  setSelectedLeaseItems,
  setSelectedFinanceItems,
  selectedLeaseItems,
  defaultCurrency,
  data,
  setColumnFilters,
  filters,
  activeTab,
  setFniPrice,
  addedItems,
  setAddedItems,
  setFilters,
  paginationData
}) => {
  const theme = useTheme()
  const [rates, setRates] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)
  const [cardDetails, setCardDetails] = useState()
  const [pageSize, setPageSize] = useState<number>(5)
  const [_pageNumber, setPageNumber] = useState<number>(0)
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [minimunDedatablePrice, setMinimunDedatablePrice] = useState<number>()
  const isAdded = (id: any) => addedItems?.includes(id)

  useEffect(() => {
    if (activeTab == 0 && financeRates) {
      setRates(financeRates)
    } else if (activeTab == 1 && leaseRates) {
      setRates(leaseRates)
    }
  }, [activeTab, financeRates, leaseRates])

  const removeItem = (data: any) => {
    if (activeTab == 0) {
      setAddedItems(addedItems.filter((id: any) => id !== data?.id))
      setSelectedFinanceItems(selectedFinanceItems.filter((item: any) => item?.id !== data?.id))
      setFniPrice((prevState: any) => ({
        ...prevState,
        finance: prevState?.finance?.filter((item: any) => item?.id !== data?.id)
      }))
    } else {
      setAddedItems(addedItems.filter((id: any) => id !== data?.id))
      setSelectedLeaseItems(selectedLeaseItems.filter((item: any) => item?.id !== data?.id))
      setFniPrice((prevState: any) => ({
        ...prevState,
        lease: prevState?.lease?.filter((item: any) => item?.id !== data?.id)
      }))
    }
  }

  const calculateSum = (obj: any) => {
    if (activeTab == 0 && !selectedFinanceItems.some((x: any) => x.id == obj?.id)) {
      setAddedItems([...addedItems, obj?.id])
      setSelectedFinanceItems([
        ...selectedFinanceItems,
        {
          ...obj,
          price: obj?.price,
          rate_id: obj?.rate_id,
          form_id: obj?.form_id
        }
      ])
    } else if (activeTab == 1 && !selectedLeaseItems.some((x: any) => x.id == obj?.id)) {
      setAddedItems([...addedItems, obj?.id])
      setSelectedLeaseItems([
        ...selectedLeaseItems,
        {
          ...obj,
          price: obj?.price,
          rate_id: obj?.rate_id,
          form_id: obj?.form_id
        }
      ])
    }
  }

  const getCoverageData = (rates: CoverageResponse[], details: any) => {
    const rateArray = Array.isArray(rates) ? rates : rates ? [rates] : []
    if (rateArray && rateArray?.length > 0) {
      const selectedProduct = rateArray?.find(
        (product: any) => product?.product_id === details?.product_id
      )
      if (Array.isArray(selectedProduct?.coverages?.coverage)) {
        const coverageObj = selectedProduct?.coverages?.coverage.find(
          (coverage: any) => coverage?.coverage_name === details?.coverage_name
        )
        return coverageObj?.deductibles?.deductible?.retail_price
      } else {
        return selectedProduct?.coverages?.coverage?.deductibles?.deductible?.retail_price
      }
    }
  }
  const handleCheckboxChange = (product: any) => {
    if (isAdded(product?.id)) {
      removeItem(product)
    } else {
      calculateSum(product)
    }
  }
  const getCoverage = (item: any) => {
    if (item) {
      const termMonths = item?.term_months
      const termMiles = item?.term_miles
      const termMonthsWithLabel = termMonths ? `${termMonths} Months, ` : ''
      const termMilesWithLabel = termMiles
        ? `${termMiles?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })} Miles`
        : ''
      return { termMonths: termMonthsWithLabel, termMiles: termMilesWithLabel }
    }
    return {}
  }

  const handleProductDetailsView = (cardData: any) => {
    setOpenProductDialog(true)
    setCardDetails(cardData)
  }

  const handleProductEditView = (cardData: any) => {
    const getDeductiblePrice = getCoverageData(rates, cardData)
    setMinimunDedatablePrice(getDeductiblePrice)
    setIsOpen(true)
    setCardDetails(cardData)
  }

  const handleCloseProductDetails = () => {
    setOpenProductDialog(false)
  }

  const selectedAllFnis =
    (data?.length > 0 && data?.every((item: any) => addedItems?.includes(item?.id))) || false
  const someFnisSelected =
    data?.length > 0 &&
    data?.some((item: any) => addedItems?.includes(item?.id)) &&
    !selectedAllFnis
  const handleSelectAll = () => {
    const nonPreInstalledIds = data.map((item: any) => item?.id)
    if (selectedAllFnis) {
      if (activeTab === 0) {
        setSelectedFinanceItems([])
        setAddedItems([])
      } else {
        setSelectedLeaseItems([])
        setAddedItems([])
      }
    } else {
      if (activeTab === 0) {
        setSelectedFinanceItems((prevItems: any) => [...prevItems, ...data])
        setAddedItems((prevItems: any) => [...prevItems, ...nonPreInstalledIds])
      } else {
        setSelectedLeaseItems((prevItems: any) => [...prevItems, ...data])
        setAddedItems((prevItems: any) => [...prevItems, ...nonPreInstalledIds])
      }
    }
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }

  const setQueryString = (pgNo: number, pgSize: number): any => {
    let query_string: string = `&page_number=${pgNo}&page_size=${pgSize}`
    if (
      filters?.productNameFilter?.trimStart() != null &&
      filters?.productNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&product_name=${filters?.productNameFilter}`)
    }

    if (
      filters?.categoryFilter?.trimStart() != null &&
      filters?.categoryFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&category=${filters?.categoryFilter}`)
    }

    if (
      filters?.providerFilter?.trimStart() != null &&
      filters?.providerFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&provider_name=${filters?.providerFilter}`)
    }

    if (
      filters?.productTypeFilter?.trimStart() != null &&
      filters?.productTypeFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&product_type=${filters?.productTypeFilter}`)
    }

    if (filters?.priceFilter?.trimStart() != null && filters?.priceFilter?.trimStart() != '') {
      query_string = query_string.concat(`&price=${filters?.priceFilter}`)
    }

    if (
      filters?.coverageFilter?.trimStart() != null &&
      filters?.coverageFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&provider_name=${filters?.coverageFilter}`)
    }

    return query_string
  }

  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
  }

  const editPopupCallback = (coverage: any) => {
    setSelectedLeaseItems(
      selectedLeaseItems?.map((leaseItem: any) => {
        if (leaseItem.product_id === coverage?.product_id) {
          return {
            ...leaseItem,
            coverage_name: coverage?.coverage_name,
            price: coverage?.price
          }
        }

        return leaseItem
      })
    )

    setSelectedFinanceItems(
      selectedFinanceItems?.map((financeItem: any) => {
        if (financeItem.product_id === coverage?.product_id) {
          return {
            ...financeItem,
            coverage_name: coverage?.coverage_name,
            price: coverage?.price
          }
        }

        return financeItem
      })
    )
  }

  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination table-dialog" mt={2}>
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="checkbox-cell fixed-cell">
                  <Checkbox
                    label=""
                    theme={theme}
                    onChange={handleSelectAll}
                    checkBoxChecked={selectedAllFnis}
                    checkBoxIndeterminate={someFnisSelected}
                  />
                </TableCell>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell id="productName">Product Name</TableCell>
                <TableCell id="category">Category</TableCell>
                <TableCell id="provider">Provider</TableCell>
                <TableCell id="productType">Product Type</TableCell>
                <TableCell id="price">Price</TableCell>
                <TableCell id="coverage">Coverage</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell fixed-cell"></TableCell>
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'productName'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, productNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.productNameFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="productNameFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'category'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, categoryFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.categoryFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'provider'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, providerFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.providerFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="providerFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'productType'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, productTypeFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.productTypeFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="productTypeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'price'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, priceFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.priceFilter?.trimStart()}
                      disabled
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="priceFilter"
                      disabled
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'coverage'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, coverageFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      // value={filters?.coverageFilter?.trimStart()}
                      disabled
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="coverageFilter"
                      iconText={<Icon name="IconFilter" />}
                      disabled
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data.map((item: any, index: number) => (
                <TableRow className="child-tr" key={index}>
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox
                      label=""
                      theme={theme}
                      checkBoxChecked={isAdded(item?.id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </TableCell>
                  <TableCell className="img-cell">
                    <img
                      className="prd-img"
                      src={
                        item?.image?.[0]?.location ??
                        require('../../../../src/public/assets/images/no-img.svg')
                      }
                      alt="Car"
                    />
                  </TableCell>
                  <TableCell>{item?.product_name}</TableCell>
                  <TableCell>{item?.category}</TableCell>
                  <TableCell>{item?.provider_name}</TableCell>
                  <TableCell>{item?.product_type}</TableCell>
                  <TableCell className="editable-cell text-right">
                    <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                      <span>
                        {item?.price && !isNaN(item?.price)
                          ? `${defaultCurrency}${item?.price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}`
                          : '-'}
                      </span>
                      {item?.price && (
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={
                            <Icon name="EditIcon" onClick={() => handleProductEditView(item)} />
                          }
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item && getCoverage(item)?.termMonths} {item && getCoverage(item)?.termMiles}
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: 'View Details',
                          optionValue: 'view_details'
                        },
                        {
                          optionText: 'Edit Coverage',
                          optionValue: 'editCoverage'
                        }
                      ]}
                      handleOptionClick={(_event, _key, value) => {
                        switch (value) {
                          case 'view_details':
                            handleProductDetailsView(item)
                            break
                          case 'editCoverage':
                            handleProductEditView(item)
                            break
                          default:
                            break
                        }
                      }}
                      render={(onMenuSelection: any) => (
                        <Button
                          theme={theme}
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />
        {paginationData && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            id="pagination"
            count={paginationData?.total_results ?? -1}
            page={paginationData?.page_number}
            rowsPerPage={paginationData?.page_size}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown
            }}
          />
        )}
      </DataTableWrap>
      {openProductDialog ? (
        <FAndISupplier
          details={cardDetails}
          open={openProductDialog}
          onBackBtn={handleCloseProductDetails}
          defaultCurrency={defaultCurrency}
        />
      ) : (
        ''
      )}
      {isOpen ? (
        <FIPriceDialog
          details={cardDetails}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          rates={rates}
          selectDataObj={data}
          minimunDedatablePrice={minimunDedatablePrice}
          defaultCurrency={defaultCurrency}
          editPopupCallback={editPopupCallback}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default FiProductsTable
