import { Input, Select } from "@src/components/atoms";
import "./address.scss";

export const Address = () => {
  return (
    <div className="checkout-initial-form-container">
      <div className="checkout-form-spacing">
        <form className="checkout-form">
          <div className="checkout-form-spacing">
            <div>
              <Input
                id={"checkout-form--streetAddress"}
                name={"checkout-form--streetAddress"}
                placeholder="Street address"
                label="Address 1"
              />
            </div>
          </div>
          <div>
            <Input
              id={"checkout-form--streetAddress2"}
              name={"checkout-form--streetAddress2"}
              placeholder="Street address"
              label="Address 2 (optional)"
            />
          </div>
          <div className="checkout-column-wrapper">
            <div>
              <Input
                id={"checkout-form--city"}
                name={"checkout-form--city"}
                placeholder="City"
                label="City"
              />
            </div>
            <div>
              <Select
                label={"State"}
                options={[
                  {
                    label: "Select",
                    value: "option-1",
                  },
                  {
                    label: "Option 2",
                    value: "option-2",
                  },
                ]}
                value={"option-1"}
              />
            </div>
            <div>
              <Input
                id={"checkout-form--county"}
                name={"checkout-form--county"}
                placeholder="County"
                label="County (optional)"
              />
            </div>
            <div>
              <Input
                id={"checkout-form--zipcode"}
                name={"checkout-form--zipcode"}
                placeholder="ZIP Code"
                label="ZIP Code"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
