import styled from "@emotion/styled";
import { GLOBAL_THEME as Theme } from "@styles/theme";
import errorIcon from "../../../shared/assets/icons/input-icons/error.svg";

export const DatepickerWrap = styled("div")({
  "&.date-picker-wrap": {
    ".input-label": {
      marginBottom: 8,
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".input-label-icon": {
        "&:before": {
          fontFamily: "bmw_next_icons",
          display: "block",
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: 1,
          textTransform: "none",
          wordWrap: "normal",
          whiteSpace: "nowrap",
          direction: "ltr",
          textAlign: "left",
          speak: "none",
          height: "1em",
          outline: "1px solid transparent",
          "-webkit-font-smoothing": "antialiased",
          textRendering: "optimizeLegibility",
          "-moz-osx-font-smoothing": "grayscale",
          fontFeatureSettings: "liga",
          content: "attr(data-icon)",
          width: "auto",
          fontSize: 16,
        },
      },
    },
    ".ant-picker": {
      width: "100%",
      borderRadius: 3,
      height: 52,
      padding: "13px 20px",
      backgroundColor: Theme.components?.Input?.activeBg,
      borderColor: Theme.components?.Input?.colorBorder,
      color: Theme.token?.colorPrimary,
      ".ant-picker-input": {
        input: {
          color: Theme.token?.colorTextBase,
          "&::placeholder": {
            color: Theme.components?.Input?.colorTextPlaceholder + "!important",
            fontWeight: 300,
          },
        },
        ".ant-picker-suffix": {
          marginLeft: 8,
        },
      },
      "&.ant-picker-disabled": {
        "&.ant-picker-focused, &:hover": {
          boxShadow: "none",
        },
        backgroundColor: Theme.token?.colorTextLightSolid,
        ".ant-picker-suffix": {
          svg: {
            path: {
              fill: Theme.token?.colorBorder,
            },
          },
        },
      },
      "&.ant-picker-focused, &:hover": {
        boxShadow: Theme.components?.Input?.activeShadow,
      },
      "&.input--input-error": {
        backgroundColor: Theme.token?.colorErrorBg,
        borderColor: Theme.token?.colorError,
        backgroundImage: `url(${errorIcon})`,
        backgroundPosition: "calc(100% - 48px)",
        backgroundRepeat: "no-repeat",
        backgroundSize: 20,
        ".ant-picker-input": {
          input: {
            color: Theme.token?.colorError,
            paddingInlineEnd: 28,
            "&::placeholder": {
              color: Theme.token?.colorError + "!important",
            },
          },
        },
        "&.ant-picker-focused, &:hover": {
          boxShadow: Theme.components?.Input?.colorErrorBorderHover,
        },
      },
    },
    ".ant-picker-dropdown": {
      ".ant-picker-panel-container": {
        borderRadius: "0 0 3px 3px",
        boxShadow:
          "0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
        fontFamily: Theme.token?.fontFamily,
        ".ant-picker-panel-layout": {
          ".ant-picker-panel": {
            ".ant-picker-date-panel": {
              minWidth: 370,
              ".ant-picker-header": {
                borderBottom: "none",
                padding: "20px 20px 8px 20px",
                gap: 8,
                ".ant-picker-header-view": {
                  display: "flex",
                  flex: 1,
                  order: 1,
                  button: {
                    fontWeight: 300,
                    fontSize: "1rem",
                    fontFamily: Theme.token?.fontFamily,
                    lineHeight: "26px",
                  },
                },
                ".ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn":
                  {
                    display: "none",
                  },
                ".ant-picker-header-next-btn, .ant-picker-header-prev-btn": {
                  width: 32,
                  height: 32,
                  padding: 5.33,
                },
                ".ant-picker-header-prev-btn": {
                  order: 2,
                },
                ".ant-picker-header-next-btn": {
                  order: 3,
                },
              },
              ".ant-picker-body": {
                ".ant-picker-content": {
                  tbody: {
                    tr: {
                      td: {
                        "&.ant-picker-cell": {
                          ".ant-picker-cell-inner": {
                            width: 42,
                            height: 42,
                            display: "flex",
                            padding: 14,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "1rem",
                            "&:hover": {
                              backgroundColor: Theme.token?.colorTextLightSolid,
                            },
                          },

                          "&.ant-picker-cell-today": {
                            ".ant-picker-cell-inner": {
                              "&:before": {
                                width: 4,
                                height: 4,
                                backgroundColor: Theme.token?.colorText,
                                border: "none",
                                top: "auto",
                                bottom: 8,
                                left: 19,
                              },
                            },
                          },
                          "&.ant-picker-cell-selected": {
                            ".ant-picker-cell-inner": {
                              backgroundColor: Theme.token?.colorText,
                            },
                          },
                        },
                      },
                    },
                  },
                  thead: {
                    tr: {
                      th: {
                        fontWeight: "300",
                        fontSize: 12,
                      },
                    },
                  },
                },
              },
            },
          },
          ".ant-picker-footer": {
            display: "none" + "!important",
          },
        },
      },
    },
  },
});
