import { Button } from "@src/components/atoms";
import "./current-and-past-addresses-completed.style.scss";

export const CurrentAndPastAddressesCompleted = ({
  changeStateOfEditModeHandler,
}: {
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  return (
    <div className="checkout-step-info">
      <div className="checkout-success-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title checkout-step text--subsection-2">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <Button
          htmlType={"button"}
          text="Edit"
          onClick={() => changeStateOfEditModeHandler("step-two")}
          size="small"
        />
      </div>
      <div className="checkout-success-info">
        <div className="checkout-success-info-container">
          <div className="checkout-success-info-section">
            <p className="checkout-success-title text--disclaimer">
              Current address
            </p>
            <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
              610 Varick Street
            </p>
            <p className="checkout-success-subtitle text--body-2 checkout-success-subtitle--garaging">
              New York, NY 10454, United States
            </p>
          </div>
          <p className="checkout-success-title text--disclaimer">
            You lived here for
          </p>
          <p className="checkout-success-subtitle text--body-2">2 years</p>
        </div>
        <div className="checkout-success-info-block" />
        <div className="checkout-billing-mailing-container">
          <div>
            <div className="checkout-success-billing-section">
              <p className="checkout-success-title text--disclaimer">
                Billing address
              </p>
              <p className="checkout-success-subtitle text--body-2">
                Same as current address
              </p>
            </div>
          </div>
          <div>
            <p className="checkout-success-title text--disclaimer">
              Garaging address
            </p>
            <p className="checkout-success-subtitle text--body-2">
              Same as current address
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
