import { useReducer } from "react";

import {
  Tooltip,
  Checkbox,
  Input,
  Button,
  Select,
} from "@src/components/atoms";
import { Accordion } from "../../utils/accordion/accordion";
import { Address } from "../address/address";

import "./current-and-past-addresses.scss";
import { initialState, reducer } from "./current-and-past-addresses.state";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";

export const CurrentAndPastAddresses = ({
  stepTwoDataReceivedEvent,
}: {
  stepTwoDataReceivedEvent: () => void;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="checkout">
      <div>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <div>
          <div className="checkout-info-wrapper">
            <p className="checkout-subtitle text--body-2">
              Please provide your current permanent address.
              {/* <i className="checkout-info-icon" />
              <Tooltip /> */}
            </p>
            <p className="checkout-info text--disclaimer">
              <i className="checkout-title-icon" />
              If you've lived at this address for less than 11 months, you'll
              need to enter your previous address as well.
            </p>
          </div>
        </div>
      </div>
      <div className="checkout-initial-form-container">
        <form className="checkout-form">
          <div className="checkout-form-spacing">
            <div>
              <Input
                id={"checkout-form--streetAddress"}
                name={"checkout-form--streetAddress"}
                placeholder="Street address"
                label="Address 1"
              />
            </div>
          </div>
          <div>
            <Input
              id={"checkout-form--streetAddress2"}
              name={"checkout-form--streetAddress2"}
              placeholder="Street address"
              label="Address 2 (optional)"
            />
          </div>
          <div className="checkout-column-wrapper">
            <div>
              <Input
                id={"checkout-form--city"}
                name={"checkout-form--city"}
                placeholder="City"
                label="City"
              />
            </div>
            <div>
              <Select
                label={"State"}
                options={[
                  {
                    label: "Select",
                    value: "option-1",
                  },
                  {
                    label: "Option 2",
                    value: "option-2",
                  },
                ]}
                value={"option-1"}
              />
            </div>
            <div>
              <Input
                id={"checkout-form--county"}
                name={"checkout-form--county"}
                placeholder="County"
                label="County (optional)"
              />
            </div>
            <div>
              <Input
                id={"checkout-form--zipcode"}
                name={"checkout-form--zipcode"}
                placeholder="ZIP Code"
                label="ZIP Code"
              />
            </div>
            <div>
              <Datepicker
                id={"date_of_birth"}
                name={"date_of_birth"}
                placeholder="Choose"
                label="Date of birth"
                format="MMM DD, YYYY"
              />
            </div>
          </div>
          <hr className="checkout-hr" />
        </form>
      </div>

      {/*<MoveDateAddress />*/}

      {state.billingAddress && (
        <>
          <div>
            <Checkbox
              id={"provdeBillingChk"}
              label="Please provide your billing address."
              htmlFor="provdeBillingChk"
              onChangeHandler={() => {}}
            />
          </div>
          {!state.isBillingAddressSame && <Address />}
        </>
      )}

      {state.billingAddress && state.mailingAddress && (
        <hr className="checkout-hr" />
      )}

      {state.mailingAddress && (
        <>
          <div>
            <Checkbox
              id={"provdeMailingChk"}
              label="Please provide your mailing address."
              htmlFor="provdeMailingChk"
              onChangeHandler={() => {}}
            />
          </div>
          {!state.isMailingAddressSame && <Address />}
        </>
      )}

      <p className="text--body-2">
        If your garaging or billing address is different from your permanent
        address, please provide them below.
        <i className="checkout-info-icon" /> <Tooltip />{" "}
      </p>

      <div className="checkout-accordion-container">
        <Accordion
          addBillingAddressHandler={() => {
            dispatch({ type: "toggle-billing-address-state" });
          }}
          addMailingAddressHandler={() => {
            dispatch({ type: "toggle-mailing-address-state" });
          }}
        />
      </div>
      <div className="form-wrapper form-wrapper--button-top-spacer">
        <Button
          type="default"
          htmlType={"button"}
          text="Save & continue"
          onClick={stepTwoDataReceivedEvent}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
