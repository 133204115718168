import React, { useState } from "react";
import { Table } from "../../controls/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { getReport } from "../../../helpers/ReportingHelper";
import { exportInWordUrl,exportDetail } from "../../../common/constants";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { CheckDockSubscribe } from "../../../common/enums";

interface IRepaymentPlanDetail {
  rows: [];
}


export const RepaymentPlanResponse: React.FC<IRepaymentPlanDetail> = ({
  rows,
}: IRepaymentPlanDetail) => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const [enableExport, setEnableExport] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const GenerateReport = () => {
    var requestObject = {
      template_type: "quote_repayment",
      data_placeholders:{
        financeAmount: storeState.financedAmount,
        currency: "EUR",
        interestRate: storeState.APR == "" ? 0 : storeState.APR,
        Planitems: [],
        },
      is_bookmark: false,
      generate_base64: false,
      tags: {}
      }
      storeState.repaymentPlanApiResponse.repaymentPlan.map((rental: any) => {
        requestObject.data_placeholders.Planitems.push({
        rentalNumber: rental.rentalNumber,
        openingPrincipal: rental.openingPrincipal,
        rentalAmount: rental.rentalAmount,
        rentalPrincipal: rental.rentalPrincipal,
        rentalInterest: rental.rentalInterest,
        rentalDueDate: rental.rentalDueDate,
        periodicInterest: rental.periodicInterest,
        closingPrincipal: rental.closingPrincipal,
      });
    })  
    setEnableExport(false);
    getReport(exportInWordUrl, requestObject, "Repayment_plan")
      .then(() => {
        setEnableExport(true);
      })
      .catch((ex) => {
        if (ex?.response?.data?.detail == exportDetail)
          setErrorDialog({
            visible: true,
            error: CheckDockSubscribe.NoTemplatefound,
          });
        else {
          setErrorDialog({
            visible: true,
            error: CheckDockSubscribe.PleaseSubscribe,
          });
        }
        setEnableExport(true);
      });
  };
  const columns = [] as any;
  columns.push("Rental #");
  columns.push("Rental DueDate");
  columns.push("Opening Principal");
  columns.push("Rental Amount");
  columns.push("Principal");
  columns.push("Interest");
  columns.push("Periodic Interest");
  columns.push("Closing Principal");
  if (rows.length > 0) {
    return (
      <>
        <MessageDialog
          visible={errorDialog.visible}
          message={errorDialog.error}
          onClose={() => {
            setErrorDialog({
              visible: false,
              error: errorDialog.error,
            });
          }}
        />
      <Table
        columns={columns}
        rows={rows}
        showExport
        fileName={"Repayment Plan"}
        externalExport
        externalExportFunc={GenerateReport}
        enableExport={enableExport}
      />
      </>
      );
  } else {
    return <div></div>;
  }
};
