import "./insurance-information.scss";
import { Col, Row } from "antd";
import { Input } from "../../atoms";
import { Phone } from "../../atoms/phone-input/phone-input.component";

export const InsuranceInfo = () => {
  return (
    <div className="insurance-information">
      <div className="insurance-information-info-header">
        <div className="checkout-form-header">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">
            Insurance information.
          </h3>
        </div>
      </div>
      <div className="insurance-information-title-container">
        <span className="insurance-information-description text--body-2">
          State and federal regulations require all drivers to be insured.
        </span>
        <span className="insurance-information-description--link text--body-2">
          I don’t have insurance
        </span>
      </div>
      <div className="insurance-information-input-container">
        <div className="insurance-information-input-wrapper">
          <Row gutter={[16, { xs: 20, sm: 20, md: 20 }]}>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Input
                id={"idInsuranceprovider"}
                name={""}
                placeholder="Insurance provider"
                label="Insurance provider's name"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={24} xl={12}>
              <Phone country="us" label="Insurance contact number" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
