import { useQuery } from "react-query";
import { queryKeyFactory } from "@helpers/query";
import { getComplianceDocuments } from "@services/index.service";
import { ComplianceDocumentSetupRes } from "@_types/setups";
import { useGetCompanyByTenantId } from "@hooks/queries";

const useGetComplianceDocuments = (financeType?: string) => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<
    ComplianceDocumentSetupRes,
    unknown,
    ComplianceDocumentSetupRes
  >({
    queryKey: queryKeyFactory.getComplianceDocuments(companyId, financeType),
    queryFn: () => getComplianceDocuments(companyId, financeType),
    enabled: !!companyId,
  });
};

export default useGetComplianceDocuments;
