import { Button } from "@src/components/atoms";
import "./vehicle-card.style.scss";
import Image_URL from "../../../shared/assets/img/vehicle-large.png";
import FinanceImage from "../../../shared/assets/img/finance.png";

export const VehicleCard = ({
  hasVehicleFinancing,
}: {
  hasVehicleFinancing?: boolean;
}) => {
  return (
    <div className="vehicle-card">
      <div className="vehicle-card-header text--body-2">
        <span className="vehicle-card-reservation-title">
          Vehicle reserved for
        </span>
        <span className="vehicle-card-reservation-time">00:29:46</span>
      </div>
      <div className="vehicle-card-inner-container">
        <h4 className="vehicle-card-title text--subsection-1">
          2024 330i xDrive Sedan
        </h4>
        <div className="vehicle-subtitle-container">
          <div className="vehicle-card-state-container">
            <span className="vehicle-card-state text--disclaimer">
              At Dealership
            </span>
          </div>
          <span className="vehicle-card-vin text--disclaimer">
            VIN#: 3MW89FF00P8C84861
          </span>
        </div>
        <img className="vehicle-card-image" src={Image_URL} alt="Vehicle" />
        <img
          className="vehicle-card-loyalty-image"
          src={FinanceImage}
          alt="Loyalty Card"
        />
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-financing-title text--body-1 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
            Est. Financing
          </span>
          <span className="vehicle-card-financing-value text--body-focus-price-1">
            $585/month
          </span>
        </div>
        {hasVehicleFinancing && (
          <div className="vehicle-card-payment-info-row">
            <span className="vehicle-card-financing-title" />
            <div className="vehicle-card-financing-container">
              <span className="vehicle-card-payment-value text--body-focus-price vehicle-card-up-arrow bmw-icon bmw-icon--before">
                $10
              </span>
              <span className="vehicle-card-payment-value vehicle-card-offer-text text--disclaimer">
                /month
              </span>
            </div>
          </div>
        )}
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-payment-title text--body-2 vehicle-card-info vehicle-card-info-icon bmw-icon bmw-icon--after">
            MSRP
          </span>
          <span className="text--body-focus-price">$48,670</span>
        </div>
        <hr className="vehicle-card-separator" />
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-payment-title text--body-2">
            Cash Down
          </span>
          <span className="text--body-focus-price">$15,000</span>
        </div>
        <div className="vehicle-card-payment-info-row">
          <span className="vehicle-card-payment-title text--body-2">Term</span>
          <span className="text--body-focus-price">60 months</span>
        </div>
        <div className="vehicle-card-cta">
          <Button
            htmlType={"button"}
            text="Details"
            size="small"
            className="vehicle-card-cta"
          />
        </div>
      </div>
    </div>
  );
};
