import styled from "@emotion/styled";
import { GLOBAL_THEME as Theme } from "@styles/theme";
import errorIcon from "../../../shared/assets/icons/input-icons/error.svg";

export const SelectWrap = styled("div")({
  "&.select-wrap": {
    ".select-label": {
      marginBottom: 8,
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".select-label-icon": {
        "&:before": {
          fontFamily: "bmw_next_icons",
          display: "block",
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: 1,
          textTransform: "none",
          wordWrap: "normal",
          whiteSpace: "nowrap",
          direction: "ltr",
          textAlign: "left",
          speak: "none",
          height: "1em",
          outline: "1px solid transparent",
          "-webkit-font-smoothing": "antialiased",
          textRendering: "optimizeLegibility",
          "-moz-osx-font-smoothing": "grayscale",
          fontFeatureSettings: "liga",
          content: "attr(data-icon)",
          width: "auto",
          fontSize: 16,
        },
      },
    },
    ".help-text, .error-text": {
      marginTop: 4,
      display: "block",
    },
    ".ant-select": {
      width: "100%",
      height: 52,
      border: "1px solid" + Theme.token?.colorBorder,
      borderRadius: 3,
      ".ant-select-selector": {
        padding: "0 16px",
        border: "none",
        ".ant-select-selection-search-input": {
          height: "100%",
        },
        ".ant-select-selection-item": {
          color: Theme.token?.colorText,
          paddingInlineEnd: 22,
        },
        ".ant-select-selection-wrap": {
          ".ant-select-selection-search": {
            paddingInlineEnd: 22,
          },
        },
      },
      ".ant-select-arrow": {
        insetInlineEnd: 22,
        ".anticon": {
          svg: {
            width: 20,
            height: 20,
          },
        },
      },
      "&:hover": {
        boxShadow: Theme.components?.Input?.activeShadow,
        ".ant-select-selector": {
          borderColor: Theme.token?.colorWhite + "!important",
        },
      },
      "&.ant-select-focused": {
        boxShadow: Theme.components?.Input?.activeShadow,
        ".ant-select-selector": {
          boxShadow: "none !important",
        },
      },
      "&.ant-select-status-error": {
        border: "1px solid" + Theme.token?.colorError,
        background: Theme.token?.colorErrorBg,
        backgroundImage: `url(${errorIcon})`,
        backgroundPosition: "calc(100% - 48px)",
        backgroundRepeat: "no-repeat",
        backgroundSize: 20,
        ".ant-select-selector": {
          background: "transparent",
        },
        ".ant-select-arrow": {
          svg: {
            path: {
              fill: Theme.token?.colorError,
            },
          },
        },
        ".ant-select-selection-placeholder, .ant-select-selection-item": {
          color: Theme.token?.colorError,
        },
        ".ant-select-selection-search": {
          paddingInlineEnd: 56,
        },
        "&:hover, &.ant-select-focused": {
          borderColor: Theme.token?.colorError,
          boxShadow: Theme.components?.Input?.colorErrorBorderHover,
        },
      },
      "&.ant-select-disabled": {
        color: "rgba(0, 0, 0, 0.25)",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderColor: "rgba(255, 255, 255, 0.4)",
        "&:hover": {
          boxShadow: "none",
        },
      },
      "&.ant-select-open": {
        boxShadow: Theme.components?.Input?.activeShadow,
      },
    },
    "&.select--is-disabled": {
      ".select-label, .help-text": {
        "&.text-secondary": {
          color: Theme.token?.colorBorder,
        },
      },
      "&.ant-select-selection-placeholder": {
        color: Theme.token?.colorBorder,
      },
      ".ant-select": {
        ".ant-select-arrow": {
          svg: {
            path: {
              fill: Theme.token?.colorBorder,
            },
          },
        },
      },
    },
    ".ant-select-selection-placeholder": {
      color: "#96a9b7",
    },
    ".ant-select-dropdown": {
      background: Theme.token?.colorWhite,
      boxShadow:
        "0px 0px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)" +
        "!important",
      borderRadius: 0,
      ".ant-select-item": {
        padding: "8px 6px 8px 12px",
        color: Theme.token?.colorText,
        position: "relative",
        borderRadius: 0,
        ".ant-select-item-option-content": {
          padding: "6px 8px",
        },
        "&.ant-select-item-option-selected": {
          backgroundColor: Theme.token?.colorText,
          color: Theme.token?.colorWhite,
          fontWeight: "normal",
        },
        "&.ant-select-item-option-active": {
          color: Theme.token?.colorWhite,
          backgroundColor: Theme.token?.colorText,
        },
      },
      ".rc-virtual-list-scrollbar": {
        width: "2px !important",
        backgroundColor: Theme.token?.colorBorderSecondary,
        right: "4px !important",
        ".rc-virtual-list-scrollbar-thumb": {
          background: "#4d4d4d" + "!important",
        },
      },
    },
  },
});
