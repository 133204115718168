import axios from 'axios'
import { ACCESS_TOKEN_KEY } from '@helpers/constants'
import { LocalStorage } from '@helpers/browserStorage'
import AppError, { ApiError } from './errors'
//@ts-ignore
import { handleRefreshToken, logout, checkSSOExpiry } from '@ntpkunity/controls-ums'
import {
  getANToken,
  getAllSettingsFromLocalStorage,
  getDecodedANToken,
  getExternalSubscriptionKey,
  setExternalSubscriptionKey
} from '@helpers/methods'
import { msalInstance } from 'root.component'
import { loginRequest } from '../authConfig'
import jwt_decode from 'jwt-decode'
import { EP_EXTERNAL_KEYS } from './endpoints'

export const externalAutoNationBaseUrl: any = process.env.AUTO_NATION_BASE_URL

interface Setting {
  baseUrl?: string
  withAuthentication?: boolean
}
axios.interceptors.request.use(
  async (req: any) => {
    if (req.url?.includes(externalAutoNationBaseUrl)) {
      try {
        const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: msalInstance.getAllAccounts()[0]
        })
        const externalSubscriptionKey: string = getExternalSubscriptionKey()
        if (externalSubscriptionKey == null) {
          const { data } = await extenalKeysApi.get(EP_EXTERNAL_KEYS)
          setExternalSubscriptionKey(data?.external_subscription_key)
          req.headers['Ocp-Apim-Subscription-Key'] = data?.external_subscription_key
        } else {
          req.headers['Ocp-Apim-Subscription-Key'] = externalSubscriptionKey
        }
        req.headers['Authorization'] = `Bearer ${response.accessToken}`
        if (req.url?.includes('v1/orders')) {
          const decodedToken: any = jwt_decode(response.accessToken)
          const decodededSettingsToken = getDecodedANToken(getANToken())
          const checkAdmin = decodededSettingsToken?.user_roles?.find(
            (obj: any) => obj?.is_admin
          )?.is_admin
          if (!checkAdmin) {
            req.params['hyperionIds'] = decodedToken?.hyperion
          }
        }
      } catch (error) {}
    }
    const iS_AN_Route = window.location.href?.includes('dealer/work-queue')
    if (iS_AN_Route) {
      if (req.url?.includes('/lane') || req.url?.includes('/config') || req.url?.includes('/eot')) {
        try {
          req.headers['Authorization'] = `Bearer ${getAllSettingsFromLocalStorage()?.access_token}`
        } catch (error) {}
      }
    } else {
      req.headers['Authorization'] = `Bearer ${getAllSettingsFromLocalStorage()?.access_token}`
    }
    return req
  },
  //@ts-ignore
  (err: any) => {}
)

const extenalKeysApi = axios.create({
  baseURL: process.env.API_GATEWAY,
  withCredentials: false,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${getAllSettingsFromLocalStorage()?.access_token}`
  }
})

extenalKeysApi.interceptors.response.use(
  async (response) => {
    await checkSSOExpiry()
    return response
  },
  async (error) => {
    const response = await handleRefreshToken(error, axios, logout)
    return response
  }
)

class Http {
  _localStorageService: LocalStorage
  _baseUrl?: string
  _token: string | null
  constructor(settings?: Setting) {
    this._localStorageService = new LocalStorage()
    this._baseUrl = settings?.baseUrl ? settings?.baseUrl : process.env.API_GATEWAY
    this._token = settings?.withAuthentication
      ? this._localStorageService.getItemByKey(ACCESS_TOKEN_KEY)
      : null
  }

  static createConnection(settings?: Setting) {
    return new Http(settings)
  }
  public async get<T>(url: string, params?: any, options?: any): Promise<T> {
    try {
      const response = await axios.get<T>(`${this._baseUrl}/${url}`, {
        ...options,
        auth: this._token,
        params
      })

      return response.data
    } catch (error: any) {
      throw new ApiError(`Http.get(${this._baseUrl}/${url})`, error, undefined, undefined, {
        params,
        options
      })
    }
  }
  public getPaginated(url: string, params?: any, options?: any): any {
    try {
      throw new AppError(`Http.getPaginated(${this._baseUrl}/${url})`, 'NOT_IMPLEMENTED')
    } catch (error: any) {
      throw new ApiError(
        `Http.getPaginated(${this._baseUrl}/${url})`,
        error,
        undefined,
        undefined,
        { params, options }
      )
    }
  }
  public async post<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await axios.post<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token
      })

      return response.data
    } catch (error: any) {
      throw new ApiError(`Http.post(${this._baseUrl}/${url})`, error, undefined, undefined, {
        body,
        options
      })
    }
  }
  public async put<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await axios.put<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token
      })

      return response.data
    } catch (error: any) {
      throw new ApiError(`Http.put(${this._baseUrl}/${url})`, error, undefined, undefined, {
        body,
        options
      })
    }
  }
  public async patch<T>(url: string, body?: any, options?: any): Promise<T> {
    try {
      const response = await axios.patch<T>(`${this._baseUrl}/${url}`, body, {
        ...options,
        auth: this._token
      })

      return response.data
    } catch (error: any) {
      throw new ApiError(`Http.put(${this._baseUrl}/${url})`, error, undefined, undefined, {
        body,
        options
      })
    }
  }
  public async delete<T>(url: string, params?: any, options?: any): Promise<T> {
    try {
      const response = await axios.delete<T>(`${this._baseUrl}/${url}`, {
        ...options,
        params,
        auth: this._token
      })

      return response.data
    } catch (error: any) {
      throw new ApiError(`Http.delete(${this._baseUrl}/${url})`, error, undefined, undefined, {
        params,
        options
      })
    }
  }
}

export default Http
