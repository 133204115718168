import { QueryClientProviderWrapper as QueryClientProvider } from "./application-query-client";
import { RouterProviderWrapper as RouterProvider } from "./application-router";
import { ThemeProvider } from "./application-ant-design";

import "@styles/main.scss";

const Application = () => {
  return (
    <ThemeProvider>
      <QueryClientProvider>
        <RouterProvider />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default Application;
