import Http from "shared/helper/http-api";
import { ConfigurationAPI } from "services";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { QueryKeys, ToastMessages } from "Enums";
import { IDealerIntegrations } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";

export const getAllDealerIntegrationFromServer = (columnFilters: string) => {
  return ConfigurationAPI.get(
    `/integrations/filter?`.concat(columnFilters)
  ).then((res) => {
    return res.data;
  });
};

export const getAllDealerIntergrationFilterFromServer = (
  integration?: string
) => {
  var query_string = `integration=${integration}`;
  if (integration != null && integration != "") {
    query_string = query_string.concat(`&integration=${integration}`);
  }
  const apiService = Http.createConnection();
  return apiService
    .get(`integrations/dealer/filter?`.concat(query_string))
    .then((response) => {
      return response;
    });
};

// updated Endpoint
export const UseQuery_getAllDealerIntergrations = (columnFilters: string) => {
  const key = [QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER, columnFilters];
  return useQuery(
    key,
    async () => {
      return getAllDealerIntegrationFromServer(columnFilters).then((data) => {
        return data;
      });
    },
    { enabled: !!columnFilters, keepPreviousData: true }
  );
};

export const useGetAllDealeIntegrations = (): any => {
  // const queryClient = useQueryClient();
  // const [, dispatch] = useSetupsStore();
  return useMutation<IDealerIntegrations>(
    (body: any) => {
      return ConfigurationAPI.get("/integration/dealer");
    },
    {
      onSuccess(_response: any) {},
    }
  );
};

export const UseQuery_getAllDealerIntegration_byParams = (): any => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER,
    (params: any) => {
      return getAllDealerIntergrationFilterFromServer(params.integration).then(
        (data: any) => {
          return data?.result;
        }
      );
    },
    {
      onSuccess: async (response: any) => {
        queryClient.setQueryData(
          [QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER],
          response
        );
      },
    }
  );
};

export const useDeleteIntegrationProvider = (): any => {
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      return ConfigurationAPI.delete(`integration/provider/${body}`);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_LENDER_INTEGRATIONS_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_INTEGRATION);
        queryClient.invalidateQueries(QueryKeys.GET_ALL_LENDER_INTEGRATIONS);
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_INTEGRATION_FILTER
        );
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DEALER_INTEGRATION);
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const UseQueryGetDealerIntergrations = (dealer_code: string) => {
  return useQuery(
    [QueryKeys.GET_ALL_DEALER_INTEGRATION, dealer_code],
    async () => {
      return ConfigurationAPI.get(
        `/integration/dealer/active/${dealer_code}`
      ).then((res) => {
        return res.data;
      });
    },
    { enabled: !!dealer_code }
  );
};

export const UseQueryGetDealerProviders = (dealer_code: string): any => {
  return useQuery(
    [QueryKeys.GET_DEALER_PROVIDERS, dealer_code],
    async () => {
      return ConfigurationAPI.get(
        `/integration/dealer/provider/${dealer_code}`
      ).then((res) => {
        return res.data;
      });
    },
    { enabled: !!dealer_code }
  );
};


export const useGetLenderintegrationByIntegrationType = (): any => {
  return useMutation<any>(
    (body: any) => {
      return ConfigurationAPI.get(
        `/integration/integration-type-company/e_signatures`
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
};
