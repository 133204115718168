import { FC, useState, useEffect, useMemo, useContext } from "react";
import { useTheme } from "@mui/material";
import { Button, Box } from "@ntpkunity/controls";
import { DrawerWrap, DataTableWrap } from "@sharedComponents";
import AddNewDealerAppointment from "./addNew-appointment-drawer";
import DealerAppointmentTable from "./appointment-table";
import { IDealerAppointment, IImage } from "Interface";
import { ActionOptions } from "Enums";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import { useSetupsStore } from "Stores/SetupStore/Store";

const DealerAppointment: FC<any> = ({
}) => {
  const theme = useTheme();
  const { dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [enabledPin, setEnabledPin] = useState(false);
  const [images, setImages] = useState<Array<IImage>>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, dispatch] = useSetupsStore();

  const [actionTitle, setActionTitle] = useState<string>("Add New Appointment Type");

  const [actionType, setActionType] = useState<ActionOptions>(
    ActionOptions.ADD
  );
  const [actionData, setActionData] = useState<IDealerAppointment>();
  const [ProductState, setProductState] = useState<boolean>(false);

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Appointment Type");
        setActionData(value);
        setProductState(true);
        setIsEditMode(false);
        dispatch(setOpenDrawer(true));
        setImages([]);
        form.reset({} as IDealerAppointment);
      default:
        return;
    }
  };

  const defaultValues: IDealerAppointment = useMemo(() => {
    const updatedFormDefaultValues = actionData;
    setActionData(updatedFormDefaultValues);
    return actionData;
  }, [actionData]) as unknown as IDealerAppointment;

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      form.reset({ ...defaultValues });
      setIsEditMode(true);
    } else {
      form.reset();
    }
  }, [defaultValues]);


  const form: {
    handleSubmit: any;
    reset: any;
  } = useForm<IDealerAppointment>();

  useEffect(() => {
    if (ProductState === false) {
      dispatch(setOpenDrawer(false));
    }
  }, [ProductState]);
  return (
    // <DrawerWrap open={popUpState}>
     <>
      <DataTableWrap className="table-tabs-pagination-button" key={1}>
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        {dealer_code && (
          <DealerAppointmentTable
            setPopUpState={setProductState}
            setActionData={setActionData}
            setActionType={setActionType}
            setActionTitle={setActionTitle}
            actionData={actionData}
            dealer_code={dealer_code}
          />
        )}
        <Button
          id="addNewDealerAppointment"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Appointment Type"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
         <form onSubmit={(e) => e.preventDefault()}>
          <AddNewDealerAppointment
            popUpState={ProductState}
            setPopUpState={setProductState}
            title={actionTitle}
            actionType={actionType}
            form={form}
            enabledPin={enabledPin}
            dealer_code={dealer_code}
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
          ></AddNewDealerAppointment>
          </form>
      </DataTableWrap>
     </>
   );
};

export default DealerAppointment;
