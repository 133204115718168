import { Button } from "@src/components/atoms";
import "./vehicle-ownership-completed.scss";

export const VehicleOwnershipCompleted = ({
  changeStateOfEditModeHandler,
}: {
  changeStateOfEditModeHandler: (step: string) => void;
}) => {
  return (
    <div className="completed-form-container">
      <div className="completed-form">
        <div className="completed-form header-wrapper">
          <h2 className="section-header upper-label">
            <span className="section-header text--subsection-2 text-secondary">
              01
            </span>
            <span className="section-header text--subsection-2">
              Vehicle ownership.
            </span>
          </h2>

          <Button
            htmlType={"button"}
            text="Edit"
            onClick={() => changeStateOfEditModeHandler("step-one")}
            size="small"
          />
        </div>
        <div className="completed-form content-container">
          <div className="completed-form content-container__header-wrapper">
            <div className="completed-form content-container__header-wrapper__header text--body-2">
              This vehicle is for you.
            </div>
            <div className="completed-form content-container__header-wrapper__sub-header text--body-2">
              You are the primary driver. Other drivers may use the vehicle
              occasionally.
            </div>
          </div>
          <div className="completed-form content-container__divider" />
          <div className="completed-form content-container__personal-details">
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  First Name
                </span>
                <span className="column detail-value text--body-2">
                  Christopher
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Middle Name
                </span>
                <span className="column detail-value text--body-2">
                  Richard
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Last Name
                </span>
                <span className="column detail-value text--body-2">Runnen</span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Date of Birth
                </span>
                <span className="column detail-value text--body-2">
                  12.10.1981
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Social Security Number
                </span>
                <span className="column detail-value text--body-2">
                  *** ** ****
                </span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Phone Number
                </span>
                <span className="column detail-value text--body-2">
                  (555) 555-1234
                </span>
              </div>
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Mobile number
                </span>
                <span className="column detail-value text--body-2">
                  (555) 555-1234
                </span>
              </div>
            </div>
            <div className="completed-form content-container__personal-details__detail-row">
              <div className="column">
                <span className="column detail-label text--disclaimer">
                  Email address
                </span>
                <span className="column detail-value text--body-2">
                  chrisschumacher@bmwna.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
