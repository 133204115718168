interface initialStateType {
  mailingAddress: boolean;
  billingAddress: boolean;
  isBillingAddressSame: boolean;
  isMailingAddressSame: boolean;
}

export const initialState: initialStateType = {
  mailingAddress: false,
  billingAddress: false,
  isBillingAddressSame: false,
  isMailingAddressSame: false,
};

export const reducer = (state: initialStateType, action: any) => {
  switch (action.type) {
    case "toggle-mailing-address-state":
      return {
        ...state,
        mailingAddress: !state.mailingAddress,
      };
    case "toggle-billing-address-state":
      return {
        ...state,
        billingAddress: !state.billingAddress,
      };
    case "toggle-is-billing-address-same":
      return {
        ...state,
        isBillingAddressSame: !state.isBillingAddressSame,
      };
    case "toggle-is-mailing-address-same":
      return {
        ...state,
        isMailingAddressSame: !state.isMailingAddressSame,
      };
    default:
      return state;
  }
};
