import { FC, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { DrawerWrap, Main, PageHeader } from "@sharedComponents";
import { LayoutWithSideNav } from "layout";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { DuoTab, Typography } from "@ntpkunity/controls";
import { IntegrationType, Providers } from "Enums/IntegrationTypesAndProviders";
import { LenderProfile } from "shared/components";
import { DealerContext } from "context";
import { useMutation_CheckS3Credentials, UseQueryGetDealerIntergrations } from "services";
import { Module } from "Enums";
import { Markup } from "modules";

const DealerLenderList: FC = () => {
  const theme = useTheme();
  const [state] = useSetupsStore();
  const [popUpState, setPopUpState] = useState(false);

  const { openDrawerSt } = state;

  const { dealer_code } = useContext(DealerContext);
  const [provider, setProvider] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const { data: dealerIntegrations, isFetched } =
    UseQueryGetDealerIntergrations(dealer_code);

  useEffect(() => {
    if (dealerIntegrations) {
      const integration = dealerIntegrations?.find(
        (x) => x.integration_type === IntegrationType.DEALER_LENDER_ASSOCIATION
      );
      const provider = integration?.providers?.find(
        (x) =>
          (x.is_active && x.provider_name === Providers.DEALER_TRACK_LENDER) ||
          x.provider_name === Providers.ROUTEONE
      );
      setProvider(provider);
    }
  }, [dealerIntegrations]);

  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);

  const tabs = [
    {
      title: "Lenders",
      content: (
        <LenderProfile
          popUpState={popUpState}
          setPopUpState={setPopUpState}
          module={Module.DEALER_MODULE}
          dealer_code={dealer_code}
          provider={provider}
        />
      ),
    },
    {
      title: "Markup",
      content: (
        <Markup
          isDisabled={isDisabled}
          s3Response={s3Response}
        />
      ),
    },
  ];

  const handleChange = (event, index) => {
    setActiveTab(index);
  };

  return (
    <Main open={openDrawerSt ? openDrawerSt : false}>
      <DrawerWrap open={popUpState}>
        <LayoutWithSideNav theme={theme}>
          <DuoTab
            theme={theme}
            varient={"underline"}
            items={tabs}
            defaultTabIndex={0}
            onChange={handleChange}
          />
        </LayoutWithSideNav>
      </DrawerWrap>
    </Main>
  );
};

export default DealerLenderList;