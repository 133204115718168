import { Button, Checkbox } from "@src/components/atoms";
import "./agreements.style.scss";

export const Agreements = ({
  stepSixDataReceivedEvent,
}: {
  stepSixDataReceivedEvent: () => void;
}) => {
  return (
    <div className="unfilled-agreement-container">
      <h2 className="section-header upper-label spacing">
        <span className="section-header number text--subsection-2">06</span>
        <span className="section-header text text--subsection-2">
          Agreements.
        </span>
      </h2>
      <p className="unfilled-agreement-container text--body-2">
        Please review and accept the following terms &amp; conditions before
        submitting your application.
      </p>
      <div className="unfilled-agreement-container checkbox-wrapper">
        <Checkbox
          id={"agreementChk"}
          label=""
          htmlFor="agreementChk"
          onChangeHandler={() => {}}
        />
        <div className="unfilled-agreement-container checkbox-wrapper agreement-bullets-wrapper">
          <p className="unfilled-agreement-container checkbox-wrapper agreement-bullets-wrapper label text--disclaimer">
            I have read and agree to the below:
          </p>
          <ul className="unfilled-agreement-container checkbox-wrapper agreement-bullets-wrapper bullets text--disclaimer">
            <li>
              Before submitting a credit application, it is your responsibility
              to ensure that your credit report is not frozen. If your credit
              report is currently frozen, please unfreeze it to allow the lender
              to access your credit information.
            </li>
            <li>
              By proceeding with the application, you acknowledge and accept
              that BMW of Manhattan will perform a hard credit pull yielding
              offers from BMW Financial Services as well as various other
              Lenders, which is a formal request to review your credit
              information and may temporarily impact your credit score.
            </li>
            <li>
              I have read the
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" className="terms-link text--disclaimer-link">
                Terms & Conditions.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Button
        type="primary"
        htmlType={"button"}
        text="Agree &amp; Submit"
        onClick={stepSixDataReceivedEvent}
        fixed
        disabled
        fullWidth
      />
    </div>
  );
};
