import React, { FC, useEffect, useRef, useState } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  DataTable,
  Input,
  Select,
  Icon,
  Tooltip,
} from "@ntpkunity/controls";
import {
  MultiSelectAll,
  BorderedTableWrap,
} from "@sharedComponents";
import { Controller } from "react-hook-form";
import { IFinanceTypeModel, IStates } from "Interface";
import { UseQuery_getAllFeeName } from "services";
interface IFeesMappingProps {
  form: any;
  setUpdatedFeesRows: any;
  financeTypes: Array<IFinanceTypeModel>;
  statesData: Array<IStates>;
  feesMappings: any;
  setFeesMappingData: any;
}

const FeesTable: FC<IFeesMappingProps> = ({
  form,
  setUpdatedFeesRows,
  financeTypes,
  statesData,
  feesMappings,
}) => {
  const theme = useTheme();
  const tableContainerRef = useRef(null);
  const lastRowRef = useRef(null);
  const {
    control,
    formState: { errors },
  } = form;

  const { data: feeNames } = UseQuery_getAllFeeName();
  const feeNameItems =
    feeNames?.map((fee) => ({
      text: fee.name,
      value: fee.id,
    })) || [];

  const stateItems =
    statesData?.map((state) => ({
      text: state.name,
      value: state.id,
    })) || [];

  const financeTypeItems =
    financeTypes?.map((finance_type) => ({
      text: finance_type.name,
      value: finance_type.id,
    })) || [];

  const handleRowChange = (index) => {
    setUpdatedFeesRows((prev) => new Set(prev).add(index));
  };


  useEffect(() => {
    if (feesMappings) {
      if (lastRowRef.current) {
        lastRowRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [feesMappings]);

  return (
    <BorderedTableWrap theme={theme} className="custom-table-wrap">
      <Box theme={theme} className="scroll" ref={tableContainerRef}>
        <Box className="scroll-hide sh-fixed-cell spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <TableRow>
            <TableCell className="sr-num"></TableCell>
            <TableCell>Fee Name</TableCell>
            <TableCell>Finance Type</TableCell>
            <TableCell>State</TableCell>
            <TableCell>External Code</TableCell>
          </TableRow>
        }
        tbodyChildren={feesMappings?.map((item, index) => (
          <React.Fragment key={index}>
            <TableRow className="child-tr" ref={index === feesMappings.length - 1 ? lastRowRef : null}>
              <TableCell className="sr-num">{index + 1}</TableCell>
              <TableCell
                className={`input-cell ${
                  errors?.feesMapping?.[index]?.fee_name_id ? "error" : ""
                }`}
              >
                {errors?.feesMapping?.[index]?.fee_name_id && (
                  <Tooltip
                    theme={theme}
                    title={
                      errors?.feesMapping?.[index]?.fee_name_id?.message || ""
                    }
                    placement="top"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
                <Controller
                  name={`feesMapping.${index}.fee_name_id`}
                  control={control}
                  defaultValue={item.fee_name_id || ""}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      theme={theme}
                      id={`feesMapping.${index}.fee_name_id`}
                      name={`feesMapping.${index}.fee_name_id`}
                      disablePortal={false}
                      items={feeNameItems || []}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleRowChange(index);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  )}
                />
              </TableCell>
              <Controller
                name={`feesMapping.${index}.id`}
                control={control}
                defaultValue={item.id}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <TableCell
                className={`input-cell ${
                  errors?.feesMapping?.[index]?.finance_type ? "error" : ""
                }`}
              >
                {errors?.feesMapping?.[index]?.finance_type && (
                  <Tooltip
                    theme={theme}
                    title={
                      errors?.feesMapping?.[index]?.finance_type?.message || ""
                    }
                    placement="top"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
                <Controller
                  name={`feesMapping.${index}.finance_type`}
                  control={control}
                  defaultValue={item?.finance_type || financeTypeItems?.map((item) => item?.value)}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelectAll
                      id={`feesMapping.${index}.finance_type`}
                      name={`feesMapping.${index}.finance_type`}
                      theme={theme}
                      items={financeTypeItems}
                      value={value as any[]}
                      onChange={(e, value) => {
                        onChange(value);
                        handleRowChange(index)
                      }}
                      disablePortal={false}
                    />
                  )}
                />
              </TableCell>
              <TableCell
                className={`input-cell ${
                  errors?.feesMapping?.[index]?.state ? "error" : ""
                }`}
              >
                {errors?.feesMapping?.[index]?.state && (
                  <Tooltip
                    theme={theme}
                    title={errors?.feesMapping?.[index]?.state?.message || ""}
                    placement="top"
                  >
                    <Icon name="CircleExc" />
                  </Tooltip>
                )}
                <Controller
                  name={`feesMapping.${index}.state`}
                  control={control}
                  defaultValue={item?.state || stateItems?.map((item) => item?.value)}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelectAll
                      id={`feesMapping.${index}.state`}
                      name={`feesMapping.${index}.state`}
                      theme={theme}
                      items={stateItems}
                      value={value as any[]}
                      onChange={(e, value) => {
                        onChange(value);
                        handleRowChange(index)
                      }}
                      disablePortal={false}
                    />
                  )}
                />
              </TableCell>
              <TableCell className="input-cell">
                <Controller
                  name={`feesMapping.${index}.external_code`}
                  control={control}
                  defaultValue={item.external_code || ""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={"Type here..."}
                      type="string"
                      id={`feesMapping.${index}.external_code`}
                      onChange={(e) => {
                        onChange(e);
                        handleRowChange(index);
                      }}
                      value={value?.trimStart()}
                    ></Input>
                  )}
                />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      />
    </BorderedTableWrap>
  );
};

export default FeesTable;
