import { InsuranceInfo } from "@src/components/molecules";
import "./insurance-information.scss";
import { Button } from "@src/components/atoms";

export const InsuranceInformation = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  return (
    <div className="insurance-information-wrapper">
      <InsuranceInfo />
      <div className="insurance-information-cta-wrapper">
        <Button
          type="default"
          htmlType={"button"}
          text="Save & continue"
          onClick={handleClick}
          fullWidth
          fixed
        />
      </div>
    </div>
  );
};
