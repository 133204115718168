import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";

import AuthChecker from "@components/atoms/password-checker/password-checker.coponent";

import CheckoutPage from "@pages/checkout-page/checkout-page";
import FinanceOfferPage from "@pages/finance-offer-page/finance-offer-page";

// extra page for test components
import { LoginComponent } from "@components/test-components/login";
import DealStatePage from "@src/pages/deal-state/deal-state-page";
import {
  IncentivesAndDiscountsPage,
  ProtectionAndWarrantyPage,
} from "@src/pages";

const ROUTING_PATH = {
  CHECKOUT: "/checkout",
  FINANCE_OFFER: "/finance-offer",
  LOGIN_PAGE: "/login",
  DEAL_STATE_PAGE: "/deal-state",
  INCENTIVES_AND_DISCOUNTS_PAGE: "/incentives-and-discounts",
  PROTECTION_AND_WARRANTY_PAGE: "/protection-and-warranty",
};

const ErrorPage = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <a href={ROUTING_PATH.CHECKOUT}>Go to Checkout</a>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ROUTING_PATH.CHECKOUT} replace />,
  },
  {
    path: ROUTING_PATH.CHECKOUT,
    element: (
      <AuthChecker>
        <CheckoutPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.FINANCE_OFFER,
    element: (
      <AuthChecker>
        <FinanceOfferPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.DEAL_STATE_PAGE,
    element: (
      <AuthChecker>
        <DealStatePage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.INCENTIVES_AND_DISCOUNTS_PAGE,
    element: (
      <AuthChecker>
        <IncentivesAndDiscountsPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.PROTECTION_AND_WARRANTY_PAGE,
    element: (
      <AuthChecker>
        <ProtectionAndWarrantyPage />
      </AuthChecker>
    ),
  },
  {
    path: ROUTING_PATH.LOGIN_PAGE,
    element: <LoginComponent />,
  },
  {
    path: "*", // Matches all undefined routes
    element: <ErrorPage />,
  },
]);

export const RouterProviderWrapper = () => {
  return <RouterProvider router={router} />;
};
