import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid, Box, Button, Icon, Menu } from '@ntpkunity/controls'
import { InsuranceRecord } from 'models/order-information.interface'
import {
  useAddInsuranceInformation,
  useGetOrderInsuranceDocs,
  useGetPreSignedUrl,
  useUpdateInsuranceInformation
} from '@apis/order-management.service'
import InsuranceControl from 'controls/InsuranceControl/insurance-control'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatPhoneNumber, utcToLocal } from '@helpers/methods'

const InsuranceDetails: FC<{
  insurance: InsuranceRecord
  referenceId?: string
  setAddInsurance?: any
  setAddInsuranceVerified?: any
  isInsuranceEditingDisabled?: boolean
}> = ({
  insurance,
  referenceId,
  setAddInsurance,
  isInsuranceEditingDisabled = false,
  setAddInsuranceVerified
}) => {
  const theme = useTheme()
  const { reference_id } = useParams()
  const [insuranceData, setInsuranceData] = useState(insurance)
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()
  const [openInsuranceDialog, setInsuranceOpenDialog] = useState(false)
  const { mutate: updateInsurance, isLoading: _insuranceLoading } = useUpdateInsuranceInformation()
  const { mutate: addInsurance, isLoading: _addInsuranceLoading } = useAddInsuranceInformation()
  const { data: orderInsuranceDocuments } = useGetOrderInsuranceDocs(reference_id)

  useEffect(() => {
    if (insurance) {
      setInsuranceData((prev) => {
        const { insurance_address: insurer_address, ...restInsurance } = insurance
        return { ...restInsurance, ...(insurer_address && { insurer_address }), ...prev }
      })
    }
  }, [insurance])

  useEffect(() => {
    if (orderInsuranceDocuments) {
      setInsuranceData((prev) => ({ ...prev, insurance_documents: orderInsuranceDocuments }))
    }
  }, [orderInsuranceDocuments])

  const handleInsuranceData = (data: any) => {
    const payload = {
      ...data,
      agent_phone: data?.agent_phone?.substring(1),
      expiration_date: dayjs(data.expiration_date).format('YYYY-MM-DD'),
      reference_id: referenceId,
      created_by: 'lender',
      updated_by: 'lender'
    }
    setInsuranceData(data)

    if (insuranceData) {
      updateInsurance(payload, {
        onSuccess() {
          setAddInsurance(true)
          setAddInsuranceVerified(true)
        }
      })
    } else {
      addInsurance(
        {
          ...data,
          agent_phone: data?.agent_phone?.substring(1),
          expiration_date: dayjs(data.expiration_date).format('YYYY-MM-DD'),
          order_reference_id: reference_id,
          reference_id: referenceId,
          created_by: 'lender',
          updated_by: 'lender'
        },
        {
          onSuccess() {
            setAddInsurance(true)
            setAddInsuranceVerified(true)
          }
        }
      )
    }
  }

  const insuranceDocumentImage = useMemo(() => {
    if (!Array.isArray(orderInsuranceDocuments) || orderInsuranceDocuments?.length === 0) {
      return null
    }

    return (
      orderInsuranceDocuments.find(
        (doc: any) => doc?.content_type && doc?.content_type?.startsWith('image/')
      ) || null
    )
  }, [orderInsuranceDocuments])

  const handleClick = () => {
    const documentUri = insuranceDocumentImage?.document
    if (documentUri) {
      const isValidUrl = /^https?:\/\//i.test(documentUri)
      const imageURL = isValidUrl ? new URL(documentUri || '')?.pathname?.substring(1) : documentUri
      getPreSignedUrl(
        { key: imageURL },
        {
          onSuccess(res: any) {
            window.open(res, '_blank')
          }
        }
      )
    }
  }

  const address = insuranceData?.insurer_address
  const { address_line_1, address_line_2, city, state_name, zip_code, county } = address || {}
  const formattedAddress = [address_line_1, address_line_2, city, state_name, zip_code, county]
    .filter(Boolean)
    .join(', ')

  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Box className="card-body" theme={theme}>
          <Box className="row" theme={theme}>
            <Typography component="h4" variant="h4" theme={theme} children="Insurance" />
            <Menu
              sx={{ ml: 2 }}
              theme={theme}
              options={[
                insuranceData
                  ? {
                      optionText: (
                        <>
                          <Icon className="menu-icon" name="EditIcon" /> Edit
                        </>
                      ),
                      optionkey: 'Edit',
                      optionValue: document,
                      disabled: isInsuranceEditingDisabled
                    }
                  : {
                      optionText: (
                        <>
                          <Icon className="menu-icon" name="AddIcon" /> Add
                        </>
                      ),
                      optionkey: 'Add',
                      optionValue: document
                    }
              ]}
              handleOptionClick={(_event: any, key: any, _value: any) => {
                switch (key) {
                  case 'Edit':
                    setInsuranceOpenDialog(true)
                    break
                  case 'Add':
                    setInsuranceOpenDialog(true)
                    break
                  default:
                    break
                }
              }}
              render={(handleOptionClick: any) => (
                <Box theme={theme}>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={handleOptionClick}
                  />
                </Box>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          </Box>
          {insuranceData ? (
            <Box className="card-body" mt={2} theme={theme}>
              <Box className="secondary-wrap" theme={theme}>
                <Box className="row" theme={theme}>
                  <Box className="center title" theme={theme}></Box>
                  <Grid theme={theme} className="container" container>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        Insurance Provider
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Typography theme={theme} variant="body2" component="span">
                        {insuranceData?.insurer_name ?? '-'}
                      </Typography>
                    </Grid>
                    {/* *********************** */}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        Contact Number
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label-detail"
                      >
                        {formatPhoneNumber(insuranceData?.agent_phone) || '-'}
                      </Typography>
                    </Grid>
                    {/* *********************** */}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        Policy Number
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label-detail"
                      >
                        {insuranceData?.policy_number ?? '-'}
                      </Typography>
                    </Grid>
                    {/* *********************** */}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        Expiration
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label-detail"
                      >
                        {utcToLocal(insuranceData?.expiration_date, 'MM/DD/YYYY') ?? '-'}
                      </Typography>
                    </Grid>
                    {/* *********************** */}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography theme={theme} variant="body2" component="h3" className="label">
                        Address
                      </Typography>
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label-detail"
                      >
                        {formattedAddress ?? '-'}
                      </Typography>
                    </Grid>
                    {/* *********************** */}
                    {_addInsuranceLoading ||
                    _insuranceLoading ||
                    !orderInsuranceDocuments ? null : (
                      <>
                        {insuranceDocumentImage ? (
                          <>
                            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                              <Typography
                                theme={theme}
                                variant="body2"
                                component="h3"
                                className="label"
                              >
                                Proof Insurance
                              </Typography>
                            </Grid>
                            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                              <Typography
                                theme={theme}
                                variant="body2"
                                component="h3"
                                className="label-detail"
                                children={
                                  <>
                                    <Link className="link" onClick={handleClick}>
                                      View Image
                                    </Link>
                                  </>
                                }
                              />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </DetailCard>

      {openInsuranceDialog && (
        <InsuranceControl
          openInsuranceDialog={openInsuranceDialog}
          insuranceFormData={{
            ...insuranceData,
            insurer_name: insuranceData?.insurer_name
              ? {
                  id: insuranceData?.insurer_name ?? '',
                  label: insuranceData?.insurer_name ?? ''
                }
              : null
          }}
          setInsuranceFormData={handleInsuranceData}
          setInsuranceOpenDialog={setInsuranceOpenDialog}
        />
      )}
    </>
  )
}

export default InsuranceDetails
