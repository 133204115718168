import { FC, useEffect, useState, useContext, useMemo } from "react";
import { useTheme } from "@mui/material";
import { Box, Button, Icon } from "@ntpkunity/controls";
import { DataTableWrap, ConfirmationDialog } from "@sharedComponents";
import MarkupTable from "./markup-table";
import MarkupDrawer from "./markup-drawer";
import { useSetupsStore } from "Stores/SetupStore/Store";
import { useForm } from "react-hook-form";
import { DealerContext } from "context";
import { IDealerMarkups } from "Interface";
import { ActionOptions, DialogMessages } from "Enums";
import { setOpenDrawer } from "Stores/SetupStore/Actions";
import {
  useDeleteDealerMarkup,
  useDeleteLenderMarkup,
} from "services/financial-insurance.service";

export declare type IDealerMarkPopup = {
  isDisabled: boolean;
  s3Response: any;
};
const Markup: FC<IDealerMarkPopup> = ({
  isDisabled,
  s3Response,
}) => {
  const form: {
    handleSubmit: any;
    reset: any;
    setValue: any;
  } = useForm<IDealerMarkups>();
  const theme = useTheme();
  const { mutate: deleteDealerMarkup } = useDeleteDealerMarkup();
  const { mutate: deleteLenderMarkup } = useDeleteLenderMarkup();
  const [actionTitle, setActionTitle] = useState<string>("Add New Markup");
  const [actionData, setActionData] = useState(undefined);
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false);
  const [state, dispatch] = useSetupsStore();
  const [actionType, setActionType] = useState<ActionOptions>(ActionOptions.ADD);
  const { dealer_code } = useContext(DealerContext);
  const [MarkupState, setMarkupState] = useState<boolean>(false);
  const [markupData, setMarkupData] = useState(undefined);
  const [lenderMarkupData, setLenderMArkupData] = useState(undefined);

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD);
        setActionTitle("Add New Markup");
        setActionData(value);
        setMarkupState(true);
        dispatch(setOpenDrawer(true));
        form.reset({});
      default:
        return;
    }
  };

  useEffect(() => {
    if (MarkupState === false) {
      form.reset();
      dispatch(setOpenDrawer(false));
    }
  }, [MarkupState]);

  const onEdit = (formData: IDealerMarkups) => {
    setActionType(ActionOptions.EDIT);
    setActionTitle("Edit Markup");
    setActionData(formData);
    setMarkupState(true);
    dispatch(setOpenDrawer(true));
    form.reset({ ...formData });
  };

  const onDelete = (formData) => {
    if (formData?.lender_id) {
      setLenderMArkupData(formData);
    } else if (formData) {
      setMarkupData(formData);
    }
    setConfirmationPopupState(true);
  };

  const onDeleteConfirm = () => {
    if (lenderMarkupData) {
      deleteLenderMarkup(lenderMarkupData);
      setLenderMArkupData(undefined);
    } else if (markupData) {
      deleteDealerMarkup(markupData);
      setMarkupData(undefined);
    }
    setConfirmationPopupState(false);
  };


  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <MarkupTable 
          onEdit={onEdit}
          onDelete={onDelete}
          dealer_code={dealer_code}
        />
        <Button
          id="idAddNewMarkup"
          theme={theme}
          secondary
          fullWidth={true}
          text={"Add New Markup"}
          onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
        />
      </DataTableWrap>
      {MarkupState && (
        <MarkupDrawer
          openPopUp={MarkupState}
          setPopUpState={setMarkupState}
          title={actionTitle}
          form={form}
          dealer_code={dealer_code}
          markupsData={actionData}
          actionType={actionType}
          isDisabled={isDisabled}
          s3Response={s3Response}
        />
      )}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default Markup;