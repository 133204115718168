import { useReducer } from "react";
import { CheckoutSteps } from "../../components/atoms/step/step.component";
import { FixedVehicleCard, VehicleCard } from "@components/molecules";

import {
  VehicleOwnership,
  VehicleOwnershipCompleted,
} from "@src/components/organisms/credit-application/checkout/01-who-is-this-for";
import {
  CurrentAndPastAddresses,
  CurrentAndPastAddressesCompleted,
} from "@src/components/organisms/credit-application/checkout/02-current-and-past-addresses";
import {
  FinancialInformation,
  FinancialInformationCompleted,
} from "@src/components/organisms/credit-application/checkout/03-financial-information";
import {
  VerifyIdentity,
  VerifyIdentityCompleted,
} from "@src/components/organisms/credit-application/checkout/04-verify-identity";
import {
  FinanceOfferProvider,
  FinanceOfferProviderCompleted,
} from "@src/components/organisms/credit-application/checkout/05-finance-offer-provider";
import { Agreements } from "@src/components/organisms/credit-application/checkout/06-agreements/agreements";

import { BaseLayout } from "@src/layouts";
import "./checkout-page.scss";

import {
  initialState,
  reducer,
} from "@pages/checkout-page/checkout-page.state";
import { Col, Row } from "antd";

const CheckoutPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const stepOneDataReceived = () => {
    dispatch({ type: "Step-one-data-received" });
    dispatch({ type: "Next-step" });
  };

  const stepTwoDataReceived = () => {
    dispatch({ type: "Step-two-data-received" });
    dispatch({ type: "Next-step" });
  };

  const stepThreeDataReceived = () => {
    dispatch({ type: "Step-three-data-received" });
    dispatch({ type: "Next-step" });
  };

  const stepFourDataReceived = () => {
    dispatch({ type: "Step-four-data-received" });
    dispatch({ type: "Next-step" });
  };

  const stepFiveDataReceived = () => {
    dispatch({ type: "Step-five-data-received" });
    dispatch({ type: "Next-step" });
  };

  const stepSixDataReceived = () => {
    dispatch({ type: "Step-six-data-received" });
    dispatch({ type: "Next-step" });
  };

  const changeStateOfEditMode = (step: string) => {
    switch (step) {
      case "step-one":
        dispatch({ type: "Step-one-data-received-status-change" });
        break;
      case "step-two":
        dispatch({ type: "Step-two-data-received-status-change" });
        break;
      case "step-three":
        dispatch({ type: "Step-three-data-received-status-change" });
        break;
      case "step-four":
        dispatch({ type: "Step-four-data-received-status-change" });
        break;
      case "step-five":
        dispatch({ type: "Step-five-data-received-status-change" });
        break;
      default:
        break;
    }
  };

  return (
    <BaseLayout>
      <div className="bmw-container address-inner-container">
        <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <div className="address-left-content">
              <h3 className="address-page-title text--h3">
                Complete your credit application.
              </h3>
              <p className="text--body-2">
                The details you provide allow us to submit your credit
                application and find options for you.
              </p>
              {/* If state.index is 0 and stepOneDataReceived is false then show StepOne */}
              {state.Index >= 0 && !state.stepOneDataReceived && (
                <VehicleOwnership
                  stepOneDataReceivedEvent={stepOneDataReceived}
                />
              )}
              {/* Completed Step 1 */}
              {state.stepOneDataReceived && (
                <VehicleOwnershipCompleted
                  changeStateOfEditModeHandler={changeStateOfEditMode}
                />
              )}

              {/* If state.index is 1 and stepTwoDataReceived is false then show StepTwo */}
              {state.Index >= 1 && !state.stepTwoDataReceived && (
                <CurrentAndPastAddresses
                  stepTwoDataReceivedEvent={stepTwoDataReceived}
                />
              )}
              {/* Completed Step 2 */}
              {state.stepTwoDataReceived && (
                <CurrentAndPastAddressesCompleted
                  changeStateOfEditModeHandler={changeStateOfEditMode}
                />
              )}

              {/* If state.index is 2 and stepThreeDataReceived is false then show StepThree */}
              {state.Index >= 2 && !state.stepThreeDataReceived && (
                <FinancialInformation
                  stepThreeDataReceivedEvent={stepThreeDataReceived}
                />
              )}
              {/* Completed Step 3 */}
              {state.stepThreeDataReceived && (
                <FinancialInformationCompleted
                  changeStateOfEditModeHandler={changeStateOfEditMode}
                />
              )}

              {/* If state.index is 3 and stepFourDataReceived is false then show StepFour */}
              {state.Index >= 3 && !state.stepFourDataReceived && (
                <VerifyIdentity
                  stepFourDataReceivedEvent={stepFourDataReceived}
                />
              )}
              {/* Completed Step 4 */}
              {state.stepFourDataReceived && (
                <VerifyIdentityCompleted
                  changeStateOfEditModeHandler={changeStateOfEditMode}
                />
              )}

              {/* If state.index is 4 and stepFiveDataReceived is false then show StepFive */}
              {state.Index >= 4 && !state.stepFiveDataReceived && (
                <FinanceOfferProvider
                  stepFiveDataReceivedEvent={stepFiveDataReceived}
                />
              )}
              {/* Completed Step 5 */}
              {state.stepFiveDataReceived && (
                <FinanceOfferProviderCompleted
                  changeStateOfEditModeHandler={changeStateOfEditMode}
                />
              )}

              {/* If state.index is 5 and stepSixDataReceived is false then show StepSix */}
              {state.Index >= 5 && !state.stepSixDataReceived && (
                <Agreements stepSixDataReceivedEvent={stepSixDataReceived} />
              )}

              <CheckoutSteps index={state.Index} />

              <p className="disclaimer text--disclaimer">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="address-right-content">
              <div className="address-right-content__inner">
                <VehicleCard />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <FixedVehicleCard />
    </BaseLayout>
  );
};

export default CheckoutPage;
