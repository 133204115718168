import "./accordion.style.css";

export const Accordion = ({
  addBillingAddressHandler,
  addMailingAddressHandler,
}: {
  addBillingAddressHandler: () => void;
  addMailingAddressHandler: () => void;
}) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="checkout-accordion" onClick={addBillingAddressHandler}>
        <i className="checkout-accordion-plus" />
        <span className="checkout-accordion-text">Add billing address</span>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="checkout-accordion" onClick={addMailingAddressHandler}>
        <i className="checkout-accordion-plus" />
        <span className="checkout-accordion-text">Add mailing address</span>
      </div>
    </>
  );
};
