import { Button, Input, Radio, Select } from "@src/components/atoms";
import "./financial-information.scss";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Phone } from "@src/components/atoms/phone-input/phone-input.component";

export const FinancialInformation = ({
  stepThreeDataReceivedEvent,
}: {
  stepThreeDataReceivedEvent: () => void;
}) => {
  return (
    <div className="checkout">
      <div className="checkout-form-header checkout-form-header--padding">
        <h3 className="checkout-title text-secondary text--subsection-2">03</h3>
        <h3 className="checkout-title text--subsection-2">
          Financial Information.
        </h3>
      </div>
      <p className="financial-checkout-subtitle text--body-2">
        Tell us about your current employment situation. This information helps
        us verify your identity and personalize your payment terms.
      </p>
      <div className="financial-occupation-wrapper">
        <div>
          <div className="form-inner-field-container checkout-column-wrapper">
            <div>
              <div>
                <Input
                  id={"financial-information--occupation"}
                  name={"financial-information--occupation"}
                  placeholder="Occupation"
                  label="Occupation"
                />
              </div>
            </div>
            <div>
              <Select
                id={""}
                placeholder="Select"
                label="Employment type"
                // showInfoIcon
                options={[
                  { value: "1", label: "Option 1", disabled: false },
                  { value: "2", label: "Option 2", disabled: false },
                ]}
                // helpText="Optional helper text"
              />
            </div>
            <div>
              <div>
                <Input
                  id={"financial-information--annual_income"}
                  name={"financial-information--annual_income"}
                  placeholder="$0.00"
                  label="Annual income"
                />
              </div>
            </div>
            <div>
              <Datepicker
                id={"start_date"}
                name={"start_date"}
                placeholder="Choose"
                label="Date of birth"
                format="MMM DD, YYYY"
              />
            </div>
            <div>
              <div>
                <Input
                  id={"financial-information--duration_employment"}
                  name={"financial-information--duration_employment"}
                  placeholder="Duration"
                  label="Duration of employment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="financial-employer-container">
        <p className="financial-subtitle text--body-2">
          Please provide your employment information.
        </p>

        <div>
          <div className="form-inner-field-container checkout-column-wrapper">
            <div>
              <Input
                id={"financial-information--employer_name"}
                name={"financial-information--employer_name"}
                placeholder="Name"
                label="Employer name"
              />
            </div>
            <div>
              <Phone country="us" label="Employer phone number" value="" />
            </div>
          </div>
        </div>
        <div>
          <div className="form-inner-field-container">
            <div>
              <Input
                id={"financial-information--address1"}
                name={"financial-information--address1"}
                placeholder="Street address"
                label="Address 1"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="form-inner-field-container">
            <div>
              <Input
                id={"financial-information--address2"}
                name={"financial-information--address2"}
                placeholder="Apartment, suite, unit, building, floor"
                label="Address 2 (optional)"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="form-inner-field-container checkout-column-wrapper">
            <div>
              <Input
                id={"financial-information--city"}
                name={"financial-information--city"}
                placeholder="City"
                label="City"
              />
            </div>
            <div>
              <Select
                id={"financial-information--state"}
                placeholder="Select"
                label="State"
                // showInfoIcon
                options={[
                  { value: "1", label: "Option 1", disabled: false },
                  { value: "2", label: "Option 2", disabled: false },
                ]}
                // helpText="Optional helper text"
              />
            </div>
            <div>
              <Input
                id={"financial-information--zip_code"}
                name={"financial-information--zip_code"}
                placeholder="ZIP Code"
                label="ZIP Code (optional)"
              />
            </div>
          </div>
        </div>
        <div className="form-inner-field-container form-inner-additional-icome">
          <div className="form-inner--add-plus-button">
            <i className="form-inner--add-plus-button-icon" />
            <span className="text--cta-2">Add additional income</span>
          </div>
        </div>
      </div>
      <hr className="checkout-hr" />

      <div className="financial-residence-container">
        <p className="text--body-2 financial-residence-text-own">
          Please provide your residence status.
        </p>
        <div className="financial-radio-group">
          <Radio
            id="radio--mortgage"
            name="residence-radio"
            defaultValue="Mortgage"
            htmlFor="Mortgage"
            label={"Mortgage"}
          />
          <Radio
            id="radio--rent"
            name="residence-radio"
            defaultValue="Renting"
            htmlFor="Renting"
            label={"Renting"}
          />

          <Radio
            id="residence-with-relatives"
            name="residence-radio"
            defaultValue="With Relatives"
            htmlFor="With Relatives"
            label={"With Relatives"}
          />
          <Radio
            id="radio--own_free_clear"
            name="residence-radio"
            defaultValue="Own Free & Clear"
            htmlFor="Own Free & Clear"
            label={"Own Free & Clear"}
          />
        </div>
        {/* <div className="financial-monthly-container">
          <div className="form-inner-field-container checkout-column-wrapper">
            <Input
              id={"input-wdchixq"}
              name={"financial-information--zip_code"}
              placeholder="$0.00"
              label="Monthly payments"
            />
          </div>
        </div> */}
      </div>
      <div className="financial-residence-container">
        <p className="text--body-2 financial-residence-text-two">
          Have you ever applied for bankruptcy?
        </p>
        <div className="financial-residence-choice-container">
          <div className="financial-radio-group">
            <Radio
              id="yesCheckbox"
              name="bankruptcy-radio"
              defaultValue="Yes"
              htmlFor="yesCheckbox"
              label={"Yes"}
            />
            <Radio
              id="noCheckbox"
              name="bankruptcy-radio"
              defaultValue="No"
              htmlFor="noCheckbox"
              label={"No"}
            />
          </div>
        </div>
      </div>

      <div className="financial-cta-wrapper">
        <Button
          type="default"
          htmlType={"button"}
          text="Save & continue"
          onClick={stepThreeDataReceivedEvent}
          fixed
          fullWidth
        />
      </div>
    </div>
  );
};
