import { FC, useContext, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Button,
  Select,
  Input,
  Box,
  PersistentDrawer,
  Icon,
} from "@ntpkunity/controls";
import {
  ConfirmationDialog,
} from "@sharedComponents";
import { DealerContext } from "context";
import { logout } from "@ntpkunity/controls-ums";
import { DealerAppointmentType } from "./dealer-appointment-type";
import { Controller } from "react-hook-form";
import {
  ActionOptions,
  ActionTypes,
  DialogMessages,
  ValidationMessages,
  QueryKeys,
} from "Enums";
import {
  useUpdateDealerAppointment,
  useMutation_AddDealerAppointment,
  useQuery_GetAllConfiguredAppointments,
  useQuery_GetAllConfiguredTimeSlotIntervals,
} from "services/dealer-appointment.service";
import { usePrompt } from "utilities/usePrompt";
import { useNavigate } from "react-router-dom";
import { useSetupsStore, useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";

const AddNewDealerAppointment: FC<DealerAppointmentType> = ({
  popUpState,
  actionType,
  setPopUpState,
  form,
  title,
}) => {
  const theme = useTheme();
  const { mutate: addDealerAppointment } = useMutation_AddDealerAppointment();
  const { mutate: updateDealerAppointment } = useUpdateDealerAppointment();

  const [dealer_id, setDealerId] = useState("");
  const { actions } = useStoreContext();

  const navigate = useNavigate();
  const [navigationState, setNavigationState] = useState<any>();
  const [leavePagePopupState, setLeavePageState] = useState<boolean>(false);

  const queryClient = useQueryClient();
  useQuery_GetAllConfiguredAppointments();
  useQuery_GetAllConfiguredTimeSlotIntervals();
  const configured_appointments: Array<any> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CONFIGURED_APPOINTMENTS
  );
  const configured_timeslots: Array<any> = queryClient.getQueryData(
    QueryKeys.GET_ALL_CONFIGURED_TIMESLOTINTERVALS
  );
  const { dealerData, dealer_code } = useContext(DealerContext);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
    getValues,
    setError,
    clearErrors,
  } = form;
  useEffect(() => {
    if (dealerData) {
      setDealerId(
        dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.id
      );
    }
  }, [dealerData]);
  const selectedAppointmentType = watch("appointment_id");
  const record = configured_appointments?.find(record => record.id === selectedAppointmentType);
  const appointmentType = record ? record.appointment_type : null;

  const handleError = (error) => {
    if (
      error?.error?.response?.data?.detail ===
      "A Dealer Appointment already exists for this appointment type" ||
      error?.response?.data?.detail ===
      "A Dealer Appointment already exists for this appointment type" 
    ) {
      setError("appointment_id", { message: "Appointment Type already exists" });
      return;
    } else {
      onClose();
      actions.setToast({
        toastMessage: error?.response?.data?.detail,
        toastState: true,
        variant: "error",
      });
    }
  };

  function onSubmit(data) {
    if (actionType === ActionOptions.ADD) {
      data.dealer_code = dealer_code,
        addDealerAppointment(
          { ...data },
          {
            onSuccess: (response) => {
              reset();
              onClose();
            },
            onError: (error) => {
              setPopUpState(true);
              handleError(error);
            },
          }
        );
    }
    if (actionType === ActionOptions.EDIT) {
      if ((configured_appointments?.find(
        (item) => item.id === data.appointment_id
      ))?.appointment_type !== "Pickup") {
        data.cushion_time = "",
        data.in_transit_cushion_days =""
      }
      updateDealerAppointment(
        { ...data },
        {
          onSuccess: (response) => {
            reset();
            onClose();
          },
          onError: (error) => {
            handleError(error);
          },
        }
      );
    }
  }

  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setPopUpState(false);
    }
  };


  const onSaveConfirm = (e: any) => {
    handleSubmit(onSubmit)();
    setLeavePageState(false);
  };
  const onCancelConfirm = (e: any) => {
    navigate(".." + navigationState.location.pathname);
    if (navigationState.location.pathname == "/market-place/signup") {
      logout();
      localStorage.clear();
    }
    localStorage.setItem("DirtyState", "false");
  };
  usePrompt(
    "Leave screen?",
    isDirty,
    (dirty) => {
      setLeavePageState(dirty);
    },
    (tx) => {
      reset(getValues());
      setNavigationState(tx);
    }
  );


  return (
    <>
      <ConfirmationDialog
        openPopUp={leavePagePopupState}
        onConfirm={onSaveConfirm}
        onCancel={onCancelConfirm}
        setPopUpState={setLeavePageState}
        confirmationTitle={DialogMessages.saveTitle}
        confirmationText={DialogMessages.saveText}
        primaryBtnText={DialogMessages.saveBtnText}
        icon={<Icon name="DialogSaveIcon" />}
      />
      <PersistentDrawer
        title={title}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        theme={theme}
        onClose={onClose}
        enabledPin={true}
        customFooter={
          <Button
            theme={theme}
            primary
            onClick={handleSubmit(onSubmit)}
            text="Save Appointment Type"
            fullWidth
          />
        }
      >
        <Controller
          name="appointment_id"
          control={control}
          defaultValue={""}
          rules={{
            required: ValidationMessages.APPOINTMENT_TYPE_REQUIRED,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Appointment Type"}
              placeholder={"Select"}
              items={configured_appointments
                ?.map((item) => ({
                  text: item.appointment_type,
                  value: item.id,
                })) || []}
              value={value ?? []}
              onChange={onChange}
              selectError={errors?.appointment_id?.message}

            />
          )}
        />
        <Controller
          name="time_slot_interval_id"
          control={control}
          defaultValue={undefined}
          rules={{
            required: ValidationMessages.TIME_SLOT_INTERVAL_REQUIRED,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={"Time Slot Interval"}
              placeholder={"Select"}
              items={configured_timeslots
                ?.map((item) => ({
                  text: `${item.time_slot_in_minute} min`,
                  value: item.id,
                })) || []}
              value={value ?? []}
              onChange={onChange}
              selectError={errors?.time_slot_interval_id?.message}
            />
          )}
        />
    {appointmentType === "Pickup" &&
        <Controller
          name="cushion_time"
          control={control}
          defaultValue={""}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"Cushion Time (Optional)"}
              type="number"
              value={value}
              endAdornment={"days"}
              onChange={onChange}
            />
          )}
        />}
        {appointmentType === "Pickup" &&
        <Controller
          name="in_transit_cushion_days"
          control={control}
          defaultValue={""}
          render={({ field: { onChange, value } }) => (
            <Input
              theme={theme}
              fullWidth
              placeholder={"Type here.."}
              label={"In-Transit Cushion (Optional)"}
              type="number"
              value={value}
              endAdornment={"days"}
              onChange={onChange}
            />
          )}
        />}
        <Controller
          name="is_active"
          control={control}
          defaultValue={true}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              sxProps={""}
              label={"Status"}
              placeholder={"Select"}
              items={[
                { text: "Enabled", value: true },
                { text: "Disabled", value: false },
              ]}
              value={value}
              onChange={onChange}
            ></Select>
          )}
        />
      </PersistentDrawer>
    </>
  );
};

export default AddNewDealerAppointment;
