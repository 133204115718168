import Http from "shared/helper/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStoreContext } from "Stores/SetupStore/Store";
import {
  EP_GET_FINANCIAL_INSURANCE_PRODUCTS,
  EP_GET_FINANCIAL_INSURANCE,
  EP_DELETE_ALL_FINANCIAL_INSURANCE,
  EP_GET_FINANCIAL_INSURANCE_BUNDLE,
  EP_GET_DEALER_MARKUPS
} from "shared/config/endpoints";
import { IDealerMarkups, IFinancialInsurance } from "Interface";
import { QueryKeys, ToastMessages } from "Enums";
import { ConfigurationAPI } from "services";
import { setToast } from "Stores/SetupStore/Actions";

export const useGetAllFinancialInsuranceProducts = (): any => {
  const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<
    { dealer_code: string },
    any
  >(
    () => {
      const apiService = Http.createConnection();
      return apiService.get<any>(`${EP_GET_FINANCIAL_INSURANCE_PRODUCTS}`);
    },
    {
      onSuccess(_response: any) {
        actions.setFinancialInsuranceProductData(_response);
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useSaveFinancialInsurance = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      // body.dealer_id = 1;
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_GET_FINANCIAL_INSURANCE}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        
        queryClient.invalidateQueries(
          QueryKeys.CHECK_PREFERRED_FNI_PRODUCTS
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `${EP_GET_FINANCIAL_INSURANCE}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        queryClient.invalidateQueries(
          QueryKeys.CHECK_PREFERRED_FNI_PRODUCTS
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteFinancialInsuranceById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      return ConfigurationAPI.delete<any>(
        `${EP_GET_FINANCIAL_INSURANCE}/${body.id}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
        queryClient.invalidateQueries(
          QueryKeys.CHECK_PREFERRED_FNI_PRODUCTS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.response?.data?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const getAllFinancialInsuranceProductFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/financial-insurances/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllFinancialInsuranceProduct_filter = (
  columnFilters: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER, columnFilters],
    async () => {
      return getAllFinancialInsuranceProductFilterFromServer(
        columnFilters
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useDeleteAllFinancialInsuranceByDealerId = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (dealer_code: string) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `${EP_DELETE_ALL_FINANCIAL_INSURANCE}/${dealer_code}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_FILTER
        );
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const getAllFinancialInsuranceProductByFinanceTypeFromServer = (
  dealer_code: string
) => {
  return ConfigurationAPI.get(
    `/financial-insurance-by-finance_type/${dealer_code}`
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllFinancialInsuranceProductByFinancetype = (
  dealer_code: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BY_FINANCETYPE],
    async () => {
      return getAllFinancialInsuranceProductByFinanceTypeFromServer(
        dealer_code
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const getAllDealerMarkupSlabsFromServer = (
  dealer_code: string
) => {
  return ConfigurationAPI.get(
    `/dealer-markup/${dealer_code}`
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllDealerMarkupSlabs = (
  dealer_code: string
) => {
  return useQuery(
    [QueryKeys.GET_ALL_DEALER_MARKUPS_SLABS],
    async () => {
      return getAllDealerMarkupSlabsFromServer(
        dealer_code
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useGetFinancialInsurancematerial = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<any>(`financial-insurance-material`, body);
    },
    {
      onSuccess() {},
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

// Finance Insurance  Product Bundle APIs

export const useSaveFinancialInsuranceBundle = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IFinancialInsurance>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_GET_FINANCIAL_INSURANCE_BUNDLE}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const getAllFinancialInsuranceProductBundleFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/financial-insurances-bundle/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const getAllDealerMarkupsFilterFromServer = (
  columnFilters: string
) => {
  return ConfigurationAPI.get(
    `/dealer-markups/filter?`.concat(columnFilters)
  ).then((response) => {
    return response.data;
  });
};

export const UseQuery_getAllFinancialInsuranceProductBundle_filter = (
  columnFilters: string
) => {
  return useQuery(
    [
      QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER,
      columnFilters,
    ],
    async () => {
      return getAllFinancialInsuranceProductBundleFilterFromServer(
        columnFilters
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const UseQuery_getAllDealerMarkups_filter = (
  columnFilters: string
) => {
  return useQuery(
    [
      QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS,
      columnFilters,
    ],
    async () => {
      return getAllDealerMarkupsFilterFromServer(
        columnFilters
      ).then((data) => {
        return data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useUpdateFinancialInsuranceBundleById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `${EP_GET_FINANCIAL_INSURANCE_BUNDLE}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteFinancialInsuranceBundleById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `${EP_GET_FINANCIAL_INSURANCE_BUNDLE}/${body}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteBundleProductById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `financial-insurance-bundle-product/${body.bundle_id}/${body.product_id}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteDealerMarkup = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `dealer-markups/${body}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useDeleteLenderMarkup = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.delete<any>(
        `lender-dealer-markups/${body.lender_id}`
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Deleted_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateBundleProductStatus = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `update-bundle-product-status/${body.bundle_id}/${body.product_id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateLenderMarkupStatus = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `update-lender-markup-status/${body.dealer_id}/${body.score_range_id}/${body.lender_id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useCheckBundleDuplication = (): any => {
  return useMutation(
    (body: any) => {
      return ConfigurationAPI.get(
        `/check-duplicate-bundle-name/${body.dealer_code}/${body.bundle_name}`
      );
    },
    {
      onSuccess(_response: any) {},
    }
  );
};

export const useUpdateFinancialInsuranceBundleStatus = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`update-bundle-status/${body.id}`, {
        is_active: body.is_active,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_FINANCIAL_INSURANCE_PRODUCT_BUNDLE_FILTER
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerMarkupsStatus = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(`dealer-markups/${body.id}`, {
        is_active: body.is_active,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.error?.toString(),
          toastState: true,
        });
      },
    }
  );
  return { data, isLoading, mutate };
};


export const useSaveDealerMarkups = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<IDealerMarkups>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.post<any>(`${EP_GET_DEALER_MARKUPS}`, body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_SLABS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Added_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.detail?.message,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};

export const useUpdateDealerMarkupsById = (): any => {
  const { actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection();
      return apiService.patch<any>(
        `${EP_GET_DEALER_MARKUPS}/${body.id}`,
        body
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_FILTERS
        );
        queryClient.invalidateQueries(
          QueryKeys.GET_ALL_DEALER_MARKUPS_SLABS
        );
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
        });
      },
      onError(response: any) {
        actions.setToast({
          toastMessage: response?.detail,
          toastState: true,
          variant: "error",
        });
      },
    }
  );
  return { data, isLoading, mutate };
};