import { DialogTotal } from '@components'
import { Box, Grid, Input, Typography } from '@ntpkunity/controls'
import { FC, Fragment } from 'react'
import { useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Fee } from './add-fees.type'

interface EditFeesProps {
  dealerFees: any
  setDealerFees: any
  financeType: string
}

const EditFees: FC<EditFeesProps> = ({ dealerFees, setDealerFees, financeType }) => {
  const theme = useTheme()

  const feeForm = useForm<any>()

  const { control, watch } = feeForm
  const onFeeUpdate = () => {
    const data = feeForm.getValues()
    const updatedDealerFees = Object.keys(dealerFees).reduce((acc, financeType) => {
      const updatedFees = dealerFees[financeType].map((fee: any, index: number) => {
        const updatedAmount = parseFloat(
          data[fee.fee_name] || fee.applied_price || fee.default_amount
        )
        const updatedVendor = data[`vendor${index}`] || fee.vendor || ''
        return {
          ...fee,
          ...(fee.default_amount !== undefined
            ? { default_amount: updatedAmount }
            : { applied_price: updatedAmount }),
          vendor: updatedVendor
        }
      })

      return {
        ...acc,
        [financeType]: updatedFees
      }
    }, {})
    setDealerFees(updatedDealerFees)
  }

  const totalAmount = dealerFees?.[financeType]?.reduce((sum: any, fee: any) => {
    const value = parseFloat(watch(fee?.fee_name) || fee?.applied_price || fee?.default_amount) || 0
    return sum + value
  }, 0)

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <>
        <Box theme={theme} height={'62.4vh'}>
          <Grid theme={theme} container rowSpacing={3} mt={2}>
            {dealerFees?.[financeType]?.map((fee: Fee, index: number) => (
              <Fragment key={index}>
                <Grid theme={theme} container item columnSpacing={2}>
                  <Grid theme={theme} item xs={5}>
                    <Typography
                      theme={theme}
                      component="p"
                      variant="body2"
                      mt={1.5}
                      children={fee?.fee_name}
                    />
                  </Grid>

                  <Grid theme={theme} item xs={4}>
                    <Controller
                      name={`vendor${index}`}
                      control={control}
                      defaultValue={fee?.vendor}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="text"
                          label="Vendor (Optional)"
                          placeholder="Type here.."
                          value={value}
                          onChange={(e: any) => {
                            onChange(e)
                          }}
                          onBlur={onFeeUpdate}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={3}>
                    <Controller
                      name={fee?.fee_name}
                      control={control}
                      defaultValue={
                        fee?.applied_price?.toFixed(2) || fee?.default_amount?.toFixed(2) || ''
                      }
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          type="decimal"
                          startAdornment="$"
                          label="Amount"
                          placeholder="0.00"
                          value={value}
                          onChange={(e: any) => {
                            onChange(e)
                          }}
                          onBlur={(_e: any) => {
                            onFeeUpdate()
                          }}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
        <DialogTotal
          theme={theme}
          className="dialog-total"
          display={'flex'}
          gap={2}
          justifyContent={'space-between'}
        >
          <Box theme={theme} className="fw-700">
            Total
          </Box>
          <Box theme={theme} className="primary" textAlign="right">
            ${totalAmount?.toFixed(2)}
          </Box>
        </DialogTotal>
        {/* <Divider sx={{ mb: 2, mt: 3 }} />
        <Grid theme={theme} container rowSpacing={1} columnSpacing={0}>
          <Grid theme={theme} item xs={6}>
            <Typography theme={theme} component="p" variant="subtitle2" children="Total" />
          </Grid>
          <Grid theme={theme} item xs={6}>
            <Typography
              theme={theme}
              component="p"
              variant="subtitle2"
              className="text-primary"
              textAlign="right"
              children={`$${totalAmount?.toFixed(2)}`}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2 }} />
        <Box theme={theme} sx={{ pt: 4, textAlign: 'center' }}>
          <Button theme={theme} primary type='submit' text='Save Changes' onClick={handleSubmit(onSubmit)} />
        </Box> */}
      </>
    </form>
  )
}

export default EditFees
