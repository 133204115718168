import styled from "@emotion/styled";
import { InputProps } from "./input.props";
import { GLOBAL_THEME as Theme } from "@styles/theme";
import errorIcon from "../../../shared/assets/icons/input-icons/error.svg";

export const InputWrapper = styled("div")({
  "&.input-wrapper": {
    ".input-label": {
      marginBottom: 8,
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".input-label-icon": {
        "&:before": {
          fontFamily: "bmw_next_icons",
          display: "block",
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: 1,
          textTransform: "none",
          wordWrap: "normal",
          whiteSpace: "nowrap",
          direction: "ltr",
          textAlign: "left",
          speak: "none",
          height: "1em",
          outline: "1px solid transparent",
          "-webkit-font-smoothing": "antialiased",
          textRendering: "optimizeLegibility",
          "-moz-osx-font-smoothing": "grayscale",
          fontFeatureSettings: "liga",
          content: "attr(data-icon)",
          width: "auto",
        },
      },
    },
    ".input--input-helperText": {
      marginTop: 4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "input, textarea": {
      "&.ant-input": {
        fontWeight: 400,
        "&::placeholder": {
          fontWeight: 300,
        },
        "&:hover": {
          boxShadow: Theme.components?.Input?.activeShadow,
        },
        "&.ant-input-disabled": {
          background: Theme.token?.colorTextLightSolid,
          borderColor: Theme.token?.colorBorderSecondary,
          color: Theme.components?.Input?.colorBorder,
          "&:hover": {
            boxShadow: "none",
          },
        },
        "&:focus": {},
        "&.ant-input-status-error": {
          paddingRight: 48,
          backgroundColor: Theme.token?.colorErrorBg,
          color: Theme.token?.colorError,
          backgroundImage: `url(${errorIcon})`,
          backgroundPosition: "calc(100% - 20px)",
          backgroundRepeat: "no-repeat",
          backgroundSize: 20,
          "&::placeholder": {
            color: Theme.token?.colorError,
          },
          "&:hover": {
            boxShadow: Theme.components?.Input?.colorErrorBorderHover,
          },
        },
        "&.input--input-small": {
          padding: "6px 8px",
          "&.ant-input-status-error": {
            backgroundPosition: "calc(100% - 8px)",
            paddingRight: 36,
          },
        },
      },
    },
    "textarea.ant-input": {
      minHeight: "80px" + "!important",
      "&.ant-input-status-error": {
        backgroundPosition: "bottom 16px right 16px",
        paddingInlineEnd: 40,
      },
    },
    ".ant-input-affix-wrapper": {
      input: {
        "&.ant-input": {
          height: "100%",
        },
      },
      "&:focus": {
        boxShadow: "none",
      },
      "&:focus-within": {
        boxShadow: "none",
      },
    },
    "&.input--input-disabled": {
      ".text-secondary": {
        color: Theme.components?.Input?.colorBorder,
      },
    },
  },
});
