import { FC, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Switch,
  Box,
  Input,
  Icon,
  Menu,
  DataTable,
} from "@ntpkunity/controls";

import {
  useDeleteDealerFeeById,
  useGetDealerFee,
  useUpdateDealerFeeById,
  useGetDealerFeeById,
  useGetDealerFeeByDealerId,
  UseQuery_getAllDealerFee_filter,
} from "services/dealer-configurations.service";
import { UseQuery_GetAll_USA_States, UseQuery_GetAllFinanceTypes } from "services";
import { IDealerFee, IDealerFeeFilter, IStates } from "Interface";
import { useStoreContext } from "Stores/SetupStore/Store";
import { useQueryClient } from "react-query";
import { ActionOptions, DialogMessages, QueryKeys } from "Enums";
import { TablePagination, useTheme } from "@mui/material";
import { number } from "yup";
import { check } from "prettier";
import ChevronDown from "shared/assets/images/chevron-down";
import { PaginationWrap, ConfirmationDialog } from "@sharedComponents";
const DealerFeesTable: FC<{ onEdit: (data: any) => unknown; dealer_code }> = ({
  onEdit,
  dealer_code,
}) => {
  const queryClient = useQueryClient();
  const { mutate: getDealerFeeByDealerId } = useGetDealerFeeByDealerId();

  const [storeDealerID, setDealerID] = useState(dealer_code);
  const { mutate: deleteDealerFee } = useDeleteDealerFeeById();
  const { mutate: updateDealerFee } = useUpdateDealerFeeById();
  const { mutate: getDealerFeeById } = useGetDealerFeeById();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [selectedDealerFee, setSelectedDealerFee] = useState<IDealerFee | null>(
    null
  );
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );

  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
      setDealerID(dealer_code);
    }
  }, [dealer_code]);
  UseQuery_GetAll_USA_States();
  UseQuery_GetAllFinanceTypes();
  const statesData: Array<IStates> = queryClient.getQueryData(
    QueryKeys.GET_ALL_USA_STATES
  );
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const { data } = UseQuery_getAllDealerFee_filter(columnFilters);
  const dealerFeeFilter: IDealerFeeFilter = data;
  const [feeNameFilter, setFeeNameFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [financeTypeFilter, setFinanceTypeFilter] = useState("");
  const [taxableFilter, setTaxableFilter] = useState("");
  const [overrideFilter, setOverRideFilter] = useState("");
  const [feeHandlingFilter, setFeeHandlingFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [vendorFilter, setVendorFilter] = useState("");
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${storeDealerID}&page_number=${pgNo}&page_size=${pgSize}`;
    if (feeNameFilter.trimStart() != null && feeNameFilter.trimStart() != "") {
      query_string = query_string.concat(`&fee_name=${feeNameFilter}`);
    }
    if (
      amountFilter.trimStart() != null &&
      amountFilter.trimStart().toString() != ""
    ) {
      query_string = query_string.concat(`&default_amount=${amountFilter}`);
    }
    if (
      financeTypeFilter.trimStart() != null &&
      financeTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&applicable_finance_type=${financeTypeFilter}`
      );
    }
    if (stateFilter.trimStart() != null && stateFilter.trimStart() != "") {
      query_string = query_string.concat(`&states=${stateFilter}`);
    }
    if (
      overrideFilter.trimStart() != null &&
      overrideFilter.trimStart() != ""
    ) {
      let fee_handling_override = "";
      if (
        "yes".startsWith(
          overrideFilter.toLowerCase().slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        fee_handling_override = "yes";
      } else if (
        "no".startsWith(
          overrideFilter.toLowerCase().slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        fee_handling_override = "no";
      } else {
        fee_handling_override = "none";
      }
      query_string = query_string.concat(
        `&fee_handling_override=${fee_handling_override}`
      );
    }
    if (taxableFilter.trimStart() != null && taxableFilter.trimStart() != "") {
      let taxable = "";
      if (
        "yes".startsWith(
          taxableFilter.toLowerCase().slice(0, Math.max("yes".length - 1, 1))
        )
      ) {
        taxable = "yes";
      } else if (
        "no".startsWith(
          taxableFilter.toLowerCase().slice(0, Math.max("no".length - 1, 1))
        )
      ) {
        taxable = "no";
      } else {
        taxable = "none";
      }
      query_string = query_string.concat(`&taxable=${taxable}`);
    }
    if (
      feeHandlingFilter.trimStart() != null &&
      feeHandlingFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&fee_handling=${feeHandlingFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    if (vendorFilter.trimStart() != null && vendorFilter.trimStart() != "") {
      query_string = query_string.concat(`&vendor=${vendorFilter}`);
    }
    return query_string;
  };
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");

  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  const { states, actions } = useStoreContext();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const filterStates = (states: any) => {
    let str: string[] = [];
    for (let i = 0; i < states.length; i++) {
      str.push(
        statesData?.find((x) => x.id == states[i])?.name
          ? statesData?.find((x) => x.id == states[i])?.name
          : ""
      );
    }
    return <>{str.length > 0 ? str.join(", ") : "-"}</>;
  };

  const theme = useTheme();
  const onDeleteConfirm = () => {
    if (selectedDealerFee) {
      deleteDealerFee(selectedDealerFee);
      actions.deleteDealerFeeRow({
        ...selectedDealerFee,
      });
      setConfirmationPopupState(false);
      setSelectedDealerFee(null);
    }
  };
  return (
    <>
      <DataTable
        theme={theme}
        variant={"basic"}
        theadChildren={
          <>
            <TableRow key={0}>
              <TableCell className="checkbox-cell fixed-left"></TableCell>
              <TableCell>Fee Name</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Applicable Finance Type(s)</TableCell>
              <TableCell>Fee Handling</TableCell>
              <TableCell>Fee Handling Override</TableCell>
              <TableCell>Taxable</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Default Amount</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow key={1} className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search name..."}
                    type="text"
                    onChange={(value) => {
                      setFeeNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={feeNameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStateFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={stateFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFinanceTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={financeTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setFeeHandlingFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={feeHandlingFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setOverRideFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={overrideFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTaxableFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={taxableFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={statusFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setAmountFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={amountFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search vendor..."}
                    type="text"
                    onChange={(value) => {
                      setVendorFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={vendorFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {dealerFeeFilter?.result &&
              dealerFeeFilter.result?.map((dealerFee, index) => {
                return (
                  <TableRow key={index} className="child-tr">
                    <TableCell className="checkbox-cell fixed-left"></TableCell>
                    <TableCell>{dealerFee.fee_name}</TableCell>
                    <TableCell>{filterStates(dealerFee.states)}</TableCell>
                    <TableCell>
                      {dealerFee &&
                        dealerFee?.applicable_finance_type?.join(", ")}
                    </TableCell>
                    <TableCell>{dealerFee.fee_handling}</TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={"basic"}
                        label={dealerFee.fee_handling_override ? "Yes" : "No"}
                        switchEnabled={dealerFee.fee_handling_override}
                        onChange={(event) => {
                          updateDealerFee({
                            ...dealerFee,
                            fee_handling_override: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={"basic"}
                        label={dealerFee.taxable ? "Yes" : "No"}
                        switchEnabled={dealerFee.taxable}
                        onChange={(event) => {
                          updateDealerFee({
                            ...dealerFee,
                            taxable: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        theme={theme}
                        varient={"basic"}
                        label={dealerFee.is_active ? "Enabled" : "Disabled"}
                        switchEnabled={dealerFee.is_active}
                        onChange={(event) => {
                          updateDealerFee({
                            ...dealerFee,
                            is_active: event.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Box theme={theme} sx={{ mr: 3.8 }}>
                        {defaultCurrency}{" "}
                        {dealerFee.default_amount.toLocaleString("en-US")}{" "}
                      </Box>
                    </TableCell>
                    <TableCell>{dealerFee.vendor}</TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="EditIcon" />
                                {"Edit"}
                              </>
                            ),
                            optionValue: "edit",
                          },
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="DeleteIcon" />
                                {"Delete"}
                              </>
                            ),
                            optionValue: "delete",
                          },
                        ]}
                        handleOptionClick={(_event, _key, value) => {
                          switch (value) {
                            case "edit":
                              getDealerFeeById(
                                { id: dealerFee.id },
                                {
                                  onSuccess(response: IDealerFee) {
                                    onEdit(response);
                                  },
                                }
                              );
                              break;
                            case "delete":
                              setSelectedDealerFee(dealerFee);
                              setConfirmationPopupState(true);
                              break;
                          }
                        }}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </>
        }
      />
      <PaginationWrap>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={dealerFeeFilter ? dealerFeeFilter?.total_results : 0}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            IconComponent: ChevronDown,
            MenuProps: { disablePortal: true },
          }}
        />
      </PaginationWrap>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={onDeleteConfirm}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.confirmationTitle}
        confirmationText={DialogMessages.confirmationText}
        primaryBtnText={DialogMessages.deleteBtnText}
        icon={<Icon name="DialogDeleteIcon" />}
      />
    </>
  );
};

export default DealerFeesTable;
