import { ReactNode } from "react";
import "../../../styles/components/_radio.scss";
import clsx from "clsx";

export const Radio = ({
  id,
  name,
  defaultValue,
  htmlFor,
  label,
  checked,
  error,
  disabled,
}: {
  id?: string;
  name?: string;
  defaultValue?: string;
  htmlFor?: string;
  label?: ReactNode | string;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      className={clsx({
        "radio-button-container": true,
        "radio-error": error,
        "radio-disabled": disabled,
      })}
    >
      <input
        className="radio-button__input"
        id={id}
        name={name}
        type="radio"
        defaultValue={defaultValue}
        checked={checked}
        disabled={disabled}
      />
      <label className="radio-button__label text--body-2" htmlFor={htmlFor}>
        {label}
      </label>
    </div>
  );
};
